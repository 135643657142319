.news-wrap 
  display: flex
  width: 100%
  height: 60px
  background-color: #222222
  align-items: center
  justify-content: center
  padding: 0px 300px 0px 300px
  color: white
  position: absolute
  z-index: 101
  top: 0
.news-block  
  display: flex
  align-items: center
  height: 30px
  width: 100%
  gap: 40px
.item-block  
  display: flex
  gap: 5px
  flex-direction: row
.title  
  display: flex
  align-items: center
  justify-content: center
  font-size: 16px
  font-weight: bold
.btn-control-block
  display: flex
  align-items: center
  justify-content: center
  font-size: 16px
  font-weight: bold
  cursor: pointer
  color: #0f26f1be
  gap: 4px
.btn-control
  display: flex
  align-items: center
  justify-content: center
  font-size: 16px
  font-weight: bold
  cursor: pointer
  color: #0f26f1be
  border-radius: 5px
  &:hover
    color: #0f26f1
    transition: 0.5s
  &.active
    color: #0f26f1
    transition: 0.5s
.anonce-block
  display: flex
  justify-content: space-between
  flex-direction: row
  width: 364px
.anonce-block
  .news
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    font-size: 16px
    font-weight: bold
    cursor: pointer
    color: #FFF
    border-radius: 5px
    gap: 4px
    &:hover
      color: #0f26f1
      transition: 0.5s
    &.active
      color: #0f26f1
      transition: 0.5s
  .news-mark
    font-size: 16px
    font-weight: normal
    color: red
.news-close
  display: flex
  justify-content: end
  flex: 1
  background: #222
  width: 32px
  height: 32px
.intro-wrapper-news
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100vh
.login-footer
  position: absolute
  z-index: 101
  bottom: 0
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  width: 100%
  height: 160px
  background: #fff
  padding: 0px 300px 0px 300px
  .footer-block
    width: 100%
    height: 60px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    gap: 5rem
    .footer-item
      color: #828282
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      p
        color: #828282
        font-family: Pretendard
        font-size: 14px
        font-style: normal
        font-weight: 500
        line-height: normal
        letter-spacing: -0.56px
    .footer-links
      color: #828282
      width: 100%
      height: 100%
      display: flex
      flex-direction: row
      gap: 12px
      border-radius: 2px
      .footer-icon
        img
          display: flex
          justify-content: center
          align-items: center
          border-radius: 2px
          background: #E0E0E0
          cursor: pointer
          padding: 10px
          &:hover
            background: #BDBDBD
            transition: 0.5s
      .footer-btn
        background: #E0E0E0
        &:hover
          background: #BDBDBD
          transition: 0.5s
      .footer-btn
        cursor: pointer
        width: 46px
        height: 46px
        span
          display: none
        img
          display: flex
          justify-content: center
          align-items: center
          border-radius: 2px
          // background: #E0E0E0
          cursor: pointer
          padding: 10px
.intro-panel-wrapper
  width: 450px
  background: #fff
  position: absolute
  z-index: 101
  top: 20%
  left: 50%
  border-radius: 14px
  background: #FFF
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25)
  padding: 25px 32px
  display: flex
  flex-direction: column
  gap: 10px
.intro-module
  display: flex
  flex-direction: column
  gap: 10px
  .module-header
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    .header-title
      color: var(--bg-dark_navy, #282A3A)
      font-family: Pretendard
      font-size: 22px
      font-style: normal
      font-weight: 700
      line-height: normal
      letter-spacing: -0.55px
      display: flex
      align-items: center
      gap: 10px
    .close-btn
      width: 32px
      height: 32px
      background: #ffffff
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      &:hover
        background: #8a8a8a
        transition: 0.5s
      i
        color: #000000
        font-size: 20px
        font-weight: bold
        &:hover
          color: #fff
          transition: 0.5s
.module-body
  display: flex
  flex-direction: column
  gap: 10px
  .form-item
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    .form-label
      color: var(--bg-dark_navy, #282A3A)
      font-family: Pretendard
      font-size: 18px
      font-style: normal
      font-weight: 700
      line-height: normal
      letter-spacing: -0.45px
    input
      width: 300px
      height: 60px
      flex-shrink: 0
      border-radius: 14px
      background: var(--input-field, #F5F5F5)
      font-family: Pretendard
      font-size: 16px
      font-style: normal
      font-weight: 500
      line-height: normal
      letter-spacing: -0.4px
      padding: 0px 20px
      border: none
.etc
  display: flex
  flex-direction: row
  justify-content: space-between
  padding:  20px 0
  align-items: center
  .label
    color: var(--bg-dark_navy, #282A3A)
    font-family: Pretendard
    font-size: 18px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: -0.45px
  a
    color: var(--font-gr, #ACACAC)
    font-family: Pretendard
    font-size: 18px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: -0.45px
.submit-btn
  border-radius: 14px
  background: var(--bg-dark_navy, #282A3A)
  width: 100%
  height: 60px
  color: #FFF
  text-align: center
  font-family: Pretendard
  font-size: 20px
  font-style: normal
  font-weight: 700
  line-height: normal
  letter-spacing: -0.5px
.login-modal-wrap
  position: absolute
  z-index: 101
  top: 20%
  right: 27%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 12px
  .login-modal-btn
    cursor: pointer
    // width: 180px
    padding: 20px 42px
    flex-shrink: 0
    border-radius: 14px
    background: #FFF
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .login-title
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      span
        color: var(--bg-dark_navy, #282A3A)
        font-family: Pretendard
        font-size: 32px
        font-style: normal
        font-weight: 700
        line-height: normal
        letter-spacing: -0.8px
  .login-demo
    cursor: pointer
    border-radius: 14px
    background: var(--font-df, #222)
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25)
    padding: 8px 49px
    color: #FFF
    font-family: Pretendard
    font-size: 20px
    font-style: normal
    font-weight: 700
    line-height: normal
    letter-spacing: -0.5px
  
.login-text-wrap
  position: absolute
  z-index: 101
  top: 20%
  left: 25%
  display: flex
  flex-direction: column
  gap: 16px
  .login-logo
    width: 200px
    height: 50px
  .login-big-title
    p
      color: #FFF
      font-family: Pretendard
      font-size: 32px
      font-style: normal
      font-weight: 700
      line-height: normal
      letter-spacing: -0.8px
  .login-small-title
    p
      color: #FFF
      font-family: Pretendard
      font-size: 20px
      font-style: normal
      font-weight: 500
      line-height: normal
      letter-spacing: -0.5px
.swiper
  position: absolute
  z-index: 100 
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  .item-bg
    width: 100%
    height: 100%
    filter: brightness(0.7)  
    // filter: brightness(0) saturate(1%)
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  .item-content
    position: absolute
    z-index: 101
    top: 20%
    left: 25%
    display: flex
    flex-direction: column
    gap: 16px
    width: 558px
    .item-logo
      img
        width: 200px
        height: 50px
    .item-title
      color: #FFF
      font-family: Pretendard
      font-size: 32px
      font-style: normal
      font-weight: 700
      line-height: normal
      letter-spacing: -0.8px
      display: flex
      flex-direction: column
      gap: 5px
    .item-sub
      color: #FFF
      font-family: Pretendard
      font-size: 20px
      font-style: normal
      font-weight: 500
      line-height: normal
      letter-spacing: -0.5px
      display: flex
      flex-direction: column
      gap: 3px
    .item-desc
      color: #FFF
      font-family: Pretendard
      font-size: 16px
      font-style: normal
      font-weight: 500
      line-height: normal
      letter-spacing: -0.4px 

.mobile-wrap
  display : absolute
  height : 100%
  width : 100%
  top : 0
  align-item : center
  justify-content : center

.top-wrap
  display: flex
  height: 20%
  justify-content: center
  align-items: flex-end
  .img-caution          
    width: 5rem
    height: 5rem

.img-pc
  margin-top: 4rem
  height: 7em

.img-store
  position: absolute  
  transform: translateX(-50%)
  height: 3em
  margin-top: 4rem

.img-logo
  position: absolute
  height: 2.5em
  bottom: 10px
  left: 50%
  transform: translateX(-50%)

.mobile-content  
  height: 35%
  align-items: center
  display: flex
  margin-top: 1rem
  color : #0064B2
  text-align: center
  flex-direction: column
  .content-font
    font-family: Pretendard
    font-weight: 700
    font-size: 2rem
    margin-top: 0.5rem

.mobile-store-content
  color : #6B6B6B
  font-family: Pretendard
  font-weight : 500  
  flex-direction: column
  text-align: center
  margin-top: 1rem

  