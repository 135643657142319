.intro-wrapper {
    display: flex;
    align-items: stretch;
    min-height: 100vh;

    .intro-slide-wrapper {
        flex: 1;
        background: #eee;
        overflow: hidden;
        position: relative;
        .swiper-container,
        .swiper-wrapper,
        .swiper-slide {
            height: 100%;
        }
        .slide-item {
            position: relative;
            height: 100%;
            padding: 14.8rem 8rem;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            letter-spacing: -0.05em;

            .item-content {
                position: relative;
                z-index: 1;
                max-width: 80rem;
            }
            .item-logo {
                margin-bottom: 4rem;
                img {
                    min-height: 3.5rem;
                    width: auto;
                }
            }
            .item-title {
                font-size: 3.8rem;
                font-weight: 600;
                margin-bottom: 0.3em;
                line-height: 1.2;
            }
            .item-sub {
                font-size: 2.2rem;
                font-weight: 600;
                padding-bottom: 1.2em;
                margin-bottom: 1.2em;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                line-height: 1.2;
            }
            .item-desc {
                font-size: 1.9rem;
                font-weight: 400;
                line-height: 1.4;
                word-break: keep-all;
            }
            .item-bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .swiper-pager {
            position: absolute;
            z-index: 99;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8rem;
            background: rgba(0, 0, 0, 0.53);
            display: flex;
            .item-pager {
                width: 100%;
                border-radius: 0;
                flex: 1;
                padding: 0 3rem;
                display: flex;
                align-items: center;
                height: 100%;
                color: #fff;
                font-weight: 400;
                opacity: 1;
                & + .item-pager {
                    border-left: 1px solid rgba(255, 255, 255, 0.12);
                }

                .pager-btn {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                &:not([class*="-active"]) {
                    .pager-btn {
                        color: #fff !important;
                        border-color: transparent !important;
                    }
                }
            }
            .swiper-pagination-bullet,
            .swiper-pagination-bullet-active {
                background: none;
            }
        }

        .swiper-control {
            position: absolute;
            z-index: 99;
            right: 8rem;
            bottom: 14.8rem;
            display: flex;
            gap: 4px;
            .btn {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.15);
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                cursor: pointer;
            }
            .auto {
                position: relative;
                &:after {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 10px;
                    border-left: 2px solid #fff;
                    border-right: 2px solid #fff;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                &.play {
                    &:after {
                        width: 0;
                        height: 0;
                        border-left: 8px solid #fff;
                        border-top: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                        border-right: none;
                        left: calc(50% + 1px);
                    }
                }
                svg {
                    width: 100%;
                    height: 100%;
                    transform: rotate(-90deg);
                    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
                    stroke-dasharray: 125.6;
                    transform: rotate(-90deg);

                    .bar {
                        --progress: 0;
                        fill: transparent;
                        stroke-width: 2;
                        stroke: #fff;
                    }

                    .bg {
                        fill: transparent;
                        stroke-width: 2;
                        stroke: rgba(0, 0, 0, 0.3);
                    }

                    circle {
                        transform-origin: 50% 50%;
                    }
                }
            }

            .counter {
                width: 6.8rem;
                height: 3.6rem;
                border-radius: 9999px;
                background: rgba(255, 255, 255, 0.15);
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 3px;
                color: #fff;
            }
        }

        &.slide-black-opacity {
            .slide-item {
                color: #000;
            }
            .swiper-control {
                .btn {
                    background: rgba(0, 0, 0, 0.3);
                    color: #000;
                }
                .auto {
                    &:after {
                        border-left: 2px solid #000;
                        border-right: 2px solid #000;
                    }
                    &.play {
                        &:after {
                            border-left: 8px solid #000;
                        }
                    }
                    svg {
                        .bar {
                            stroke: #000;
                        }

                        .bg {
                            stroke: rgba(0, 0, 0, 0.3);
                        }
                    }
                }

                .counter {
                    background: rgba(0, 0, 0, 0.3);
                    color: #000;
                }
            }
        }

        &.slide-black {
            .slide-item {
                color: #fff;
            }
            .swiper-control {
                .btn {
                    background: rgba(0, 0, 0, 1);
                    color: #fff;
                }
                .auto {
                    &:after {
                        border-left: 2px solid #fff;
                        border-right: 2px solid #fff;
                    }
                    &.play {
                        &:after {
                            border-left: 8px solid #fff;
                        }
                    }
                    svg {
                        .bar {
                            stroke: #fff;
                        }

                        .bg {
                            stroke: rgba(255, 255, 255, 0.3);
                        }
                    }
                }

                .counter {
                    background: rgba(0, 0, 0, 1);
                    color: #fff;
                }
            }
        }
    }

    .intro-panel-wrapper {
        width: 50rem;
        flex: 0 0 50rem;
        padding: 5rem 4rem;
        box-shadow: -10px 0 60px rgba(0, 0, 0, 0.33);

        .panel-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid #ddd;
            .logo {
                img {
                    max-height: 2.2rem;
                    width: auto;
                }
            }
            .slogan {
                color: #aeaeae;
                font-size: 1.4rem;
                .emp {
                    font-weight: 600;
                    color: $color-purple-2;
                }
            }
        }
    }

    .intro-module {
        & + .intro-module {
            margin-top: 5rem;
        }
        .module-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2.2rem;

            .header-title {
                font-size: 1.6rem;
                font-weight: 700;
                display: flex;
                align-items: center;
                gap: 9px;
                .icon {
                    font-weight: 400;
                    font-size: 2rem;
                    opacity: 0.18;
                }
            }

            .header-control {
                a {
                    font-size: 1.5rem;
                    color: #959595;
                }
            }
        }

        .module-body {
            [class*="-item"] {
                background: #f8f9fa;
                border-radius: 4px;
                height: 5.4rem;
                padding: 0 1.8rem;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                transition: background 0.2s;

                label {
                    font-weight: 500;
                    color: #000;
                    width: 6em;
                }

                input[type="text"],
                input[type="password"] {
                    width: 100%;
                    height: 100%;
                    border: none;
                    background: none;
                    font-size: 1.5rem;

                    &:focus {
                        border: none;
                        outline: none;
                    }
                }

                & + [class*="-item"] {
                    margin-top: 4px;
                }

                &:has(input:focus) {
                    background: #e9e7ff;
                }
            }
        }

        .module-footer {
            .submit-btn {
                width: 100%;
                height: 5.4rem;
                background: $color-purple-2;
                border: none;
                border-radius: $border-radius-sm;
                color: #fff;
                font-size: 1.6rem;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background 0.2s;

                &:hover {
                    background: #413aab;
                }
            }
        }
    }

    .login-module {
        .etc {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 2rem 0 2.4rem;

            .custom-checkbox {
                .checkbox {
                    background: #fff;
                    border: 1px solid #ddd;
                }
                .label {
                    font-size: 1.5rem;
                    font-weight: 600;
                }

                input:checked ~ .checkbox {
                    background: #e9e7ff;
                    border-color: $color-purple-2;
                    &::after {
                        border-color: $color-purple-2;
                    }
                }

                input:checked ~ .label {
                    color: $color-purple-2;
                }
            }

            .link {
                font-size: 1.5rem;
                color: #959595;
            }
        }
    }

    .recent-module {
        .recent-list {
            a {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                white-space: nowrap;
                gap: 20px;
                .item-title {
                    min-width: 0;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: #000;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 2rem;

                    &.new {
                        &:before {
                            content: "N";
                            display: block;
                            width: 2rem;
                            height: 2rem;
                            background: #ff9233;
                            border-radius: 2px;
                            color: #fff;
                            font-weight: 800;
                            font-size: 1.2rem;
                            float: left;
                            margin-right: 9px;
                            text-align: center;
                        }
                    }
                    &.eme {
                        &:before {
                            content: "긴급";
                            display: block;
                            width: 3rem;
                            height: 2rem;
                            background: #fd0000;
                            border-radius: 2px;
                            color: #fff;
                            font-weight: 800;
                            font-size: 1.2rem;
                            float: left;
                            margin-right: 9px;
                            text-align: center;
                        }
                    }
                }
                .item-date {
                    flex: none;
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    font-size: 1.4rem;
                    font-weight: 500;
                    color: #888;

                    .icon {
                        color: #ccc;
                    }
                }
            }
        }
    }
}

.recent-view {
    padding: 3rem 0;
    text-align: left;
    .view-title {
        font-size: 2rem;
        font-weight: 700;
        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid #eee;
        line-height: 1.2;
    }
    .view-meta {
        display: flex;
        .meta-item {
            display: flex;
            margin-bottom: 3em;
            position: relative;
            gap: 10px;
            font-size: 1.5rem;
            & + .meta-item {
                margin-left: 16px;
                padding-left: 16px;
                &:before {
                    content: "";
                    display: blolck;
                    width: 1px;
                    height: 12px;
                    background: #ddd;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .item-label {
                font-weight: 600;
                color: $color-purple-2;
            }
            .item-content {
                font-weight: 500;
                color: #555;
            }
        }
    }
    .view-content {
        background: #f8f9fa;
        padding: 2em;
        border-radius: 10px;
        .item-content {
            font-weight: 500;
            color: #555;
        }
    }
}
.item-content-recent {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    white-space: pre-line;
}
.privacy-wrapper {
    background: #f8f9fa;

    .privacy-inner {
        background: #fff;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
        max-width: 96rem;
        margin: 0 auto;
        padding: 6rem 4rem 10rem;
    }

    .logo-title {
        margin-bottom: 4rem;
        padding-bottom: 4rem;
        text-align: right;
    }

    .pv-title {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: 2em;
        border-bottom: 1px solid #eee;
        span {
            position: relative;
            display: inline-block;
            padding-bottom: 1em;
            height: 100%;
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 4px;
                background: $color-purple-2;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }

    .pv-sec {
        margin-bottom: 6rem;
        * {
            line-height: 1.3;
        }
        .sec-title {
            font-size: 1.8rem;
            margin-bottom: 1.2em;
            font-weight: 700;
            color: #000;
        }
        .sec-p + .sec-p {
            margin-top: 1em;
        }

        ul li + li {
            margin-top: 0.6em;
        }
        ul li ul {
            margin: 0.6em 0 1em;
        }
    }
}

.gps-demo-submit {
    box-sizing: border-box;
    display: inline-flex;
    padding: 2px 16px;
    align-items: center;
    background: #fff;
    box-shadow: 0 4px 18px 0 rgba(0,0,0,0.25);
    border: none;
    border-radius: 14px;
}

.ri-verified-badge-fill {
    color: #097FBB;
    font-size: 20px;
    padding: 4px;
}

.gps-demo-txt {
    color: #222;
    font-size: 15px;
    font-weight: 700;
    font-family: "pretendard";
    margin: 6px;

    & p + p {
        padding-top: 1.2px;
    }
}