.hover-effect 
  background: rgba(85,74,255,.22)
  border-color: #2c2685
  color: #2c2685
.swal2-container
  z-index: 9999999!important
.swal2-confirm
  background: #2c2685!important
  border: none!important
  border-radius: 3px!important
  color: #fff!important
  font-size: 1.5rem!important
  font-weight: 600!important
  height: 3.6rem!important
  margin-top: 4px!important
  transition: all .2s!important
.swal2-cancel
  background: #6e7881!important
  border: none!important
  border-radius: 3px!important
  color: #fff!important
  font-size: 1.5rem!important
  font-weight: 600!important
  height: 3.6rem!important
  margin-top: 4px!important
  transition: all .2s!important