.custom-select{
    position: relative;
    border:1px solid #c9c9c9;
    height:3.4rem;
    min-width:10rem;
    background:#fff;
    border-radius: $border-radius-xs;
    &:after{
        @include border-arrow(8px, 6px, #000);
        right:1.2rem;
        pointer-events: none;
    }
    select{
        width:100%;
        height:100%;
        padding:0 1.4rem 0 1.2rem;
        border:none;
        appearance: none;
        border-radius: $border-radius-xs;
        font-size:1.4rem;
        color:#000;
        font-weight:500;
        cursor: pointer;     
        &:focus{
            outline: none;
        }   
    }
}

.custom-input{
    border:1px solid #c9c9c9;
    height:3.4rem;
    background:#fff;
    border-radius: $border-radius-xs;
    overflow: hidden;
    display:flex;
    position: relative;

    &:has(.search-result-panel){
        overflow: visible;
    }

    &.full{
        flex:1;
        width:100%;
    }

    &.wide{
        flex:0 0 400px;
        width:400px;
    }

    .label{
        border-right:1px solid #ddd;
        white-space: nowrap;
        color:#6b6b6b;
        font-weight: 500;
        font-size: 1.4rem;
        padding:0 4rem 0 1.6rem;
        min-width:10rem;
        display:flex;
        align-items: center;
    }

    input{
        background:none;
        border:none;
        appearance: none;
        width:100%;
        height:100%;
        padding:0 1.2rem;
        font-size:1.4rem;
        color:#000;
        font-weight:500;
        &:disabled, &:read-only{
            background:#F6F6F6;
            outline: none;
        }
    }
}

.custom-color{
    width: 7.6rem; 
    border-radius: 3px;
    border: 1px solid #ddd;
    input{
        width: 100%;
        height: 4rem;        
        padding: 4px;
        border:none;
        cursor: pointer;
        background:none;
        &::-webkit-color-swatch-wrapper {
            padding: 0;
        }
    }
}

.custom-checkbox{
    cursor: pointer;
    display: flex;
    gap:6px;
    align-items: center;
    input{
        display: none;
    }
    .checkbox{
        width:2rem;
        height:2rem;
        background:#676579;
        border-radius: 2px;
    }
    
    input:checked ~ .checkbox{
        background:$color-purple-4;
        position: relative;
        &:after{
            content:"";
            display:block;
            width:1rem;
            height:0.7rem;
            border-left:2px solid #fff;
            border-bottom:2px solid #fff;
            position: absolute;
            top:calc(50% - 2px);
            left:50%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
    .label{
        padding-top:2px;
    }
}

.custom-file{
    display:flex;
    gap:3px;
    input[type="file"]{
        display:none;
    }
    label{
        cursor: pointer;
    }
    .custom-input{
        flex:0 0 400px;
    }
    &.short{
        .custom-input{
            flex:0 0 240px;
        }
    }
    .del-btn{
        display: none;
        &.on{
            display:flex;
        }
    }
}

.filter-module{
    position: relative;
    [class*='-btn']{
        display: flex;
        align-items: center;
        gap:5px;
        font-size:1.5rem;
        color:$color-grey-1;
        letter-spacing: -0.05em;
        .icon{
            font-size:1.4rem;
            &.red{
                color:#1546A8;
            }
            &.blue{
                color:#FE5D2A;
            }
        }
    }
    .filter-option{
        position: absolute;
        top:-8px;
        right:-6px;
        border:1px solid #ddd;
        border-radius: $border-radius-md;
        background:#fff;
        white-space: nowrap;
        display:none;
        flex-direction: column;
        overflow: hidden;
        &.on{
            display:flex;
            z-index: 2;
        }
        [class*='-btn']{
            gap:6px;
            padding:11px 14px 11px 12px;
            color:#000;
            font-size:1.4rem;
            font-weight:500;
            & + [class*='-btn']{
                border-top: 1px solid #ddd;
            }

            &.on{
                background:#F5F5F5;
            }
            &:hover{
                background:#F5F5F5;
            }
        }

    }
}

.common-btn{
    min-width:10rem;
    height:4rem;
    border-radius: 3px;
    background: #000;
    display:flex;
    align-items: center;
    justify-content: center;
    gap:6px;
    padding:0 13px;
    border:none;
    .icon{
        color:#000;
        font-size:1.9rem;
        margin-top: -1px;
    }
    span{
        color:#fff;
        font-size:1.5rem;
        font-weight:600
    }

    &.sm{
        min-width:7rem;
        height:4rem;
    }

    &.xs{
        min-width:0;
        height:2.8rem;
        font-size:1.4rem;
    }

    &.purple{
        background:$color-purple-2;
        .icon{
            color:#fff;
        }
        span{
            color:#fff;
        }
    }
    &.red{
        background:red;
        .icon{
            color:#fff;
        }
        span{
            color:#fff;
        }
    }
    &.blue{
        background:#1B61EB;
        .icon{
            color:#fff;
        }
        span{
            color:#fff;
        }
    }

    &.white{
        background:#fff;
        border:1px solid #ddd;
        .icon{
            color:#000;
        }
        span{
            color:#000;
        }
    }

    &.excel{
        .icon{
            color:#087233;
        }
    }

}

.custom-datepicker{
    position: relative;
    border:1px solid #ddd;
    height:100%;
    background:#fff;
    border-radius: $border-radius-xs;
    box-shadow: 0 5px 0 rgb(0 0 0 / 2%);
    &:after{
        @include border-arrow(8px, 6px, #000);
        right:1.2rem;
        pointer-events: none;
    }
    input{
        width: 100%;
        height:100%;
        padding:0 1.4rem 0 1.2rem;
        border:none;
        appearance: none;
        border-radius: $border-radius-xs;
        font-size:1.4rem;
        color:#000;
        font-weight:500;
        cursor: pointer;        
        &:focus{
            outline: none;
        }
    }

    .datepicker-dropdown{
        min-width: 320px;
    }
}

.period-group{
    display:flex;
    gap:6px;
    align-items: center;

    [class*="custom-"]{
        width:160px;
        flex:0 0 160px;
    }

    & + .period-group{
        margin-left: 10px;
    }
}

.custom-radio-group, .custom-check-group{
    display:flex;
    gap:4px;

    &.col-3{
        flex-wrap:wrap;
        .custom-radio, .custom-check{
            width:calc(33.333% - 2.667px);
        }
    }
}

.custom-radio, .custom-check{
    input{
        display:none;
    }
    .label{
        display:flex;
        align-items: center;
        justify-content: center;
        min-width:7.6rem;
        padding:0 1em;
        height:4rem;
        border-radius: $border-radius-xs;
        border:1px solid #ddd;
        background:#fff;
        color:#000;
        font-size:1.5rem;
        font-weight: 500;
        cursor: pointer;
        white-space: nowrap; 
    }

    input:checked ~ .label{
        background:#D9D6FF;
        border-color:$color-purple-2;
        color:$color-purple-2;
        box-shadow: 0 5px 12px rgba(44,38,133,0.12);
    }
}

.custom-check-btn{
    input{
        display:none;
    }
    .label{
        display:flex;
        align-items: center;
        justify-content: center;
        width:7.6rem;
        height:4rem;
        border-radius: $border-radius-xs;
        border:1px solid #ddd;
        color:#000;
        font-size:1.5rem;
        font-weight: 500;
        cursor: pointer;
    }

    input:disabled ~ .label{
        background:#F6F6F6;
        border:1px solid #ddd;     
        box-shadow: 0 5px 12px rgba(44,38,133,0.12);   
    }

    input:checked ~ .label{
        background:#D9D6FF;
        border-color:$color-purple-2;
        color:$color-purple-2      
    }
}

.custom-textarea{
    width:100%;
    height:20rem;
    border:1px solid #ddd;
    border-radius: $border-radius-xs;
    textarea{
        border: none;
        background:none;
        width:100%;
        height:100%;
        resize:none;
        overflow-y: auto;
        font-family: inherit;
        font-size:1.5rem;
        color:#333;
        line-height: 1.2;
        padding:1em;
    }
}

.address{
    display:flex;
    flex-direction: column;
    gap:4px;
    .row{
        display:flex;
        gap:4px;
        .custom-input.full{
            width: 100%;
        }
    }
}

[class*="custom-"].short{
    width:200px;
}

[class*="custom-"].mini{
    width:100px;
}

[class*="custom-"] + .unit{
    margin-right:1em;
}
