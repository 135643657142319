@media (max-width: 1500px) {
    .faq-outer .float-banner {
        display: none;
    }
}

@media (max-width: 1024px) {
    .faq-outer .header-container {
        margin: auto 0.5em;
    }

    .faq-outer .header-lists {
        margin: auto 0.5em;
    }
    .faq-outer .nav-container {
        display: none;
    }

    .faq-outer .main-container {
        margin: 1em;
    }

    .faq-outer .article-point {
        margin: 0.5em;
    }
}

@media (max-width: 450px) {
    .faq-outer {
        font-size: 80%;
    }

    .faq-outer .header-logo {
        display: none;
    }

    .faq-outer .header-container {
        justify-content: center;
    }

    .faq-outer .img-30 {
        width: 70%;
    }

    .faq-outer .img-70 {
        width: 90%;
    }
}