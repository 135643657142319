#policy .strong-policy {
	font-weight: 700;
}

#policy .table-policy {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	table-layout: fixed;
	margin: 2.4rem 0;
	font-size: 1.5rem;
	line-height: 1.5;
}

#policy .table-policy .th-policy,
#policy .table-policy .td-policy {
	height: 2rem;
	padding: 1.4rem 0.8rem;
	word-break: break-all;
	border: 1px solid rgba(38, 45, 57, 0.08);
}

#policy .table-policy .th-policy:first-child,
#policy .table-policy .td-policy:first-child {
	border-left: 0;
}

#policy .table-policy .th-policy:last-child,
#policy .table-policy .td-policy:last-child {
	border-right: 0;
}

#policy .table-policy .th-policy {
	text-align: left;
	background: #f3f3f3;
}

#policy .table-policy .thead-policy .th-policy {
	border-color: rgba(38, 45, 57, 0.52) rgba(38, 45, 57, 0.08);
}

#policy .table-policy .table-policy {
	margin: 0;
	margin-bottom: 1.4rem;
}

#policy .table-policy .table-policy .th-policy {
	border-top-color: transparent;
}

#policy.policy {
	box-sizing: border-box;
	width: 100%;
	margin: 0 auto;
	padding: 2rem 2.4rem;
	padding: calc(constant(safe-area-inset-top) + 2rem)
		calc(constant(safe-area-inset-right) + 2.4rem)
		calc(constant(safe-area-inset-bottom) + 2rem)
		calc(constant(safe-area-inset-left) + 2.4rem);
	padding: calc(env(safe-area-inset-top) + 2rem)
		calc(env(safe-area-inset-right) + 2.4rem)
		calc(env(safe-area-inset-bottom) + 2rem)
		calc(env(safe-area-inset-left) + 2.4rem);
	font-size: 1.7rem;
	color: #222;
	line-height: 1.4;

	@media screen and (width>=1200px) {
		width: 80vw;
	}
}


#policy .h1-policy {
	font-size: 2.8rem;
	font-weight: 700;
	line-height: 1.5;
	letter-spacing: -0.025em;
}

#policy .h2-policy {
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 1.4;
	margin: 2.4rem 0 2rem;
}

#policy .h3-policy {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.4;
}

#policy .h4-policy {
	font-size: 1.8rem;
	font-weight: 700;
	line-height: 1.4;
}

#policy p {
	margin: 1.6rem 0;
	line-height: 1.4;
}

#policy .hr-policy {
	margin: 3rem 0;
	border: 0;
	border-top: 1px solid #ddd;
}

#policy .ol-policy,
#policy .ul-policy {
	padding-left: 1.6rem;
	margin-left: 0.25rem;
	margin-bottom: 3rem;
}

/* #policy .ol-policy > li-policy {
	list-style: decimal;
} */

#policy .ol-policy .ol-policy,
#policy .ol-policy .ul-policy,
#policy .ul-policy .ol-policy,
#policy .ul-policy .ul-policy {
	margin: 1.2rem 0 2.4rem;
}

#policy .ol-policy .li-policy{
	list-style: decimal;
}

#policy .ul-policy .li-policy{
	list-style: disc;
}

#policy {
	.ul-policy .ul-policy .li-policy,
	.ol-policy .li-policy .ul-policy .li-policy{
		list-style: circle;
	}
}


#policy .ol-policy .li-policy,
#policy .ul-policy .li-policy {
	margin-bottom: 0.8rem;
	word-break: keep-all;
	line-height: 1.5;
}

#policy .ul-policy .li-policy .p-policy,
#policy .ol-policy .li-policy .p-policy {
	margin: 0;
	line-height: 1.5;
}

#policy .title-policy {
	margin-top: 3rem;
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
}

#policy .img-policy {
	width: 200px;
}
