@import const.sass
#app-cal
  .btn
    // @include primeButton($color-defalut, $color-primary)
  .header-calendar
    display: flex
    flex-direction: column
    padding: 10px
    gap: 10px
    width: 340px
  .header-calendar-btn
    @include flex(row, space-between, center, 10px)
  .title-header
    width: 100%
    font-size: 18px
    font-weight: bold
    text-align: center
    margin-bottom: 10px
    margin-top: 10px
    border: none
    border-radius: 5px
    cursor: pointer 
    &:hover
      background: $color-primary
    &:active
      background: $color-primary  
  .calendar-box
    display: flex
    flex-direction: column
  .calendar-weekdays
    tr
      @include grid(1fr 1fr 1fr 1fr 1fr 1fr 1fr, center, center, 5px)
      // font-size: $font-size-small
      margin-bottom: 5px
      padding: 0px 5px 30px 5px
  .calendar-days
    tr
      @include grid(1fr 1fr 1fr 1fr 1fr 1fr 1fr, center, center, 0px)
  .calendar-day 
    padding: 0px
  .btn-today
    @include primeButton(#ffffff44, #ccc)
    border: 1px solid #ccc
  .prev-month
    @include primeButton(#ffffff44, #ccc)
    border: 1px solid #ccc
  .next-month
    @include primeButton(#ffffff44, #ccc)
    border: 1px solid #ccc
  .calendar-day-btn
    width: 100%
    min-height: 40px
    border: none
    background-color: transparent
    cursor: pointer
    display: flex
    justify-content: start
    align-items: center
    padding: 0px
    flex-direction: column
    // font-size: $font-size-medium
    height: 80px
    &:hover
      border: 1px solid #ccc
      border-radius: 5px
  .event-line
    width: 100%
    height: 8px
    pointer-events: none
    margin-top: 1px
    background-color: #7bc485
  .calendar-text
    // font-size: $font-size-medium
    text-decoration: none 
    margin-bottom: 3px
    margin-top: 3px
    pointer-events: none
  .days-prevmonth
    color: #ccc
  .full-day
    width: 100%
    border-radius: 0
  .full-day-start
    width: 100%
    border-radius: 5px 5px 0 0
  .full-day-end
    width: 100%
    border-radius: 0 0 5px 5px
  
  .start
    border-radius: 5px 0 0 5px
    width: 100%
  .end
    border-radius: 0 5px 5px 0
    width: 100%
  .single
    border-radius: 5px
    width: 80%
  .calendar-weekday-sun
    color: #ff0000
  .calendar-weekday-sat
    color: #ff9100

  .title-header-box-body
    display: flex
    flex-direction: column
    justify-content: center
    gap: 10px
    align-items: center
    width: 340px
    height: 100%
  .title-header-box-body-select
    display: flex
    justify-content: center
    gap: 10px
    width: 100%
  .title-header-box-body-btn
    display: flex
    justify-content: center
    gap: 10px
    width: 100%
  .title-header-box-body-year
    width: 100%
    height: 40px
    select
      height: 100%
      width: 100%
      
      font-weight: bold
      text-align: center
      background: #ffffff62
      border: none
      border-radius: 5px
      cursor: pointer 
      padding: 2px
      border: 1px solid #ccc
      font-size: 14px
      &:hover
        background: $color-primary
      &:active
        background: $color-primary  
    
  .title-header-box-body-month
    width: 100%
    height: 40px
    select
      width: 100%
      font-size: 14px
      font-weight: bold
      text-align: center
  
      background: #ffffff62
      border: none
      border-radius: 5px
      cursor: pointer 
      padding: 2px
      height: 100%
      border: 1px solid #ccc
      &:hover
        background: $color-primary
      &:active
        background: $color-primary  
  .title-header-box
    width: 100%
.Red
  background-color: #FF8787!important
  color: #ff0000
.Yellow
  background-color: #FF922B!important
  color: #FF9233
.Blue
  background-color: #5C7CFA!important
  color: #0000ff
.Green
  background-color: #20C997!important
  color: #00ff00
.Pink
  background-color: #BE4BDB!important
  color: #ff008c