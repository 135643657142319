*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  line-height: 1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
a {
  text-decoration: none;
  color: inherit;
}
a:visited,
a:visited *,
a.visited,
a.visited:visited {
  text-decoration: none;
}
a:hover,
a:hover *,
a.visited:hover {
  text-decoration: none;
}
ol,
ul,
li,
dd,
dl,
dt {
  list-style: none;
}
fieldset {
  padding: 0px;
  margin: 0px;
  border: 0px;
}
legend {
  display: none;
}
input {
  font-family: inherit;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s;
  transition: background-color 5000s;
  -webkit-text-fill-color: #333 !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset;
}
input::-ms-check {
  -ms-filter: alpha(opacity=0);
  filter: alpha(opacity=0);
  opacity: 0;
}
button {
  font-family: inherit;
  cursor: pointer;
}
select {
  font-family: inherit;
}
select::-ms-expand {
  display: none;
}
textarea {
  font-family: inherit;
}
table {
  border-collapse: collapse;
  border-spacing: 0px;
}
table caption {
  text-indent: -9999px;
  font-size: 0;
}
img {
  border: 0px;
  vertical-align: top;
}
.a11y {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: -9999em;
  left: -9999em;
  display: block;
  text-indent: -9999em;
  font-size: 0px;
  line-height: 0;
}
html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  font-family: "Pretendard", sans-serif;
  font-size: 1.6rem;
  letter-spacing: -0.03em;
  overflow-y: overlay;
}
body::-webkit-scrollbar {
  width: 4px;
}
body::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 0.49);
}
#root {
  height: 100%;
}
.hr {
  width: 100%;
  height: 4px;
  background: #f3f3f4;
  margin: 10px 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0) inset !important;
}
.flex {
  display: flex;
  gap: 4px;
  align-items: center;
}
.flex.wide {
  gap: 20px;
}
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
.hidden {
  display: none;
}
.hidden.on {
  display: block;
}
tr.hidden {
  display: none;
}
tr.hidden.on {
  display: table-row;
}
.blue {
  color: #1b61ec;
}
/* .red {
  color: #d81d1d;
} */
.green {
  color: #05dc86;
}
.flr {
  float: right;
}
.flr:after {
  content: "";
  display: block;
  clear: both;
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 100;
  src: url("../font/pretendard/woff2/Pretendard-Thin.woff2") format("woff2"),
    url("../font/pretendard/woff/Pretendard-Thin.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 200;
  src: url("../font/pretendard/woff2/Pretendard-ExtraLight.woff2")
      format("woff2"),
    url("../font/pretendard/woff/Pretendard-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  src: url("../font/pretendard/woff2/Pretendard-Light.woff2") format("woff2"),
    url("../font/pretendard/woff/Pretendard-Light.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  src: url("../font/pretendard/woff2/Pretendard-Regular.woff2") format("woff2"),
    url("../font/pretendard/woff/Pretendard-Regular.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  src: url("../font/pretendard/woff2/Pretendard-Medium.woff2") format("woff2"),
    url("../font/pretendard/woff/Pretendard-Medium.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  src: url("../font/pretendard/woff2/Pretendard-SemiBold.woff2") format("woff2"),
    url("../font/pretendard/woff/Pretendard-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  src: url("../font/pretendard/woff2/Pretendard-Bold.woff2") format("woff2"),
    url("../font/pretendard/woff/Pretendard-Bold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 800;
  src: url("../font/pretendard/woff2/Pretendard-ExtraBold.woff2")
      format("woff2"),
    url("../font/pretendard/woff/Pretendard-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 900;
  src: url("../font/pretendard/woff2/Pretendard-Black.woff2") format("woff2"),
    url("../font/pretendard/woff/Pretendard-Black.woff") format("woff");
}
@font-face {
  font-family: "remixicon";
  src: url("../font/remixicon/remixicon.eot?t=1590207869815");
  src: url("../font/remixicon/remixicon.eot?t=1590207869815#iefix")
      format("embedded-opentype"),
    url("../font/remixicon/remixicon.woff2?t=1590207869815") format("woff2"),
    url("../font/remixicon/remixicon.woff?t=1590207869815") format("woff"),
    url("../font/remixicon/remixicon.ttf?t=1590207869815") format("truetype"),
    url("../font/remixicon/remixicon.svg?t=1590207869815#remixicon")
      format("svg");
  font-display: swap;
}
[class^="ri-"],
[class*=" ri-"] {
  font-family: "remixicon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -0.075em;
}
.ri-xxs {
  font-size: 0.5em;
}
.ri-xs {
  font-size: 0.75em;
}
.ri-sm {
  font-size: 0.875em;
}
.ri-1x {
  font-size: 1em;
}
.ri-2x {
  font-size: 2em;
}
.ri-3x {
  font-size: 3em;
}
.ri-4x {
  font-size: 4em;
}
.ri-5x {
  font-size: 5em;
}
.ri-6x {
  font-size: 6em;
}
.ri-7x {
  font-size: 7em;
}
.ri-8x {
  font-size: 8em;
}
.ri-9x {
  font-size: 9em;
}
.ri-10x {
  font-size: 10em;
}
.ri-fw {
  text-align: center;
  width: 1.25em;
}
.ri-24-hours-fill:before {
  content: "";
}
.ri-24-hours-line:before {
  content: "";
}
.ri-4k-fill:before {
  content: "";
}
.ri-4k-line:before {
  content: "";
}
.ri-a-b:before {
  content: "";
}
.ri-account-box-fill:before {
  content: "";
}
.ri-account-box-line:before {
  content: "";
}
.ri-account-circle-fill:before {
  content: "";
}
.ri-account-circle-line:before {
  content: "";
}
.ri-account-pin-box-fill:before {
  content: "";
}
.ri-account-pin-box-line:before {
  content: "";
}
.ri-account-pin-circle-fill:before {
  content: "";
}
.ri-account-pin-circle-line:before {
  content: "";
}
.ri-add-box-fill:before {
  content: "";
}
.ri-add-box-line:before {
  content: "";
}
.ri-add-circle-fill:before {
  content: "";
}
.ri-add-circle-line:before {
  content: "";
}
.ri-add-fill:before {
  content: "";
}
.ri-add-line:before {
  content: "";
}
.ri-admin-fill:before {
  content: "";
}
.ri-admin-line:before {
  content: "";
}
.ri-advertisement-fill:before {
  content: "";
}
.ri-advertisement-line:before {
  content: "";
}
.ri-airplay-fill:before {
  content: "";
}
.ri-airplay-line:before {
  content: "";
}
.ri-alarm-fill:before {
  content: "";
}
.ri-alarm-line:before {
  content: "";
}
.ri-alarm-warning-fill:before {
  content: "";
}
.ri-alarm-warning-line:before {
  content: "";
}
.ri-album-fill:before {
  content: "";
}
.ri-album-line:before {
  content: "";
}
.ri-alert-fill:before {
  content: "";
}
.ri-alert-line:before {
  content: "";
}
.ri-aliens-fill:before {
  content: "";
}
.ri-aliens-line:before {
  content: "";
}
.ri-align-bottom:before {
  content: "";
}
.ri-align-center:before {
  content: "";
}
.ri-align-justify:before {
  content: "";
}
.ri-align-left:before {
  content: "";
}
.ri-align-right:before {
  content: "";
}
.ri-align-top:before {
  content: "";
}
.ri-align-vertically:before {
  content: "";
}
.ri-alipay-fill:before {
  content: "";
}
.ri-alipay-line:before {
  content: "";
}
.ri-amazon-fill:before {
  content: "";
}
.ri-amazon-line:before {
  content: "";
}
.ri-anchor-fill:before {
  content: "";
}
.ri-anchor-line:before {
  content: "";
}
.ri-ancient-gate-fill:before {
  content: "";
}
.ri-ancient-gate-line:before {
  content: "";
}
.ri-ancient-pavilion-fill:before {
  content: "";
}
.ri-ancient-pavilion-line:before {
  content: "";
}
.ri-android-fill:before {
  content: "";
}
.ri-android-line:before {
  content: "";
}
.ri-angularjs-fill:before {
  content: "";
}
.ri-angularjs-line:before {
  content: "";
}
.ri-anticlockwise-2-fill:before {
  content: "";
}
.ri-anticlockwise-2-line:before {
  content: "";
}
.ri-anticlockwise-fill:before {
  content: "";
}
.ri-anticlockwise-line:before {
  content: "";
}
.ri-app-store-fill:before {
  content: "";
}
.ri-app-store-line:before {
  content: "";
}
.ri-apple-fill:before {
  content: "";
}
.ri-apple-line:before {
  content: "";
}
.ri-apps-2-fill:before {
  content: "";
}
.ri-apps-2-line:before {
  content: "";
}
.ri-apps-fill:before {
  content: "";
}
.ri-apps-line:before {
  content: "";
}
.ri-archive-drawer-fill:before {
  content: "";
}
.ri-archive-drawer-line:before {
  content: "";
}
.ri-archive-fill:before {
  content: "";
}
.ri-archive-line:before {
  content: "";
}
.ri-arrow-down-circle-fill:before {
  content: "";
}
.ri-arrow-down-circle-line:before {
  content: "";
}
.ri-arrow-down-fill:before {
  content: "";
}
.ri-arrow-down-line:before {
  content: "";
}
.ri-arrow-down-s-fill:before {
  content: "";
}
.ri-arrow-down-s-line:before {
  content: "";
}
.ri-arrow-drop-down-fill:before {
  content: "";
}
.ri-arrow-drop-down-line:before {
  content: "";
}
.ri-arrow-drop-left-fill:before {
  content: "";
}
.ri-arrow-drop-left-line:before {
  content: "";
}
.ri-arrow-drop-right-fill:before {
  content: "";
}
.ri-arrow-drop-right-line:before {
  content: "";
}
.ri-arrow-drop-up-fill:before {
  content: "";
}
.ri-arrow-drop-up-line:before {
  content: "";
}
.ri-arrow-go-back-fill:before {
  content: "";
}
.ri-arrow-go-back-line:before {
  content: "";
}
.ri-arrow-go-forward-fill:before {
  content: "";
}
.ri-arrow-go-forward-line:before {
  content: "";
}
.ri-arrow-left-circle-fill:before {
  content: "";
}
.ri-arrow-left-circle-line:before {
  content: "";
}
.ri-arrow-left-down-fill:before {
  content: "";
}
.ri-arrow-left-down-line:before {
  content: "";
}
.ri-arrow-left-fill:before {
  content: "";
}
.ri-arrow-left-line:before {
  content: "";
}
.ri-arrow-left-right-fill:before {
  content: "";
}
.ri-arrow-left-right-line:before {
  content: "";
}
.ri-arrow-left-s-fill:before {
  content: "";
}
.ri-arrow-left-s-line:before {
  content: "";
}
.ri-arrow-left-up-fill:before {
  content: "";
}
.ri-arrow-left-up-line:before {
  content: "";
}
.ri-arrow-right-circle-fill:before {
  content: "";
}
.ri-arrow-right-circle-line:before {
  content: "";
}
.ri-arrow-right-down-fill:before {
  content: "";
}
.ri-arrow-right-down-line:before {
  content: "";
}
.ri-arrow-right-fill:before {
  content: "";
}
.ri-arrow-right-line:before {
  content: "";
}
.ri-arrow-right-s-fill:before {
  content: "";
}
.ri-arrow-right-s-line:before {
  content: "";
}
.ri-arrow-right-up-fill:before {
  content: "";
}
.ri-arrow-right-up-line:before {
  content: "";
}
.ri-arrow-up-circle-fill:before {
  content: "";
}
.ri-arrow-up-circle-line:before {
  content: "";
}
.ri-arrow-up-down-fill:before {
  content: "";
}
.ri-arrow-up-down-line:before {
  content: "";
}
.ri-arrow-up-fill:before {
  content: "";
}
.ri-arrow-up-line:before {
  content: "";
}
.ri-arrow-up-s-fill:before {
  content: "";
}
.ri-arrow-up-s-line:before {
  content: "";
}
.ri-artboard-2-fill:before {
  content: "";
}
.ri-artboard-2-line:before {
  content: "";
}
.ri-artboard-fill:before {
  content: "";
}
.ri-artboard-line:before {
  content: "";
}
.ri-article-fill:before {
  content: "";
}
.ri-article-line:before {
  content: "";
}
.ri-aspect-ratio-fill:before {
  content: "";
}
.ri-aspect-ratio-line:before {
  content: "";
}
.ri-asterisk:before {
  content: "";
}
.ri-at-fill:before {
  content: "";
}
.ri-at-line:before {
  content: "";
}
.ri-attachment-2:before {
  content: "";
}
.ri-attachment-fill:before {
  content: "";
}
.ri-attachment-line:before {
  content: "";
}
.ri-auction-fill:before {
  content: "";
}
.ri-auction-line:before {
  content: "";
}
.ri-award-fill:before {
  content: "";
}
.ri-award-line:before {
  content: "";
}
.ri-baidu-fill:before {
  content: "";
}
.ri-baidu-line:before {
  content: "";
}
.ri-ball-pen-fill:before {
  content: "";
}
.ri-ball-pen-line:before {
  content: "";
}
.ri-bank-card-2-fill:before {
  content: "";
}
.ri-bank-card-2-line:before {
  content: "";
}
.ri-bank-card-fill:before {
  content: "";
}
.ri-bank-card-line:before {
  content: "";
}
.ri-bank-fill:before {
  content: "";
}
.ri-bank-line:before {
  content: "";
}
.ri-bar-chart-2-fill:before {
  content: "";
}
.ri-bar-chart-2-line:before {
  content: "";
}
.ri-bar-chart-box-fill:before {
  content: "";
}
.ri-bar-chart-box-line:before {
  content: "";
}
.ri-bar-chart-fill:before {
  content: "";
}
.ri-bar-chart-grouped-fill:before {
  content: "";
}
.ri-bar-chart-grouped-line:before {
  content: "";
}
.ri-bar-chart-horizontal-fill:before {
  content: "";
}
.ri-bar-chart-horizontal-line:before {
  content: "";
}
.ri-bar-chart-line:before {
  content: "";
}
.ri-barcode-box-fill:before {
  content: "";
}
.ri-barcode-box-line:before {
  content: "";
}
.ri-barcode-fill:before {
  content: "";
}
.ri-barcode-line:before {
  content: "";
}
.ri-barricade-fill:before {
  content: "";
}
.ri-barricade-line:before {
  content: "";
}
.ri-base-station-fill:before {
  content: "";
}
.ri-base-station-line:before {
  content: "";
}
.ri-basketball-fill:before {
  content: "";
}
.ri-basketball-line:before {
  content: "";
}
.ri-battery-2-charge-fill:before {
  content: "";
}
.ri-battery-2-charge-line:before {
  content: "";
}
.ri-battery-2-fill:before {
  content: "";
}
.ri-battery-2-line:before {
  content: "";
}
.ri-battery-charge-fill:before {
  content: "";
}
.ri-battery-charge-line:before {
  content: "";
}
.ri-battery-fill:before {
  content: "";
}
.ri-battery-line:before {
  content: "";
}
.ri-battery-low-fill:before {
  content: "";
}
.ri-battery-low-line:before {
  content: "";
}
.ri-battery-saver-fill:before {
  content: "";
}
.ri-battery-saver-line:before {
  content: "";
}
.ri-battery-share-fill:before {
  content: "";
}
.ri-battery-share-line:before {
  content: "";
}
.ri-bear-smile-fill:before {
  content: "";
}
.ri-bear-smile-line:before {
  content: "";
}
.ri-behance-fill:before {
  content: "";
}
.ri-behance-line:before {
  content: "";
}
.ri-bell-fill:before {
  content: "";
}
.ri-bell-line:before {
  content: "";
}
.ri-bike-fill:before {
  content: "";
}
.ri-bike-line:before {
  content: "";
}
.ri-bilibili-fill:before {
  content: "";
}
.ri-bilibili-line:before {
  content: "";
}
.ri-bill-fill:before {
  content: "";
}
.ri-bill-line:before {
  content: "";
}
.ri-billiards-fill:before {
  content: "";
}
.ri-billiards-line:before {
  content: "";
}
.ri-bit-coin-fill:before {
  content: "";
}
.ri-bit-coin-line:before {
  content: "";
}
.ri-blaze-fill:before {
  content: "";
}
.ri-blaze-line:before {
  content: "";
}
.ri-bluetooth-connect-fill:before {
  content: "";
}
.ri-bluetooth-connect-line:before {
  content: "";
}
.ri-bluetooth-fill:before {
  content: "";
}
.ri-bluetooth-line:before {
  content: "";
}
.ri-blur-off-fill:before {
  content: "";
}
.ri-blur-off-line:before {
  content: "";
}
.ri-body-scan-fill:before {
  content: "";
}
.ri-body-scan-line:before {
  content: "";
}
.ri-bold:before {
  content: "";
}
.ri-book-2-fill:before {
  content: "";
}
.ri-book-2-line:before {
  content: "";
}
.ri-book-3-fill:before {
  content: "";
}
.ri-book-3-line:before {
  content: "";
}
.ri-book-fill:before {
  content: "";
}
.ri-book-line:before {
  content: "";
}
.ri-book-mark-fill:before {
  content: "";
}
.ri-book-mark-line:before {
  content: "";
}
.ri-book-open-fill:before {
  content: "";
}
.ri-book-open-line:before {
  content: "";
}
.ri-book-read-fill:before {
  content: "";
}
.ri-book-read-line:before {
  content: "";
}
.ri-booklet-fill:before {
  content: "";
}
.ri-booklet-line:before {
  content: "";
}
.ri-bookmark-2-fill:before {
  content: "";
}
.ri-bookmark-2-line:before {
  content: "";
}
.ri-bookmark-3-fill:before {
  content: "";
}
.ri-bookmark-3-line:before {
  content: "";
}
.ri-bookmark-fill:before {
  content: "";
}
.ri-bookmark-line:before {
  content: "";
}
.ri-boxing-fill:before {
  content: "";
}
.ri-boxing-line:before {
  content: "";
}
.ri-braces-fill:before {
  content: "";
}
.ri-braces-line:before {
  content: "";
}
.ri-brackets-fill:before {
  content: "";
}
.ri-brackets-line:before {
  content: "";
}
.ri-briefcase-2-fill:before {
  content: "";
}
.ri-briefcase-2-line:before {
  content: "";
}
.ri-briefcase-3-fill:before {
  content: "";
}
.ri-briefcase-3-line:before {
  content: "";
}
.ri-briefcase-4-fill:before {
  content: "";
}
.ri-briefcase-4-line:before {
  content: "";
}
.ri-briefcase-5-fill:before {
  content: "";
}
.ri-briefcase-5-line:before {
  content: "";
}
.ri-briefcase-fill:before {
  content: "";
}
.ri-briefcase-line:before {
  content: "";
}
.ri-bring-forward:before {
  content: "";
}
.ri-bring-to-front:before {
  content: "";
}
.ri-broadcast-fill:before {
  content: "";
}
.ri-broadcast-line:before {
  content: "";
}
.ri-brush-2-fill:before {
  content: "";
}
.ri-brush-2-line:before {
  content: "";
}
.ri-brush-3-fill:before {
  content: "";
}
.ri-brush-3-line:before {
  content: "";
}
.ri-brush-4-fill:before {
  content: "";
}
.ri-brush-4-line:before {
  content: "";
}
.ri-brush-fill:before {
  content: "";
}
.ri-brush-line:before {
  content: "";
}
.ri-bubble-chart-fill:before {
  content: "";
}
.ri-bubble-chart-line:before {
  content: "";
}
.ri-bug-2-fill:before {
  content: "";
}
.ri-bug-2-line:before {
  content: "";
}
.ri-bug-fill:before {
  content: "";
}
.ri-bug-line:before {
  content: "";
}
.ri-building-2-fill:before {
  content: "";
}
.ri-building-2-line:before {
  content: "";
}
.ri-building-3-fill:before {
  content: "";
}
.ri-building-3-line:before {
  content: "";
}
.ri-building-4-fill:before {
  content: "";
}
.ri-building-4-line:before {
  content: "";
}
.ri-building-fill:before {
  content: "";
}
.ri-building-line:before {
  content: "";
}
.ri-bus-2-fill:before {
  content: "";
}
.ri-bus-2-line:before {
  content: "";
}
.ri-bus-fill:before {
  content: "";
}
.ri-bus-line:before {
  content: "";
}
.ri-bus-wifi-fill:before {
  content: "";
}
.ri-bus-wifi-line:before {
  content: "";
}
.ri-cactus-fill:before {
  content: "";
}
.ri-cactus-line:before {
  content: "";
}
.ri-cake-2-fill:before {
  content: "";
}
.ri-cake-2-line:before {
  content: "";
}
.ri-cake-3-fill:before {
  content: "";
}
.ri-cake-3-line:before {
  content: "";
}
.ri-cake-fill:before {
  content: "";
}
.ri-cake-line:before {
  content: "";
}
.ri-calculator-fill:before {
  content: "";
}
.ri-calculator-line:before {
  content: "";
}
.ri-calendar-2-fill:before {
  content: "";
}
.ri-calendar-2-line:before {
  content: "";
}
.ri-calendar-1-line:before {
  content: "";
  display: inline-block;
  width: 24px; /* Adjust the size as needed */
  height: 24px;
  background-image: url("../font/remixicon/calendar-line_f.svg");
  background-size: cover; /* Adjust according to your SVG dimensions */
}
.ri-calendar-check-fill:before {
  content: "";
}
.ri-calendar-check-line:before {
  content: "";
}
.ri-calendar-event-fill:before {
  content: "";
}
.ri-calendar-event-line:before {
  content: "";
}
.ri-calendar-fill:before {
  content: "";
}
.ri-calendar-line:before {
  content: "";
}
.ri-calendar-todo-fill:before {
  content: "";
}
.ri-calendar-todo-line:before {
  content: "";
}
.ri-camera-2-fill:before {
  content: "";
}
.ri-camera-2-line:before {
  content: "";
}
.ri-camera-3-fill:before {
  content: "";
}
.ri-camera-3-line:before {
  content: "";
}
.ri-camera-fill:before {
  content: "";
}
.ri-camera-lens-fill:before {
  content: "";
}
.ri-camera-lens-line:before {
  content: "";
}
.ri-camera-line:before {
  content: "";
}
.ri-camera-off-fill:before {
  content: "";
}
.ri-camera-off-line:before {
  content: "";
}
.ri-camera-switch-fill:before {
  content: "";
}
.ri-camera-switch-line:before {
  content: "";
}
.ri-capsule-fill:before {
  content: "";
}
.ri-capsule-line:before {
  content: "";
}
.ri-car-fill:before {
  content: "";
}
.ri-car-line:before {
  content: "";
}
.ri-car-washing-fill:before {
  content: "";
}
.ri-car-washing-line:before {
  content: "";
}
.ri-caravan-fill:before {
  content: "";
}
.ri-caravan-line:before {
  content: "";
}
.ri-cast-fill:before {
  content: "";
}
.ri-cast-line:before {
  content: "";
}
.ri-cellphone-fill:before {
  content: "";
}
.ri-cellphone-line:before {
  content: "";
}
.ri-celsius-fill:before {
  content: "";
}
.ri-celsius-line:before {
  content: "";
}
.ri-centos-fill:before {
  content: "";
}
.ri-centos-line:before {
  content: "";
}
.ri-character-recognition-fill:before {
  content: "";
}
.ri-character-recognition-line:before {
  content: "";
}
.ri-charging-pile-2-fill:before {
  content: "";
}
.ri-charging-pile-2-line:before {
  content: "";
}
.ri-charging-pile-fill:before {
  content: "";
}
.ri-charging-pile-line:before {
  content: "";
}
.ri-chat-1-fill:before {
  content: "";
}
.ri-chat-1-line:before {
  content: "";
}
.ri-chat-2-fill:before {
  content: "";
}
.ri-chat-2-line:before {
  content: "";
}
.ri-chat-3-fill:before {
  content: "";
}
.ri-chat-3-line:before {
  content: "";
}
.ri-chat-4-fill:before {
  content: "";
}
.ri-chat-4-line:before {
  content: "";
}
.ri-chat-check-fill:before {
  content: "";
}
.ri-chat-check-line:before {
  content: "";
}
.ri-chat-delete-fill:before {
  content: "";
}
.ri-chat-delete-line:before {
  content: "";
}
.ri-chat-download-fill:before {
  content: "";
}
.ri-chat-download-line:before {
  content: "";
}
.ri-chat-follow-up-fill:before {
  content: "";
}
.ri-chat-follow-up-line:before {
  content: "";
}
.ri-chat-forward-fill:before {
  content: "";
}
.ri-chat-forward-line:before {
  content: "";
}
.ri-chat-heart-fill:before {
  content: "";
}
.ri-chat-heart-line:before {
  content: "";
}
.ri-chat-history-fill:before {
  content: "";
}
.ri-chat-history-line:before {
  content: "";
}
.ri-chat-new-fill:before {
  content: "";
}
.ri-chat-new-line:before {
  content: "";
}
.ri-chat-off-fill:before {
  content: "";
}
.ri-chat-off-line:before {
  content: "";
}
.ri-chat-poll-fill:before {
  content: "";
}
.ri-chat-poll-line:before {
  content: "";
}
.ri-chat-private-fill:before {
  content: "";
}
.ri-chat-private-line:before {
  content: "";
}
.ri-chat-quote-fill:before {
  content: "";
}
.ri-chat-quote-line:before {
  content: "";
}
.ri-chat-settings-fill:before {
  content: "";
}
.ri-chat-settings-line:before {
  content: "";
}
.ri-chat-smile-2-fill:before {
  content: "";
}
.ri-chat-smile-2-line:before {
  content: "";
}
.ri-chat-smile-3-fill:before {
  content: "";
}
.ri-chat-smile-3-line:before {
  content: "";
}
.ri-chat-smile-fill:before {
  content: "";
}
.ri-chat-smile-line:before {
  content: "";
}
.ri-chat-upload-fill:before {
  content: "";
}
.ri-chat-upload-line:before {
  content: "";
}
.ri-chat-voice-fill:before {
  content: "";
}
.ri-chat-voice-line:before {
  content: "";
}
.ri-check-double-fill:before {
  content: "";
}
.ri-check-double-line:before {
  content: "";
}
.ri-check-fill:before {
  content: "";
}
.ri-check-line:before {
  content: "";
}
.ri-checkbox-blank-circle-fill:before {
  content: "";
}
.ri-checkbox-blank-circle-line:before {
  content: "";
}
.ri-checkbox-blank-fill:before {
  content: "";
}
.ri-checkbox-blank-line:before {
  content: "";
}
.ri-checkbox-circle-fill:before {
  content: "";
}
.ri-checkbox-circle-line:before {
  content: "";
}
.ri-checkbox-fill:before {
  content: "";
}
.ri-checkbox-indeterminate-fill:before {
  content: "";
}
.ri-checkbox-indeterminate-line:before {
  content: "";
}
.ri-checkbox-line:before {
  content: "";
}
.ri-checkbox-multiple-blank-fill:before {
  content: "";
}
.ri-checkbox-multiple-blank-line:before {
  content: "";
}
.ri-checkbox-multiple-fill:before {
  content: "";
}
.ri-checkbox-multiple-line:before {
  content: "";
}
.ri-china-railway-fill:before {
  content: "";
}
.ri-china-railway-line:before {
  content: "";
}
.ri-chrome-fill:before {
  content: "";
}
.ri-chrome-line:before {
  content: "";
}
.ri-clapperboard-fill:before {
  content: "";
}
.ri-clapperboard-line:before {
  content: "";
}
.ri-clipboard-fill:before {
  content: "";
}
.ri-clipboard-line:before {
  content: "";
}
.ri-clockwise-2-fill:before {
  content: "";
}
.ri-clockwise-2-line:before {
  content: "";
}
.ri-clockwise-fill:before {
  content: "";
}
.ri-clockwise-line:before {
  content: "";
}
.ri-close-circle-fill:before {
  content: "";
}
.ri-close-circle-line:before {
  content: "";
}
.ri-close-fill:before {
  content: "";
}
.ri-close-line:before {
  content: "";
}
.ri-closed-captioning-fill:before {
  content: "";
}
.ri-closed-captioning-line:before {
  content: "";
}
.ri-cloud-fill:before {
  content: "";
}
.ri-cloud-line:before {
  content: "";
}
.ri-cloud-off-fill:before {
  content: "";
}
.ri-cloud-off-line:before {
  content: "";
}
.ri-cloud-windy-fill:before {
  content: "";
}
.ri-cloud-windy-line:before {
  content: "";
}
.ri-cloudy-2-fill:before {
  content: "";
}
.ri-cloudy-2-line:before {
  content: "";
}
.ri-cloudy-fill:before {
  content: "";
}
.ri-cloudy-line:before {
  content: "";
}
.ri-code-box-fill:before {
  content: "";
}
.ri-code-box-line:before {
  content: "";
}
.ri-code-fill:before {
  content: "";
}
.ri-code-line:before {
  content: "";
}
.ri-code-s-fill:before {
  content: "";
}
.ri-code-s-line:before {
  content: "";
}
.ri-code-s-slash-fill:before {
  content: "";
}
.ri-code-s-slash-line:before {
  content: "";
}
.ri-code-view:before {
  content: "";
}
.ri-codepen-fill:before {
  content: "";
}
.ri-codepen-line:before {
  content: "";
}
.ri-coin-fill:before {
  content: "";
}
.ri-coin-line:before {
  content: "";
}
.ri-coins-fill:before {
  content: "";
}
.ri-coins-line:before {
  content: "";
}
.ri-collage-fill:before {
  content: "";
}
.ri-collage-line:before {
  content: "";
}
.ri-command-fill:before {
  content: "";
}
.ri-command-line:before {
  content: "";
}
.ri-community-fill:before {
  content: "";
}
.ri-community-line:before {
  content: "";
}
.ri-compass-2-fill:before {
  content: "";
}
.ri-compass-2-line:before {
  content: "";
}
.ri-compass-3-fill:before {
  content: "";
}
.ri-compass-3-line:before {
  content: "";
}
.ri-compass-4-fill:before {
  content: "";
}
.ri-compass-4-line:before {
  content: "";
}
.ri-compass-discover-fill:before {
  content: "";
}
.ri-compass-discover-line:before {
  content: "";
}
.ri-compass-fill:before {
  content: "";
}
.ri-compass-line:before {
  content: "";
}
.ri-compasses-2-fill:before {
  content: "";
}
.ri-compasses-2-line:before {
  content: "";
}
.ri-compasses-fill:before {
  content: "";
}
.ri-compasses-line:before {
  content: "";
}
.ri-computer-fill:before {
  content: "";
}
.ri-computer-line:before {
  content: "";
}
.ri-contacts-book-2-fill:before {
  content: "";
}
.ri-contacts-book-2-line:before {
  content: "";
}
.ri-contacts-book-fill:before {
  content: "";
}
.ri-contacts-book-line:before {
  content: "";
}
.ri-contacts-book-upload-fill:before {
  content: "";
}
.ri-contacts-book-upload-line:before {
  content: "";
}
.ri-contacts-fill:before {
  content: "";
}
.ri-contacts-line:before {
  content: "";
}
.ri-contrast-2-fill:before {
  content: "";
}
.ri-contrast-2-line:before {
  content: "";
}
.ri-contrast-drop-2-fill:before {
  content: "";
}
.ri-contrast-drop-2-line:before {
  content: "";
}
.ri-contrast-drop-fill:before {
  content: "";
}
.ri-contrast-drop-line:before {
  content: "";
}
.ri-contrast-fill:before {
  content: "";
}
.ri-contrast-line:before {
  content: "";
}
.ri-copper-coin-fill:before {
  content: "";
}
.ri-copper-coin-line:before {
  content: "";
}
.ri-copper-diamond-fill:before {
  content: "";
}
.ri-copper-diamond-line:before {
  content: "";
}
.ri-copyleft-fill:before {
  content: "";
}
.ri-copyleft-line:before {
  content: "";
}
.ri-copyright-fill:before {
  content: "";
}
.ri-copyright-line:before {
  content: "";
}
.ri-coreos-fill:before {
  content: "";
}
.ri-coreos-line:before {
  content: "";
}
.ri-coupon-2-fill:before {
  content: "";
}
.ri-coupon-2-line:before {
  content: "";
}
.ri-coupon-3-fill:before {
  content: "";
}
.ri-coupon-3-line:before {
  content: "";
}
.ri-coupon-4-fill:before {
  content: "";
}
.ri-coupon-4-line:before {
  content: "";
}
.ri-coupon-5-fill:before {
  content: "";
}
.ri-coupon-5-line:before {
  content: "";
}
.ri-coupon-fill:before {
  content: "";
}
.ri-coupon-line:before {
  content: "";
}
.ri-cpu-fill:before {
  content: "";
}
.ri-cpu-line:before {
  content: "";
}
.ri-creative-commons-by-fill:before {
  content: "";
}
.ri-creative-commons-by-line:before {
  content: "";
}
.ri-creative-commons-fill:before {
  content: "";
}
.ri-creative-commons-line:before {
  content: "";
}
.ri-creative-commons-nc-fill:before {
  content: "";
}
.ri-creative-commons-nc-line:before {
  content: "";
}
.ri-creative-commons-nd-fill:before {
  content: "";
}
.ri-creative-commons-nd-line:before {
  content: "";
}
.ri-creative-commons-sa-fill:before {
  content: "";
}
.ri-creative-commons-sa-line:before {
  content: "";
}
.ri-creative-commons-zero-fill:before {
  content: "";
}
.ri-creative-commons-zero-line:before {
  content: "";
}
.ri-criminal-fill:before {
  content: "";
}
.ri-criminal-line:before {
  content: "";
}
.ri-crop-2-fill:before {
  content: "";
}
.ri-crop-2-line:before {
  content: "";
}
.ri-crop-fill:before {
  content: "";
}
.ri-crop-line:before {
  content: "";
}
.ri-css3-fill:before {
  content: "";
}
.ri-css3-line:before {
  content: "";
}
.ri-cup-fill:before {
  content: "";
}
.ri-cup-line:before {
  content: "";
}
.ri-currency-fill:before {
  content: "";
}
.ri-currency-line:before {
  content: "";
}
.ri-cursor-fill:before {
  content: "";
}
.ri-cursor-line:before {
  content: "";
}
.ri-customer-service-2-fill:before {
  content: "";
}
.ri-customer-service-2-line:before {
  content: "";
}
.ri-customer-service-fill:before {
  content: "";
}
.ri-customer-service-line:before {
  content: "";
}
.ri-dashboard-2-fill:before {
  content: "";
}
.ri-dashboard-2-line:before {
  content: "";
}
.ri-dashboard-3-fill:before {
  content: "";
}
.ri-dashboard-3-line:before {
  content: "";
}
.ri-dashboard-fill:before {
  content: "";
}
.ri-dashboard-line:before {
  content: "";
}
.ri-database-2-fill:before {
  content: "";
}
.ri-database-2-line:before {
  content: "";
}
.ri-database-fill:before {
  content: "";
}
.ri-database-line:before {
  content: "";
}
.ri-delete-back-2-fill:before {
  content: "";
}
.ri-delete-back-2-line:before {
  content: "";
}
.ri-delete-back-fill:before {
  content: "";
}
.ri-delete-back-line:before {
  content: "";
}
.ri-delete-bin-2-fill:before {
  content: "";
}
.ri-delete-bin-2-line:before {
  content: "";
}
.ri-delete-bin-3-fill:before {
  content: "";
}
.ri-delete-bin-3-line:before {
  content: "";
}
.ri-delete-bin-4-fill:before {
  content: "";
}
.ri-delete-bin-4-line:before {
  content: "";
}
.ri-delete-bin-5-fill:before {
  content: "";
}
.ri-delete-bin-5-line:before {
  content: "";
}
.ri-delete-bin-6-fill:before {
  content: "";
}
.ri-delete-bin-6-line:before {
  content: "";
}
.ri-delete-bin-7-fill:before {
  content: "";
}
.ri-delete-bin-7-line:before {
  content: "";
}
.ri-delete-bin-fill:before {
  content: "";
}
.ri-delete-bin-line:before {
  content: "";
}
.ri-delete-column:before {
  content: "";
}
.ri-delete-row:before {
  content: "";
}
.ri-device-fill:before {
  content: "";
}
.ri-device-line:before {
  content: "";
}
.ri-device-recover-fill:before {
  content: "";
}
.ri-device-recover-line:before {
  content: "";
}
.ri-dingding-fill:before {
  content: "";
}
.ri-dingding-line:before {
  content: "";
}
.ri-direction-fill:before {
  content: "";
}
.ri-direction-line:before {
  content: "";
}
.ri-disc-fill:before {
  content: "";
}
.ri-disc-line:before {
  content: "";
}
.ri-discord-fill:before {
  content: "";
}
.ri-discord-line:before {
  content: "";
}
.ri-discuss-fill:before {
  content: "";
}
.ri-discuss-line:before {
  content: "";
}
.ri-dislike-fill:before {
  content: "";
}
.ri-dislike-line:before {
  content: "";
}
.ri-disqus-fill:before {
  content: "";
}
.ri-disqus-line:before {
  content: "";
}
.ri-divide-fill:before {
  content: "";
}
.ri-divide-line:before {
  content: "";
}
.ri-donut-chart-fill:before {
  content: "";
}
.ri-donut-chart-line:before {
  content: "";
}
.ri-door-closed-fill:before {
  content: "";
}
.ri-door-closed-line:before {
  content: "";
}
.ri-door-fill:before {
  content: "";
}
.ri-door-line:before {
  content: "";
}
.ri-door-lock-box-fill:before {
  content: "";
}
.ri-door-lock-box-line:before {
  content: "";
}
.ri-door-lock-fill:before {
  content: "";
}
.ri-door-lock-line:before {
  content: "";
}
.ri-door-open-fill:before {
  content: "";
}
.ri-door-open-line:before {
  content: "";
}
.ri-dossier-fill:before {
  content: "";
}
.ri-dossier-line:before {
  content: "";
}
.ri-douban-fill:before {
  content: "";
}
.ri-douban-line:before {
  content: "";
}
.ri-double-quotes-l:before {
  content: "";
}
.ri-double-quotes-r:before {
  content: "";
}
.ri-download-2-fill:before {
  content: "";
}
.ri-download-2-line:before {
  content: "";
}
.ri-download-cloud-2-fill:before {
  content: "";
}
.ri-download-cloud-2-line:before {
  content: "";
}
.ri-download-cloud-fill:before {
  content: "";
}
.ri-download-cloud-line:before {
  content: "";
}
.ri-download-fill:before {
  content: "";
}
.ri-download-line:before {
  content: "";
}
.ri-draft-fill:before {
  content: "";
}
.ri-draft-line:before {
  content: "";
}
.ri-drag-drop-fill:before {
  content: "";
}
.ri-drag-drop-line:before {
  content: "";
}
.ri-drag-move-2-fill:before {
  content: "";
}
.ri-drag-move-2-line:before {
  content: "";
}
.ri-drag-move-fill:before {
  content: "";
}
.ri-drag-move-line:before {
  content: "";
}
.ri-dribbble-fill:before {
  content: "";
}
.ri-dribbble-line:before {
  content: "";
}
.ri-drive-fill:before {
  content: "";
}
.ri-drive-line:before {
  content: "";
}
.ri-drizzle-fill:before {
  content: "";
}
.ri-drizzle-line:before {
  content: "";
}
.ri-drop-fill:before {
  content: "";
}
.ri-drop-line:before {
  content: "";
}
.ri-dropbox-fill:before {
  content: "";
}
.ri-dropbox-line:before {
  content: "";
}
.ri-dual-sim-1-fill:before {
  content: "";
}
.ri-dual-sim-1-line:before {
  content: "";
}
.ri-dual-sim-2-fill:before {
  content: "";
}
.ri-dual-sim-2-line:before {
  content: "";
}
.ri-dv-fill:before {
  content: "";
}
.ri-dv-line:before {
  content: "";
}
.ri-dvd-fill:before {
  content: "";
}
.ri-dvd-line:before {
  content: "";
}
.ri-e-bike-2-fill:before {
  content: "";
}
.ri-e-bike-2-line:before {
  content: "";
}
.ri-e-bike-fill:before {
  content: "";
}
.ri-e-bike-line:before {
  content: "";
}
.ri-earth-fill:before {
  content: "";
}
.ri-earth-line:before {
  content: "";
}
.ri-earthquake-fill:before {
  content: "";
}
.ri-earthquake-line:before {
  content: "";
}
.ri-edge-fill:before {
  content: "";
}
.ri-edge-line:before {
  content: "";
}
.ri-edit-2-fill:before {
  content: "";
}
.ri-edit-2-line:before {
  content: "";
}
.ri-edit-box-fill:before {
  content: "";
}
.ri-edit-box-line:before {
  content: "";
}
.ri-edit-circle-fill:before {
  content: "";
}
.ri-edit-circle-line:before {
  content: "";
}
.ri-edit-fill:before {
  content: "";
}
.ri-edit-line:before {
  content: "";
}
.ri-eject-fill:before {
  content: "";
}
.ri-eject-line:before {
  content: "";
}
.ri-emotion-2-fill:before {
  content: "";
}
.ri-emotion-2-line:before {
  content: "";
}
.ri-emotion-fill:before {
  content: "";
}
.ri-emotion-happy-fill:before {
  content: "";
}
.ri-emotion-happy-line:before {
  content: "";
}
.ri-emotion-laugh-fill:before {
  content: "";
}
.ri-emotion-laugh-line:before {
  content: "";
}
.ri-emotion-line:before {
  content: "";
}
.ri-emotion-normal-fill:before {
  content: "";
}
.ri-emotion-normal-line:before {
  content: "";
}
.ri-emotion-sad-fill:before {
  content: "";
}
.ri-emotion-sad-line:before {
  content: "";
}
.ri-emotion-unhappy-fill:before {
  content: "";
}
.ri-emotion-unhappy-line:before {
  content: "";
}
.ri-empathize-fill:before {
  content: "";
}
.ri-empathize-line:before {
  content: "";
}
.ri-emphasis-cn:before {
  content: "";
}
.ri-emphasis:before {
  content: "";
}
.ri-english-input:before {
  content: "";
}
.ri-equalizer-fill:before {
  content: "";
}
.ri-equalizer-line:before {
  content: "";
}
.ri-eraser-fill:before {
  content: "";
}
.ri-eraser-line:before {
  content: "";
}
.ri-error-warning-fill:before {
  content: "";
}
.ri-error-warning-line:before {
  content: "";
}
.ri-evernote-fill:before {
  content: "";
}
.ri-evernote-line:before {
  content: "";
}
.ri-exchange-box-fill:before {
  content: "";
}
.ri-exchange-box-line:before {
  content: "";
}
.ri-exchange-cny-fill:before {
  content: "";
}
.ri-exchange-cny-line:before {
  content: "";
}
.ri-exchange-dollar-fill:before {
  content: "";
}
.ri-exchange-dollar-line:before {
  content: "";
}
.ri-exchange-fill:before {
  content: "";
}
.ri-exchange-funds-fill:before {
  content: "";
}
.ri-exchange-funds-line:before {
  content: "";
}
.ri-exchange-line:before {
  content: "";
}
.ri-external-link-fill:before {
  content: "";
}
.ri-external-link-line:before {
  content: "";
}
.ri-eye-2-fill:before {
  content: "";
}
.ri-eye-2-line:before {
  content: "";
}
.ri-eye-close-fill:before {
  content: "";
}
.ri-eye-close-line:before {
  content: "";
}
.ri-eye-fill:before {
  content: "";
}
.ri-eye-line:before {
  content: "";
}
.ri-eye-off-fill:before {
  content: "";
}
.ri-eye-off-line:before {
  content: "";
}
.ri-facebook-box-fill:before {
  content: "";
}
.ri-facebook-box-line:before {
  content: "";
}
.ri-facebook-circle-fill:before {
  content: "";
}
.ri-facebook-circle-line:before {
  content: "";
}
.ri-facebook-fill:before {
  content: "";
}
.ri-facebook-line:before {
  content: "";
}
.ri-fahrenheit-fill:before {
  content: "";
}
.ri-fahrenheit-line:before {
  content: "";
}
.ri-feedback-fill:before {
  content: "";
}
.ri-feedback-line:before {
  content: "";
}
.ri-file-2-fill:before {
  content: "";
}
.ri-file-2-line:before {
  content: "";
}
.ri-file-3-fill:before {
  content: "";
}
.ri-file-3-line:before {
  content: "";
}
.ri-file-4-fill:before {
  content: "";
}
.ri-file-4-line:before {
  content: "";
}
.ri-file-add-fill:before {
  content: "";
}
.ri-file-add-line:before {
  content: "";
}
.ri-file-chart-2-fill:before {
  content: "";
}
.ri-file-chart-2-line:before {
  content: "";
}
.ri-file-chart-fill:before {
  content: "";
}
.ri-file-chart-line:before {
  content: "";
}
.ri-file-cloud-fill:before {
  content: "";
}
.ri-file-cloud-line:before {
  content: "";
}
.ri-file-code-fill:before {
  content: "";
}
.ri-file-code-line:before {
  content: "";
}
.ri-file-copy-2-fill:before {
  content: "";
}
.ri-file-copy-2-line:before {
  content: "";
}
.ri-file-copy-fill:before {
  content: "";
}
.ri-file-copy-line:before {
  content: "";
}
.ri-file-damage-fill:before {
  content: "";
}
.ri-file-damage-line:before {
  content: "";
}
.ri-file-download-fill:before {
  content: "";
}
.ri-file-download-line:before {
  content: "";
}
.ri-file-edit-fill:before {
  content: "";
}
.ri-file-edit-line:before {
  content: "";
}
.ri-file-excel-2-fill:before {
  content: "";
}
.ri-file-excel-2-line:before {
  content: "";
}
.ri-file-excel-fill:before {
  content: "";
}
.ri-file-excel-line:before {
  content: "";
}
.ri-file-fill:before {
  content: "";
}
.ri-file-forbid-fill:before {
  content: "";
}
.ri-file-forbid-line:before {
  content: "";
}
.ri-file-gif-fill:before {
  content: "";
}
.ri-file-gif-line:before {
  content: "";
}
.ri-file-history-fill:before {
  content: "";
}
.ri-file-history-line:before {
  content: "";
}
.ri-file-hwp-fill:before {
  content: "";
}
.ri-file-hwp-line:before {
  content: "";
}
.ri-file-info-fill:before {
  content: "";
}
.ri-file-info-line:before {
  content: "";
}
.ri-file-line:before {
  content: "";
}
.ri-file-list-2-fill:before {
  content: "";
}
.ri-file-list-2-line:before {
  content: "";
}
.ri-file-list-3-fill:before {
  content: "";
}
.ri-file-list-3-line:before {
  content: "";
}
.ri-file-list-fill:before {
  content: "";
}
.ri-file-list-line:before {
  content: "";
}
.ri-file-lock-fill:before {
  content: "";
}
.ri-file-lock-line:before {
  content: "";
}
.ri-file-mark-fill:before {
  content: "";
}
.ri-file-mark-line:before {
  content: "";
}
.ri-file-music-fill:before {
  content: "";
}
.ri-file-music-line:before {
  content: "";
}
.ri-file-paper-2-fill:before {
  content: "";
}
.ri-file-paper-2-line:before {
  content: "";
}
.ri-file-paper-fill:before {
  content: "";
}
.ri-file-paper-line:before {
  content: "";
}
.ri-file-pdf-fill:before {
  content: "";
}
.ri-file-pdf-line:before {
  content: "";
}
.ri-file-ppt-2-fill:before {
  content: "";
}
.ri-file-ppt-2-line:before {
  content: "";
}
.ri-file-ppt-fill:before {
  content: "";
}
.ri-file-ppt-line:before {
  content: "";
}
.ri-file-reduce-fill:before {
  content: "";
}
.ri-file-reduce-line:before {
  content: "";
}
.ri-file-search-fill:before {
  content: "";
}
.ri-file-search-line:before {
  content: "";
}
.ri-file-settings-fill:before {
  content: "";
}
.ri-file-settings-line:before {
  content: "";
}
.ri-file-shield-2-fill:before {
  content: "";
}
.ri-file-shield-2-line:before {
  content: "";
}
.ri-file-shield-fill:before {
  content: "";
}
.ri-file-shield-line:before {
  content: "";
}
.ri-file-shred-fill:before {
  content: "";
}
.ri-file-shred-line:before {
  content: "";
}
.ri-file-text-fill:before {
  content: "";
}
.ri-file-text-line:before {
  content: "";
}
.ri-file-transfer-fill:before {
  content: "";
}
.ri-file-transfer-line:before {
  content: "";
}
.ri-file-unknow-fill:before {
  content: "";
}
.ri-file-unknow-line:before {
  content: "";
}
.ri-file-upload-fill:before {
  content: "";
}
.ri-file-upload-line:before {
  content: "";
}
.ri-file-user-fill:before {
  content: "";
}
.ri-file-user-line:before {
  content: "";
}
.ri-file-warning-fill:before {
  content: "";
}
.ri-file-warning-line:before {
  content: "";
}
.ri-file-word-2-fill:before {
  content: "";
}
.ri-file-word-2-line:before {
  content: "";
}
.ri-file-word-fill:before {
  content: "";
}
.ri-file-word-line:before {
  content: "";
}
.ri-file-zip-fill:before {
  content: "";
}
.ri-file-zip-line:before {
  content: "";
}
.ri-film-fill:before {
  content: "";
}
.ri-film-line:before {
  content: "";
}
.ri-filter-2-fill:before {
  content: "";
}
.ri-filter-2-line:before {
  content: "";
}
.ri-filter-3-fill:before {
  content: "";
}
.ri-filter-3-line:before {
  content: "";
}
.ri-filter-fill:before {
  content: "";
}
.ri-filter-line:before {
  content: "";
}
.ri-filter-off-fill:before {
  content: "";
}
.ri-filter-off-line:before {
  content: "";
}
.ri-find-replace-fill:before {
  content: "";
}
.ri-find-replace-line:before {
  content: "";
}
.ri-finder-fill:before {
  content: "";
}
.ri-finder-line:before {
  content: "";
}
.ri-fingerprint-2-fill:before {
  content: "";
}
.ri-fingerprint-2-line:before {
  content: "";
}
.ri-fingerprint-fill:before {
  content: "";
}
.ri-fingerprint-line:before {
  content: "";
}
.ri-fire-fill:before {
  content: "";
}
.ri-fire-line:before {
  content: "";
}
.ri-firefox-fill:before {
  content: "";
}
.ri-firefox-line:before {
  content: "";
}
.ri-first-aid-kit-fill:before {
  content: "";
}
.ri-first-aid-kit-line:before {
  content: "";
}
.ri-flag-2-fill:before {
  content: "";
}
.ri-flag-2-line:before {
  content: "";
}
.ri-flag-fill:before {
  content: "";
}
.ri-flag-line:before {
  content: "";
}
.ri-flashlight-fill:before {
  content: "";
}
.ri-flashlight-line:before {
  content: "";
}
.ri-flask-fill:before {
  content: "";
}
.ri-flask-line:before {
  content: "";
}
.ri-flight-land-fill:before {
  content: "";
}
.ri-flight-land-line:before {
  content: "";
}
.ri-flight-takeoff-fill:before {
  content: "";
}
.ri-flight-takeoff-line:before {
  content: "";
}
.ri-flood-fill:before {
  content: "";
}
.ri-flood-line:before {
  content: "";
}
.ri-flow-chart:before {
  content: "";
}
.ri-flutter-fill:before {
  content: "";
}
.ri-flutter-line:before {
  content: "";
}
.ri-focus-2-fill:before {
  content: "";
}
.ri-focus-2-line:before {
  content: "";
}
.ri-focus-3-fill:before {
  content: "";
}
.ri-focus-3-line:before {
  content: "";
}
.ri-focus-fill:before {
  content: "";
}
.ri-focus-line:before {
  content: "";
}
.ri-foggy-fill:before {
  content: "";
}
.ri-foggy-line:before {
  content: "";
}
.ri-folder-2-fill:before {
  content: "";
}
.ri-folder-2-line:before {
  content: "";
}
.ri-folder-3-fill:before {
  content: "";
}
.ri-folder-3-line:before {
  content: "";
}
.ri-folder-4-fill:before {
  content: "";
}
.ri-folder-4-line:before {
  content: "";
}
.ri-folder-5-fill:before {
  content: "";
}
.ri-folder-5-line:before {
  content: "";
}
.ri-folder-add-fill:before {
  content: "";
}
.ri-folder-add-line:before {
  content: "";
}
.ri-folder-chart-2-fill:before {
  content: "";
}
.ri-folder-chart-2-line:before {
  content: "";
}
.ri-folder-chart-fill:before {
  content: "";
}
.ri-folder-chart-line:before {
  content: "";
}
.ri-folder-download-fill:before {
  content: "";
}
.ri-folder-download-line:before {
  content: "";
}
.ri-folder-fill:before {
  content: "";
}
.ri-folder-forbid-fill:before {
  content: "";
}
.ri-folder-forbid-line:before {
  content: "";
}
.ri-folder-history-fill:before {
  content: "";
}
.ri-folder-history-line:before {
  content: "";
}
.ri-folder-info-fill:before {
  content: "";
}
.ri-folder-info-line:before {
  content: "";
}
.ri-folder-keyhole-fill:before {
  content: "";
}
.ri-folder-keyhole-line:before {
  content: "";
}
.ri-folder-line:before {
  content: "";
}
.ri-folder-lock-fill:before {
  content: "";
}
.ri-folder-lock-line:before {
  content: "";
}
.ri-folder-music-fill:before {
  content: "";
}
.ri-folder-music-line:before {
  content: "";
}
.ri-folder-open-fill:before {
  content: "";
}
.ri-folder-open-line:before {
  content: "";
}
.ri-folder-received-fill:before {
  content: "";
}
.ri-folder-received-line:before {
  content: "";
}
.ri-folder-reduce-fill:before {
  content: "";
}
.ri-folder-reduce-line:before {
  content: "";
}
.ri-folder-settings-fill:before {
  content: "";
}
.ri-folder-settings-line:before {
  content: "";
}
.ri-folder-shared-fill:before {
  content: "";
}
.ri-folder-shared-line:before {
  content: "";
}
.ri-folder-shield-2-fill:before {
  content: "";
}
.ri-folder-shield-2-line:before {
  content: "";
}
.ri-folder-shield-fill:before {
  content: "";
}
.ri-folder-shield-line:before {
  content: "";
}
.ri-folder-transfer-fill:before {
  content: "";
}
.ri-folder-transfer-line:before {
  content: "";
}
.ri-folder-unknow-fill:before {
  content: "";
}
.ri-folder-unknow-line:before {
  content: "";
}
.ri-folder-upload-fill:before {
  content: "";
}
.ri-folder-upload-line:before {
  content: "";
}
.ri-folder-user-fill:before {
  content: "";
}
.ri-folder-user-line:before {
  content: "";
}
.ri-folder-warning-fill:before {
  content: "";
}
.ri-folder-warning-line:before {
  content: "";
}
.ri-folder-zip-fill:before {
  content: "";
}
.ri-folder-zip-line:before {
  content: "";
}
.ri-folders-fill:before {
  content: "";
}
.ri-folders-line:before {
  content: "";
}
.ri-font-color:before {
  content: "";
}
.ri-font-size-2:before {
  content: "";
}
.ri-font-size:before {
  content: "";
}
.ri-football-fill:before {
  content: "";
}
.ri-football-line:before {
  content: "";
}
.ri-footprint-fill:before {
  content: "";
}
.ri-footprint-line:before {
  content: "";
}
.ri-forbid-2-fill:before {
  content: "";
}
.ri-forbid-2-line:before {
  content: "";
}
.ri-forbid-fill:before {
  content: "";
}
.ri-forbid-line:before {
  content: "";
}
.ri-format-clear:before {
  content: "";
}
.ri-fridge-fill:before {
  content: "";
}
.ri-fridge-line:before {
  content: "";
}
.ri-fullscreen-exit-fill:before {
  content: "";
}
.ri-fullscreen-exit-line:before {
  content: "";
}
.ri-fullscreen-fill:before {
  content: "";
}
.ri-fullscreen-line:before {
  content: "";
}
.ri-function-fill:before {
  content: "";
}
.ri-function-line:before {
  content: "";
}
.ri-functions:before {
  content: "";
}
.ri-funds-box-fill:before {
  content: "";
}
.ri-funds-box-line:before {
  content: "";
}
.ri-funds-fill:before {
  content: "";
}
.ri-funds-line:before {
  content: "";
}
.ri-gallery-fill:before {
  content: "";
}
.ri-gallery-line:before {
  content: "";
}
.ri-gallery-upload-fill:before {
  content: "";
}
.ri-gallery-upload-line:before {
  content: "";
}
.ri-game-fill:before {
  content: "";
}
.ri-game-line:before {
  content: "";
}
.ri-gamepad-fill:before {
  content: "";
}
.ri-gamepad-line:before {
  content: "";
}
.ri-gas-station-fill:before {
  content: "";
}
.ri-gas-station-line:before {
  content: "";
}
.ri-gatsby-fill:before {
  content: "";
}
.ri-gatsby-line:before {
  content: "";
}
.ri-genderless-fill:before {
  content: "";
}
.ri-genderless-line:before {
  content: "";
}
.ri-ghost-2-fill:before {
  content: "";
}
.ri-ghost-2-line:before {
  content: "";
}
.ri-ghost-fill:before {
  content: "";
}
.ri-ghost-line:before {
  content: "";
}
.ri-ghost-smile-fill:before {
  content: "";
}
.ri-ghost-smile-line:before {
  content: "";
}
.ri-gift-2-fill:before {
  content: "";
}
.ri-gift-2-line:before {
  content: "";
}
.ri-gift-fill:before {
  content: "";
}
.ri-gift-line:before {
  content: "";
}
.ri-git-branch-fill:before {
  content: "";
}
.ri-git-branch-line:before {
  content: "";
}
.ri-git-commit-fill:before {
  content: "";
}
.ri-git-commit-line:before {
  content: "";
}
.ri-git-merge-fill:before {
  content: "";
}
.ri-git-merge-line:before {
  content: "";
}
.ri-git-pull-request-fill:before {
  content: "";
}
.ri-git-pull-request-line:before {
  content: "";
}
.ri-git-repository-commits-fill:before {
  content: "";
}
.ri-git-repository-commits-line:before {
  content: "";
}
.ri-git-repository-fill:before {
  content: "";
}
.ri-git-repository-line:before {
  content: "";
}
.ri-git-repository-private-fill:before {
  content: "";
}
.ri-git-repository-private-line:before {
  content: "";
}
.ri-github-fill:before {
  content: "";
}
.ri-github-line:before {
  content: "";
}
.ri-gitlab-fill:before {
  content: "";
}
.ri-gitlab-line:before {
  content: "";
}
.ri-global-fill:before {
  content: "";
}
.ri-global-line:before {
  content: "";
}
.ri-globe-fill:before {
  content: "";
}
.ri-globe-line:before {
  content: "";
}
.ri-goblet-fill:before {
  content: "";
}
.ri-goblet-line:before {
  content: "";
}
.ri-google-fill:before {
  content: "";
}
.ri-google-line:before {
  content: "";
}
.ri-google-play-fill:before {
  content: "";
}
.ri-google-play-line:before {
  content: "";
}
.ri-government-fill:before {
  content: "";
}
.ri-government-line:before {
  content: "";
}
.ri-gps-fill:before {
  content: "";
}
.ri-gps-line:before {
  content: "";
}
.ri-gradienter-fill:before {
  content: "";
}
.ri-gradienter-line:before {
  content: "";
}
.ri-grid-fill:before {
  content: "";
}
.ri-grid-line:before {
  content: "";
}
.ri-group-2-fill:before {
  content: "";
}
.ri-group-2-line:before {
  content: "";
}
.ri-group-fill:before {
  content: "";
}
.ri-group-line:before {
  content: "";
}
.ri-guide-fill:before {
  content: "";
}
.ri-guide-line:before {
  content: "";
}
.ri-h-1:before {
  content: "";
}
.ri-h-2:before {
  content: "";
}
.ri-h-3:before {
  content: "";
}
.ri-h-4:before {
  content: "";
}
.ri-h-5:before {
  content: "";
}
.ri-h-6:before {
  content: "";
}
.ri-hail-fill:before {
  content: "";
}
.ri-hail-line:before {
  content: "";
}
.ri-hammer-fill:before {
  content: "";
}
.ri-hammer-line:before {
  content: "";
}
.ri-hand-coin-fill:before {
  content: "";
}
.ri-hand-coin-line:before {
  content: "";
}
.ri-hand-heart-fill:before {
  content: "";
}
.ri-hand-heart-line:before {
  content: "";
}
.ri-hand-sanitizer-fill:before {
  content: "";
}
.ri-hand-sanitizer-line:before {
  content: "";
}
.ri-handbag-fill:before {
  content: "";
}
.ri-handbag-line:before {
  content: "";
}
.ri-hard-drive-2-fill:before {
  content: "";
}
.ri-hard-drive-2-line:before {
  content: "";
}
.ri-hard-drive-fill:before {
  content: "";
}
.ri-hard-drive-line:before {
  content: "";
}
.ri-hashtag:before {
  content: "";
}
.ri-haze-2-fill:before {
  content: "";
}
.ri-haze-2-line:before {
  content: "";
}
.ri-haze-fill:before {
  content: "";
}
.ri-haze-line:before {
  content: "";
}
.ri-hd-fill:before {
  content: "";
}
.ri-hd-line:before {
  content: "";
}
.ri-heading:before {
  content: "";
}
.ri-headphone-fill:before {
  content: "";
}
.ri-headphone-line:before {
  content: "";
}
.ri-health-book-fill:before {
  content: "";
}
.ri-health-book-line:before {
  content: "";
}
.ri-heart-2-fill:before {
  content: "";
}
.ri-heart-2-line:before {
  content: "";
}
.ri-heart-3-fill:before {
  content: "";
}
.ri-heart-3-line:before {
  content: "";
}
.ri-heart-add-fill:before {
  content: "";
}
.ri-heart-add-line:before {
  content: "";
}
.ri-heart-fill:before {
  content: "";
}
.ri-heart-line:before {
  content: "";
}
.ri-heart-pulse-fill:before {
  content: "";
}
.ri-heart-pulse-line:before {
  content: "";
}
.ri-hearts-fill:before {
  content: "";
}
.ri-hearts-line:before {
  content: "";
}
.ri-heavy-showers-fill:before {
  content: "";
}
.ri-heavy-showers-line:before {
  content: "";
}
.ri-history-fill:before {
  content: "";
}
.ri-history-line:before {
  content: "";
}
.ri-home-2-fill:before {
  content: "";
}
.ri-home-2-line:before {
  content: "";
}
.ri-home-3-fill:before {
  content: "";
}
.ri-home-3-line:before {
  content: "";
}
.ri-home-4-fill:before {
  content: "";
}
.ri-home-4-line:before {
  content: "";
}
.ri-home-5-fill:before {
  content: "";
}
.ri-home-5-line:before {
  content: "";
}
.ri-home-6-fill:before {
  content: "";
}
.ri-home-6-line:before {
  content: "";
}
.ri-home-7-fill:before {
  content: "";
}
.ri-home-7-line:before {
  content: "";
}
.ri-home-8-fill:before {
  content: "";
}
.ri-home-8-line:before {
  content: "";
}
.ri-home-fill:before {
  content: "";
}
.ri-home-gear-fill:before {
  content: "";
}
.ri-home-gear-line:before {
  content: "";
}
.ri-home-heart-fill:before {
  content: "";
}
.ri-home-heart-line:before {
  content: "";
}
.ri-home-line:before {
  content: "";
}
.ri-home-smile-2-fill:before {
  content: "";
}
.ri-home-smile-2-line:before {
  content: "";
}
.ri-home-smile-fill:before {
  content: "";
}
.ri-home-smile-line:before {
  content: "";
}
.ri-home-wifi-fill:before {
  content: "";
}
.ri-home-wifi-line:before {
  content: "";
}
.ri-honor-of-kings-fill:before {
  content: "";
}
.ri-honor-of-kings-line:before {
  content: "";
}
.ri-honour-fill:before {
  content: "";
}
.ri-honour-line:before {
  content: "";
}
.ri-hospital-fill:before {
  content: "";
}
.ri-hospital-line:before {
  content: "";
}
.ri-hotel-bed-fill:before {
  content: "";
}
.ri-hotel-bed-line:before {
  content: "";
}
.ri-hotel-fill:before {
  content: "";
}
.ri-hotel-line:before {
  content: "";
}
.ri-hotspot-fill:before {
  content: "";
}
.ri-hotspot-line:before {
  content: "";
}
.ri-hq-fill:before {
  content: "";
}
.ri-hq-line:before {
  content: "";
}
.ri-html5-fill:before {
  content: "";
}
.ri-html5-line:before {
  content: "";
}
.ri-ie-fill:before {
  content: "";
}
.ri-ie-line:before {
  content: "";
}
.ri-image-2-fill:before {
  content: "";
}
.ri-image-2-line:before {
  content: "";
}
.ri-image-add-fill:before {
  content: "";
}
.ri-image-add-line:before {
  content: "";
}
.ri-image-edit-fill:before {
  content: "";
}
.ri-image-edit-line:before {
  content: "";
}
.ri-image-fill:before {
  content: "";
}
.ri-image-line:before {
  content: "";
}
.ri-inbox-archive-fill:before {
  content: "";
}
.ri-inbox-archive-line:before {
  content: "";
}
.ri-inbox-fill:before {
  content: "";
}
.ri-inbox-line:before {
  content: "";
}
.ri-inbox-unarchive-fill:before {
  content: "";
}
.ri-inbox-unarchive-line:before {
  content: "";
}
.ri-increase-decrease-fill:before {
  content: "";
}
.ri-increase-decrease-line:before {
  content: "";
}
.ri-indent-decrease:before {
  content: "";
}
.ri-indent-increase:before {
  content: "";
}
.ri-indeterminate-circle-fill:before {
  content: "";
}
.ri-indeterminate-circle-line:before {
  content: "";
}
.ri-information-fill:before {
  content: "";
}
.ri-information-line:before {
  content: "";
}
.ri-infrared-thermometer-fill:before {
  content: "";
}
.ri-infrared-thermometer-line:before {
  content: "";
}
.ri-ink-bottle-fill:before {
  content: "";
}
.ri-ink-bottle-line:before {
  content: "";
}
.ri-input-cursor-move:before {
  content: "";
}
.ri-input-method-fill:before {
  content: "";
}
.ri-input-method-line:before {
  content: "";
}
.ri-insert-column-left:before {
  content: "";
}
.ri-insert-column-right:before {
  content: "";
}
.ri-insert-row-bottom:before {
  content: "";
}
.ri-insert-row-top:before {
  content: "";
}
.ri-instagram-fill:before {
  content: "";
}
.ri-instagram-line:before {
  content: "";
}
.ri-install-fill:before {
  content: "";
}
.ri-install-line:before {
  content: "";
}
.ri-invision-fill:before {
  content: "";
}
.ri-invision-line:before {
  content: "";
}
.ri-italic:before {
  content: "";
}
.ri-kakao-talk-fill:before {
  content: "";
}
.ri-kakao-talk-line:before {
  content: "";
}
.ri-key-2-fill:before {
  content: "";
}
.ri-key-2-line:before {
  content: "";
}
.ri-key-fill:before {
  content: "";
}
.ri-key-line:before {
  content: "";
}
.ri-keyboard-box-fill:before {
  content: "";
}
.ri-keyboard-box-line:before {
  content: "";
}
.ri-keyboard-fill:before {
  content: "";
}
.ri-keyboard-line:before {
  content: "";
}
.ri-keynote-fill:before {
  content: "";
}
.ri-keynote-line:before {
  content: "";
}
.ri-knife-blood-fill:before {
  content: "";
}
.ri-knife-blood-line:before {
  content: "";
}
.ri-knife-fill:before {
  content: "";
}
.ri-knife-line:before {
  content: "";
}
.ri-landscape-fill:before {
  content: "";
}
.ri-landscape-line:before {
  content: "";
}
.ri-layout-2-fill:before {
  content: "";
}
.ri-layout-2-line:before {
  content: "";
}
.ri-layout-3-fill:before {
  content: "";
}
.ri-layout-3-line:before {
  content: "";
}
.ri-layout-4-fill:before {
  content: "";
}
.ri-layout-4-line:before {
  content: "";
}
.ri-layout-5-fill:before {
  content: "";
}
.ri-layout-5-line:before {
  content: "";
}
.ri-layout-6-fill:before {
  content: "";
}
.ri-layout-6-line:before {
  content: "";
}
.ri-layout-bottom-2-fill:before {
  content: "";
}
.ri-layout-bottom-2-line:before {
  content: "";
}
.ri-layout-bottom-fill:before {
  content: "";
}
.ri-layout-bottom-line:before {
  content: "";
}
.ri-layout-column-fill:before {
  content: "";
}
.ri-layout-column-line:before {
  content: "";
}
.ri-layout-fill:before {
  content: "";
}
.ri-layout-grid-fill:before {
  content: "";
}
.ri-layout-grid-line:before {
  content: "";
}
.ri-layout-left-2-fill:before {
  content: "";
}
.ri-layout-left-2-line:before {
  content: "";
}
.ri-layout-left-fill:before {
  content: "";
}
.ri-layout-left-line:before {
  content: "";
}
.ri-layout-line:before {
  content: "";
}
.ri-layout-masonry-fill:before {
  content: "";
}
.ri-layout-masonry-line:before {
  content: "";
}
.ri-layout-right-2-fill:before {
  content: "";
}
.ri-layout-right-2-line:before {
  content: "";
}
.ri-layout-right-fill:before {
  content: "";
}
.ri-layout-right-line:before {
  content: "";
}
.ri-layout-row-fill:before {
  content: "";
}
.ri-layout-row-line:before {
  content: "";
}
.ri-layout-top-2-fill:before {
  content: "";
}
.ri-layout-top-2-line:before {
  content: "";
}
.ri-layout-top-fill:before {
  content: "";
}
.ri-layout-top-line:before {
  content: "";
}
.ri-leaf-fill:before {
  content: "";
}
.ri-leaf-line:before {
  content: "";
}
.ri-lifebuoy-fill:before {
  content: "";
}
.ri-lifebuoy-line:before {
  content: "";
}
.ri-lightbulb-fill:before {
  content: "";
}
.ri-lightbulb-flash-fill:before {
  content: "";
}
.ri-lightbulb-flash-line:before {
  content: "";
}
.ri-lightbulb-line:before {
  content: "";
}
.ri-line-chart-fill:before {
  content: "";
}
.ri-line-chart-line:before {
  content: "";
}
.ri-line-fill:before {
  content: "";
}
.ri-line-height:before {
  content: "";
}
.ri-line-line:before {
  content: "";
}
.ri-link-m:before {
  content: "";
}
.ri-link-unlink-m:before {
  content: "";
}
.ri-link-unlink:before {
  content: "";
}
.ri-link:before {
  content: "";
}
.ri-linkedin-box-fill:before {
  content: "";
}
.ri-linkedin-box-line:before {
  content: "";
}
.ri-linkedin-fill:before {
  content: "";
}
.ri-linkedin-line:before {
  content: "";
}
.ri-links-fill:before {
  content: "";
}
.ri-links-line:before {
  content: "";
}
.ri-list-check-2:before {
  content: "";
}
.ri-list-check:before {
  content: "";
}
.ri-list-ordered:before {
  content: "";
}
.ri-list-settings-fill:before {
  content: "";
}
.ri-list-settings-line:before {
  content: "";
}
.ri-list-unordered:before {
  content: "";
}
.ri-live-fill:before {
  content: "";
}
.ri-live-line:before {
  content: "";
}
.ri-loader-2-fill:before {
  content: "";
}
.ri-loader-2-line:before {
  content: "";
}
.ri-loader-3-fill:before {
  content: "";
}
.ri-loader-3-line:before {
  content: "";
}
.ri-loader-4-fill:before {
  content: "";
}
.ri-loader-4-line:before {
  content: "";
}
.ri-loader-5-fill:before {
  content: "";
}
.ri-loader-5-line:before {
  content: "";
}
.ri-loader-fill:before {
  content: "";
}
.ri-loader-line:before {
  content: "";
}
.ri-lock-2-fill:before {
  content: "";
}
.ri-lock-2-line:before {
  content: "";
}
.ri-lock-fill:before {
  content: "";
}
.ri-lock-line:before {
  content: "";
}
.ri-lock-password-fill:before {
  content: "";
}
.ri-lock-password-line:before {
  content: "";
}
.ri-lock-unlock-fill:before {
  content: "";
}
.ri-lock-unlock-line:before {
  content: "";
}
.ri-login-box-fill:before {
  content: "";
}
.ri-login-box-line:before {
  content: "";
}
.ri-login-circle-fill:before {
  content: "";
}
.ri-login-circle-line:before {
  content: "";
}
.ri-logout-box-fill:before {
  content: "";
}
.ri-logout-box-line:before {
  content: "";
}
.ri-logout-box-r-fill:before {
  content: "";
}
.ri-logout-box-r-line:before {
  content: "";
}
.ri-logout-circle-fill:before {
  content: "";
}
.ri-logout-circle-line:before {
  content: "";
}
.ri-logout-circle-r-fill:before {
  content: "";
}
.ri-logout-circle-r-line:before {
  content: "";
}
.ri-luggage-cart-fill:before {
  content: "";
}
.ri-luggage-cart-line:before {
  content: "";
}
.ri-luggage-deposit-fill:before {
  content: "";
}
.ri-luggage-deposit-line:before {
  content: "";
}
.ri-lungs-fill:before {
  content: "";
}
.ri-lungs-line:before {
  content: "";
}
.ri-mac-fill:before {
  content: "";
}
.ri-mac-line:before {
  content: "";
}
.ri-macbook-fill:before {
  content: "";
}
.ri-macbook-line:before {
  content: "";
}
.ri-magic-fill:before {
  content: "";
}
.ri-magic-line:before {
  content: "";
}
.ri-mail-add-fill:before {
  content: "";
}
.ri-mail-add-line:before {
  content: "";
}
.ri-mail-check-fill:before {
  content: "";
}
.ri-mail-check-line:before {
  content: "";
}
.ri-mail-close-fill:before {
  content: "";
}
.ri-mail-close-line:before {
  content: "";
}
.ri-mail-download-fill:before {
  content: "";
}
.ri-mail-download-line:before {
  content: "";
}
.ri-mail-fill:before {
  content: "";
}
.ri-mail-forbid-fill:before {
  content: "";
}
.ri-mail-forbid-line:before {
  content: "";
}
.ri-mail-line:before {
  content: "";
}
.ri-mail-lock-fill:before {
  content: "";
}
.ri-mail-lock-line:before {
  content: "";
}
.ri-mail-open-fill:before {
  content: "";
}
.ri-mail-open-line:before {
  content: "";
}
.ri-mail-send-fill:before {
  content: "";
}
.ri-mail-send-line:before {
  content: "";
}
.ri-mail-settings-fill:before {
  content: "";
}
.ri-mail-settings-line:before {
  content: "";
}
.ri-mail-star-fill:before {
  content: "";
}
.ri-mail-star-line:before {
  content: "";
}
.ri-mail-unread-fill:before {
  content: "";
}
.ri-mail-unread-line:before {
  content: "";
}
.ri-mail-volume-fill:before {
  content: "";
}
.ri-mail-volume-line:before {
  content: "";
}
.ri-map-2-fill:before {
  content: "";
}
.ri-map-2-line:before {
  content: "";
}
.ri-map-fill:before {
  content: "";
}
.ri-map-line:before {
  content: "";
}
.ri-map-pin-2-fill:before {
  content: "";
}
.ri-map-pin-2-line:before {
  content: "";
}
.ri-map-pin-3-fill:before {
  content: "";
}
.ri-map-pin-3-line:before {
  content: "";
}
.ri-map-pin-4-fill:before {
  content: "";
}
.ri-map-pin-4-line:before {
  content: "";
}
.ri-map-pin-5-fill:before {
  content: "";
}
.ri-map-pin-5-line:before {
  content: "";
}
.ri-map-pin-add-fill:before {
  content: "";
}
.ri-map-pin-add-line:before {
  content: "";
}
.ri-map-pin-fill:before {
  content: "";
}
.ri-map-pin-line:before {
  content: "";
}
.ri-map-pin-range-fill:before {
  content: "";
}
.ri-map-pin-range-line:before {
  content: "";
}
.ri-map-pin-time-fill:before {
  content: "";
}
.ri-map-pin-time-line:before {
  content: "";
}
.ri-map-pin-user-fill:before {
  content: "";
}
.ri-map-pin-user-line:before {
  content: "";
}
.ri-mark-pen-fill:before {
  content: "";
}
.ri-mark-pen-line:before {
  content: "";
}
.ri-markdown-fill:before {
  content: "";
}
.ri-markdown-line:before {
  content: "";
}
.ri-markup-fill:before {
  content: "";
}
.ri-markup-line:before {
  content: "";
}
.ri-mastercard-fill:before {
  content: "";
}
.ri-mastercard-line:before {
  content: "";
}
.ri-mastodon-fill:before {
  content: "";
}
.ri-mastodon-line:before {
  content: "";
}
.ri-medal-2-fill:before {
  content: "";
}
.ri-medal-2-line:before {
  content: "";
}
.ri-medal-fill:before {
  content: "";
}
.ri-medal-line:before {
  content: "";
}
.ri-medicine-bottle-fill:before {
  content: "";
}
.ri-medicine-bottle-line:before {
  content: "";
}
.ri-medium-fill:before {
  content: "";
}
.ri-medium-line:before {
  content: "";
}
.ri-men-fill:before {
  content: "";
}
.ri-men-line:before {
  content: "";
}
.ri-mental-health-fill:before {
  content: "";
}
.ri-mental-health-line:before {
  content: "";
}
.ri-menu-2-fill:before {
  content: "";
}
.ri-menu-2-line:before {
  content: "";
}
.ri-menu-3-fill:before {
  content: "";
}
.ri-menu-3-line:before {
  content: "";
}
.ri-menu-4-fill:before {
  content: "";
}
.ri-menu-4-line:before {
  content: "";
}
.ri-menu-5-fill:before {
  content: "";
}
.ri-menu-5-line:before {
  content: "";
}
.ri-menu-add-fill:before {
  content: "";
}
.ri-menu-add-line:before {
  content: "";
}
.ri-menu-fill:before {
  content: "";
}
.ri-menu-fold-fill:before {
  content: "";
}
.ri-menu-fold-line:before {
  content: "";
}
.ri-menu-line:before {
  content: "";
}
.ri-menu-unfold-fill:before {
  content: "";
}
.ri-menu-unfold-line:before {
  content: "";
}
.ri-merge-cells-horizontal:before {
  content: "";
}
.ri-merge-cells-vertical:before {
  content: "";
}
.ri-message-2-fill:before {
  content: "";
}
.ri-message-2-line:before {
  content: "";
}
.ri-message-3-fill:before {
  content: "";
}
.ri-message-3-line:before {
  content: "";
}
.ri-message-fill:before {
  content: "";
}
.ri-message-line:before {
  content: "";
}
.ri-messenger-fill:before {
  content: "";
}
.ri-messenger-line:before {
  content: "";
}
.ri-meteor-fill:before {
  content: "";
}
.ri-meteor-line:before {
  content: "";
}
.ri-mic-2-fill:before {
  content: "";
}
.ri-mic-2-line:before {
  content: "";
}
.ri-mic-fill:before {
  content: "";
}
.ri-mic-line:before {
  content: "";
}
.ri-mic-off-fill:before {
  content: "";
}
.ri-mic-off-line:before {
  content: "";
}
.ri-mickey-fill:before {
  content: "";
}
.ri-mickey-line:before {
  content: "";
}
.ri-microscope-fill:before {
  content: "";
}
.ri-microscope-line:before {
  content: "";
}
.ri-microsoft-fill:before {
  content: "";
}
.ri-microsoft-line:before {
  content: "";
}
.ri-mind-map:before {
  content: "";
}
.ri-mini-program-fill:before {
  content: "";
}
.ri-mini-program-line:before {
  content: "";
}
.ri-mist-fill:before {
  content: "";
}
.ri-mist-line:before {
  content: "";
}
.ri-money-cny-box-fill:before {
  content: "";
}
.ri-money-cny-box-line:before {
  content: "";
}
.ri-money-cny-circle-fill:before {
  content: "";
}
.ri-money-cny-circle-line:before {
  content: "";
}
.ri-money-dollar-box-fill:before {
  content: "";
}
.ri-money-dollar-box-line:before {
  content: "";
}
.ri-money-dollar-circle-fill:before {
  content: "";
}
.ri-money-dollar-circle-line:before {
  content: "";
}
.ri-money-euro-box-fill:before {
  content: "";
}
.ri-money-euro-box-line:before {
  content: "";
}
.ri-money-euro-circle-fill:before {
  content: "";
}
.ri-money-euro-circle-line:before {
  content: "";
}
.ri-money-pound-box-fill:before {
  content: "";
}
.ri-money-pound-box-line:before {
  content: "";
}
.ri-money-pound-circle-fill:before {
  content: "";
}
.ri-money-pound-circle-line:before {
  content: "";
}
.ri-moon-clear-fill:before {
  content: "";
}
.ri-moon-clear-line:before {
  content: "";
}
.ri-moon-cloudy-fill:before {
  content: "";
}
.ri-moon-cloudy-line:before {
  content: "";
}
.ri-moon-fill:before {
  content: "";
}
.ri-moon-foggy-fill:before {
  content: "";
}
.ri-moon-foggy-line:before {
  content: "";
}
.ri-moon-line:before {
  content: "";
}
.ri-more-2-fill:before {
  content: "";
}
.ri-more-2-line:before {
  content: "";
}
.ri-more-fill:before {
  content: "";
}
.ri-more-line:before {
  content: "";
}
.ri-motorbike-fill:before {
  content: "";
}
.ri-motorbike-line:before {
  content: "";
}
.ri-mouse-fill:before {
  content: "";
}
.ri-mouse-line:before {
  content: "";
}
.ri-movie-2-fill:before {
  content: "";
}
.ri-movie-2-line:before {
  content: "";
}
.ri-movie-fill:before {
  content: "";
}
.ri-movie-line:before {
  content: "";
}
.ri-music-2-fill:before {
  content: "";
}
.ri-music-2-line:before {
  content: "";
}
.ri-music-fill:before {
  content: "";
}
.ri-music-line:before {
  content: "";
}
.ri-mv-fill:before {
  content: "";
}
.ri-mv-line:before {
  content: "";
}
.ri-navigation-fill:before {
  content: "";
}
.ri-navigation-line:before {
  content: "";
}
.ri-netease-cloud-music-fill:before {
  content: "";
}
.ri-netease-cloud-music-line:before {
  content: "";
}
.ri-netflix-fill:before {
  content: "";
}
.ri-netflix-line:before {
  content: "";
}
.ri-newspaper-fill:before {
  content: "";
}
.ri-newspaper-line:before {
  content: "";
}
.ri-node-tree:before {
  content: "";
}
.ri-notification-2-fill:before {
  content: "";
}
.ri-notification-2-line:before {
  content: "";
}
.ri-notification-3-fill:before {
  content: "";
}
.ri-notification-3-line:before {
  content: "";
}
.ri-notification-4-fill:before {
  content: "";
}
.ri-notification-4-line:before {
  content: "";
}
.ri-notification-badge-fill:before {
  content: "";
}
.ri-notification-badge-line:before {
  content: "";
}
.ri-notification-fill:before {
  content: "";
}
.ri-notification-line:before {
  content: "";
}
.ri-notification-off-fill:before {
  content: "";
}
.ri-notification-off-line:before {
  content: "";
}
.ri-npmjs-fill:before {
  content: "";
}
.ri-npmjs-line:before {
  content: "";
}
.ri-number-0:before {
  content: "";
}
.ri-number-1:before {
  content: "";
}
.ri-number-2:before {
  content: "";
}
.ri-number-3:before {
  content: "";
}
.ri-number-4:before {
  content: "";
}
.ri-number-5:before {
  content: "";
}
.ri-number-6:before {
  content: "";
}
.ri-number-7:before {
  content: "";
}
.ri-number-8:before {
  content: "";
}
.ri-number-9:before {
  content: "";
}
.ri-numbers-fill:before {
  content: "";
}
.ri-numbers-line:before {
  content: "";
}
.ri-nurse-fill:before {
  content: "";
}
.ri-nurse-line:before {
  content: "";
}
.ri-oil-fill:before {
  content: "";
}
.ri-oil-line:before {
  content: "";
}
.ri-omega:before {
  content: "";
}
.ri-open-arm-fill:before {
  content: "";
}
.ri-open-arm-line:before {
  content: "";
}
.ri-open-source-fill:before {
  content: "";
}
.ri-open-source-line:before {
  content: "";
}
.ri-opera-fill:before {
  content: "";
}
.ri-opera-line:before {
  content: "";
}
.ri-order-play-fill:before {
  content: "";
}
.ri-order-play-line:before {
  content: "";
}
.ri-organization-chart:before {
  content: "";
}
.ri-outlet-2-fill:before {
  content: "";
}
.ri-outlet-2-line:before {
  content: "";
}
.ri-outlet-fill:before {
  content: "";
}
.ri-outlet-line:before {
  content: "";
}
.ri-page-separator:before {
  content: "";
}
.ri-pages-fill:before {
  content: "";
}
.ri-pages-line:before {
  content: "";
}
.ri-paint-brush-fill:before {
  content: "";
}
.ri-paint-brush-line:before {
  content: "";
}
.ri-paint-fill:before {
  content: "";
}
.ri-paint-line:before {
  content: "";
}
.ri-palette-fill:before {
  content: "";
}
.ri-palette-line:before {
  content: "";
}
.ri-pantone-fill:before {
  content: "";
}
.ri-pantone-line:before {
  content: "";
}
.ri-paragraph:before {
  content: "";
}
.ri-parent-fill:before {
  content: "";
}
.ri-parent-line:before {
  content: "";
}
.ri-parentheses-fill:before {
  content: "";
}
.ri-parentheses-line:before {
  content: "";
}
.ri-parking-box-fill:before {
  content: "";
}
.ri-parking-box-line:before {
  content: "";
}
.ri-parking-fill:before {
  content: "";
}
.ri-parking-line:before {
  content: "";
}
.ri-passport-fill:before {
  content: "";
}
.ri-passport-line:before {
  content: "";
}
.ri-patreon-fill:before {
  content: "";
}
.ri-patreon-line:before {
  content: "";
}
.ri-pause-circle-fill:before {
  content: "";
}
.ri-pause-circle-line:before {
  content: "";
}
.ri-pause-fill:before {
  content: "";
}
.ri-pause-line:before {
  content: "";
}
.ri-pause-mini-fill:before {
  content: "";
}
.ri-pause-mini-line:before {
  content: "";
}
.ri-paypal-fill:before {
  content: "";
}
.ri-paypal-line:before {
  content: "";
}
.ri-pen-nib-fill:before {
  content: "";
}
.ri-pen-nib-line:before {
  content: "";
}
.ri-pencil-fill:before {
  content: "";
}
.ri-pencil-line:before {
  content: "";
}
.ri-pencil-ruler-2-fill:before {
  content: "";
}
.ri-pencil-ruler-2-line:before {
  content: "";
}
.ri-pencil-ruler-fill:before {
  content: "";
}
.ri-pencil-ruler-line:before {
  content: "";
}
.ri-percent-fill:before {
  content: "";
}
.ri-percent-line:before {
  content: "";
}
.ri-phone-camera-fill:before {
  content: "";
}
.ri-phone-camera-line:before {
  content: "";
}
.ri-phone-fill:before {
  content: "";
}
.ri-phone-find-fill:before {
  content: "";
}
.ri-phone-find-line:before {
  content: "";
}
.ri-phone-line:before {
  content: "";
}
.ri-phone-lock-fill:before {
  content: "";
}
.ri-phone-lock-line:before {
  content: "";
}
.ri-picture-in-picture-2-fill:before {
  content: "";
}
.ri-picture-in-picture-2-line:before {
  content: "";
}
.ri-picture-in-picture-exit-fill:before {
  content: "";
}
.ri-picture-in-picture-exit-line:before {
  content: "";
}
.ri-picture-in-picture-fill:before {
  content: "";
}
.ri-picture-in-picture-line:before {
  content: "";
}
.ri-pie-chart-2-fill:before {
  content: "";
}
.ri-pie-chart-2-line:before {
  content: "";
}
.ri-pie-chart-box-fill:before {
  content: "";
}
.ri-pie-chart-box-line:before {
  content: "";
}
.ri-pie-chart-fill:before {
  content: "";
}
.ri-pie-chart-line:before {
  content: "";
}
.ri-pin-distance-fill:before {
  content: "";
}
.ri-pin-distance-line:before {
  content: "";
}
.ri-ping-pong-fill:before {
  content: "";
}
.ri-ping-pong-line:before {
  content: "";
}
.ri-pinterest-fill:before {
  content: "";
}
.ri-pinterest-line:before {
  content: "";
}
.ri-pinyin-input:before {
  content: "";
}
.ri-pixelfed-fill:before {
  content: "";
}
.ri-pixelfed-line:before {
  content: "";
}
.ri-plane-fill:before {
  content: "";
}
.ri-plane-line:before {
  content: "";
}
.ri-plant-fill:before {
  content: "";
}
.ri-plant-line:before {
  content: "";
}
.ri-play-circle-fill:before {
  content: "";
}
.ri-play-circle-line:before {
  content: "";
}
.ri-play-fill:before {
  content: "";
}
.ri-play-line:before {
  content: "";
}
.ri-play-list-2-fill:before {
  content: "";
}
.ri-play-list-2-line:before {
  content: "";
}
.ri-play-list-add-fill:before {
  content: "";
}
.ri-play-list-add-line:before {
  content: "";
}
.ri-play-list-fill:before {
  content: "";
}
.ri-play-list-line:before {
  content: "";
}
.ri-play-mini-fill:before {
  content: "";
}
.ri-play-mini-line:before {
  content: "";
}
.ri-playstation-fill:before {
  content: "";
}
.ri-playstation-line:before {
  content: "";
}
.ri-plug-2-fill:before {
  content: "";
}
.ri-plug-2-line:before {
  content: "";
}
.ri-plug-fill:before {
  content: "";
}
.ri-plug-line:before {
  content: "";
}
.ri-polaroid-2-fill:before {
  content: "";
}
.ri-polaroid-2-line:before {
  content: "";
}
.ri-polaroid-fill:before {
  content: "";
}
.ri-polaroid-line:before {
  content: "";
}
.ri-police-car-fill:before {
  content: "";
}
.ri-police-car-line:before {
  content: "";
}
.ri-price-tag-2-fill:before {
  content: "";
}
.ri-price-tag-2-line:before {
  content: "";
}
.ri-price-tag-3-fill:before {
  content: "";
}
.ri-price-tag-3-line:before {
  content: "";
}
.ri-price-tag-fill:before {
  content: "";
}
.ri-price-tag-line:before {
  content: "";
}
.ri-printer-cloud-fill:before {
  content: "";
}
.ri-printer-cloud-line:before {
  content: "";
}
.ri-printer-fill:before {
  content: "";
}
.ri-printer-line:before {
  content: "";
}
.ri-product-hunt-fill:before {
  content: "";
}
.ri-product-hunt-line:before {
  content: "";
}
.ri-profile-fill:before {
  content: "";
}
.ri-profile-line:before {
  content: "";
}
.ri-projector-2-fill:before {
  content: "";
}
.ri-projector-2-line:before {
  content: "";
}
.ri-projector-fill:before {
  content: "";
}
.ri-projector-line:before {
  content: "";
}
.ri-psychotherapy-fill:before {
  content: "";
}
.ri-psychotherapy-line:before {
  content: "";
}
.ri-pulse-fill:before {
  content: "";
}
.ri-pulse-line:before {
  content: "";
}
.ri-pushpin-2-fill:before {
  content: "";
}
.ri-pushpin-2-line:before {
  content: "";
}
.ri-pushpin-fill:before {
  content: "";
}
.ri-pushpin-line:before {
  content: "";
}
.ri-qq-fill:before {
  content: "";
}
.ri-qq-line:before {
  content: "";
}
.ri-qr-code-fill:before {
  content: "";
}
.ri-qr-code-line:before {
  content: "";
}
.ri-qr-scan-2-fill:before {
  content: "";
}
.ri-qr-scan-2-line:before {
  content: "";
}
.ri-qr-scan-fill:before {
  content: "";
}
.ri-qr-scan-line:before {
  content: "";
}
.ri-question-answer-fill:before {
  content: "";
}
.ri-question-answer-line:before {
  content: "";
}
.ri-question-fill:before {
  content: "";
}
.ri-question-line:before {
  content: "";
}
.ri-question-mark:before {
  content: "";
}
.ri-questionnaire-fill:before {
  content: "";
}
.ri-questionnaire-line:before {
  content: "";
}
.ri-quill-pen-fill:before {
  content: "";
}
.ri-quill-pen-line:before {
  content: "";
}
.ri-radar-fill:before {
  content: "";
}
.ri-radar-line:before {
  content: "";
}
.ri-radio-2-fill:before {
  content: "";
}
.ri-radio-2-line:before {
  content: "";
}
.ri-radio-button-fill:before {
  content: "";
}
.ri-radio-button-line:before {
  content: "";
}
.ri-radio-fill:before {
  content: "";
}
.ri-radio-line:before {
  content: "";
}
.ri-rainbow-fill:before {
  content: "";
}
.ri-rainbow-line:before {
  content: "";
}
.ri-rainy-fill:before {
  content: "";
}
.ri-rainy-line:before {
  content: "";
}
.ri-reactjs-fill:before {
  content: "";
}
.ri-reactjs-line:before {
  content: "";
}
.ri-record-circle-fill:before {
  content: "";
}
.ri-record-circle-line:before {
  content: "";
}
.ri-record-mail-fill:before {
  content: "";
}
.ri-record-mail-line:before {
  content: "";
}
.ri-recycle-fill:before {
  content: "";
}
.ri-recycle-line:before {
  content: "";
}
.ri-red-packet-fill:before {
  content: "";
}
.ri-red-packet-line:before {
  content: "";
}
.ri-reddit-fill:before {
  content: "";
}
.ri-reddit-line:before {
  content: "";
}
.ri-refresh-fill:before {
  content: "";
}
.ri-refresh-line:before {
  content: "";
}
.ri-refund-2-fill:before {
  content: "";
}
.ri-refund-2-line:before {
  content: "";
}
.ri-refund-fill:before {
  content: "";
}
.ri-refund-line:before {
  content: "";
}
.ri-registered-fill:before {
  content: "";
}
.ri-registered-line:before {
  content: "";
}
.ri-remixicon-fill:before {
  content: "";
}
.ri-remixicon-line:before {
  content: "";
}
.ri-remote-control-2-fill:before {
  content: "";
}
.ri-remote-control-2-line:before {
  content: "";
}
.ri-remote-control-fill:before {
  content: "";
}
.ri-remote-control-line:before {
  content: "";
}
.ri-repeat-2-fill:before {
  content: "";
}
.ri-repeat-2-line:before {
  content: "";
}
.ri-repeat-fill:before {
  content: "";
}
.ri-repeat-line:before {
  content: "";
}
.ri-repeat-one-fill:before {
  content: "";
}
.ri-repeat-one-line:before {
  content: "";
}
.ri-reply-all-fill:before {
  content: "";
}
.ri-reply-all-line:before {
  content: "";
}
.ri-reply-fill:before {
  content: "";
}
.ri-reply-line:before {
  content: "";
}
.ri-reserved-fill:before {
  content: "";
}
.ri-reserved-line:before {
  content: "";
}
.ri-rest-time-fill:before {
  content: "";
}
.ri-rest-time-line:before {
  content: "";
}
.ri-restart-fill:before {
  content: "";
}
.ri-restart-line:before {
  content: "";
}
.ri-restaurant-2-fill:before {
  content: "";
}
.ri-restaurant-2-line:before {
  content: "";
}
.ri-restaurant-fill:before {
  content: "";
}
.ri-restaurant-line:before {
  content: "";
}
.ri-rewind-fill:before {
  content: "";
}
.ri-rewind-line:before {
  content: "";
}
.ri-rewind-mini-fill:before {
  content: "";
}
.ri-rewind-mini-line:before {
  content: "";
}
.ri-rhythm-fill:before {
  content: "";
}
.ri-rhythm-line:before {
  content: "";
}
.ri-riding-fill:before {
  content: "";
}
.ri-riding-line:before {
  content: "";
}
.ri-road-map-fill:before {
  content: "";
}
.ri-road-map-line:before {
  content: "";
}
.ri-roadster-fill:before {
  content: "";
}
.ri-roadster-line:before {
  content: "";
}
.ri-robot-fill:before {
  content: "";
}
.ri-robot-line:before {
  content: "";
}
.ri-rocket-2-fill:before {
  content: "";
}
.ri-rocket-2-line:before {
  content: "";
}
.ri-rocket-fill:before {
  content: "";
}
.ri-rocket-line:before {
  content: "";
}
.ri-rotate-lock-fill:before {
  content: "";
}
.ri-rotate-lock-line:before {
  content: "";
}
.ri-rounded-corner:before {
  content: "";
}
.ri-route-fill:before {
  content: "";
}
.ri-route-line:before {
  content: "";
}
.ri-router-fill:before {
  content: "";
}
.ri-router-line:before {
  content: "";
}
.ri-rss-fill:before {
  content: "";
}
.ri-rss-line:before {
  content: "";
}
.ri-ruler-2-fill:before {
  content: "";
}
.ri-ruler-2-line:before {
  content: "";
}
.ri-ruler-fill:before {
  content: "";
}
.ri-ruler-line:before {
  content: "";
}
.ri-run-fill:before {
  content: "";
}
.ri-run-line:before {
  content: "";
}
.ri-safari-fill:before {
  content: "";
}
.ri-safari-line:before {
  content: "";
}
.ri-safe-2-fill:before {
  content: "";
}
.ri-safe-2-line:before {
  content: "";
}
.ri-safe-fill:before {
  content: "";
}
.ri-safe-line:before {
  content: "";
}
.ri-sailboat-fill:before {
  content: "";
}
.ri-sailboat-line:before {
  content: "";
}
.ri-save-2-fill:before {
  content: "";
}
.ri-save-2-line:before {
  content: "";
}
.ri-save-3-fill:before {
  content: "";
}
.ri-save-3-line:before {
  content: "";
}
.ri-save-fill:before {
  content: "";
}
.ri-save-line:before {
  content: "";
}
.ri-scales-2-fill:before {
  content: "";
}
.ri-scales-2-line:before {
  content: "";
}
.ri-scales-3-fill:before {
  content: "";
}
.ri-scales-3-line:before {
  content: "";
}
.ri-scales-fill:before {
  content: "";
}
.ri-scales-line:before {
  content: "";
}
.ri-scan-2-fill:before {
  content: "";
}
.ri-scan-2-line:before {
  content: "";
}
.ri-scan-fill:before {
  content: "";
}
.ri-scan-line:before {
  content: "";
}
.ri-scissors-2-fill:before {
  content: "";
}
.ri-scissors-2-line:before {
  content: "";
}
.ri-scissors-cut-fill:before {
  content: "";
}
.ri-scissors-cut-line:before {
  content: "";
}
.ri-scissors-fill:before {
  content: "";
}
.ri-scissors-line:before {
  content: "";
}
.ri-screenshot-2-fill:before {
  content: "";
}
.ri-screenshot-2-line:before {
  content: "";
}
.ri-screenshot-fill:before {
  content: "";
}
.ri-screenshot-line:before {
  content: "";
}
.ri-sd-card-fill:before {
  content: "";
}
.ri-sd-card-line:before {
  content: "";
}
.ri-sd-card-mini-fill:before {
  content: "";
}
.ri-sd-card-mini-line:before {
  content: "";
}
.ri-search-2-fill:before {
  content: "";
}
.ri-search-2-line:before {
  content: "";
}
.ri-search-eye-fill:before {
  content: "";
}
.ri-search-eye-line:before {
  content: "";
}
.ri-search-fill:before {
  content: "";
}
.ri-search-line:before {
  content: "";
}
.ri-secure-payment-fill:before {
  content: "";
}
.ri-secure-payment-line:before {
  content: "";
}
.ri-seedling-fill:before {
  content: "";
}
.ri-seedling-line:before {
  content: "";
}
.ri-send-backward:before {
  content: "";
}
.ri-send-plane-2-fill:before {
  content: "";
}
.ri-send-plane-2-line:before {
  content: "";
}
.ri-send-plane-fill:before {
  content: "";
}
.ri-send-plane-line:before {
  content: "";
}
.ri-send-to-back:before {
  content: "";
}
.ri-sensor-fill:before {
  content: "";
}
.ri-sensor-line:before {
  content: "";
}
.ri-separator:before {
  content: "";
}
.ri-server-fill:before {
  content: "";
}
.ri-server-line:before {
  content: "";
}
.ri-service-fill:before {
  content: "";
}
.ri-service-line:before {
  content: "";
}
.ri-settings-2-fill:before {
  content: "";
}
.ri-settings-2-line:before {
  content: "";
}
.ri-settings-3-fill:before {
  content: "";
}
.ri-settings-3-line:before {
  content: "";
}
.ri-settings-4-fill:before {
  content: "";
}
.ri-settings-4-line:before {
  content: "";
}
.ri-settings-5-fill:before {
  content: "";
}
.ri-settings-5-line:before {
  content: "";
}
.ri-settings-6-fill:before {
  content: "";
}
.ri-settings-6-line:before {
  content: "";
}
.ri-settings-fill:before {
  content: "";
}
.ri-settings-line:before {
  content: "";
}
.ri-shape-2-fill:before {
  content: "";
}
.ri-shape-2-line:before {
  content: "";
}
.ri-shape-fill:before {
  content: "";
}
.ri-shape-line:before {
  content: "";
}
.ri-share-box-fill:before {
  content: "";
}
.ri-share-box-line:before {
  content: "";
}
.ri-share-circle-fill:before {
  content: "";
}
.ri-share-circle-line:before {
  content: "";
}
.ri-share-fill:before {
  content: "";
}
.ri-share-forward-2-fill:before {
  content: "";
}
.ri-share-forward-2-line:before {
  content: "";
}
.ri-share-forward-box-fill:before {
  content: "";
}
.ri-share-forward-box-line:before {
  content: "";
}
.ri-share-forward-fill:before {
  content: "";
}
.ri-share-forward-line:before {
  content: "";
}
.ri-share-line:before {
  content: "";
}
.ri-shield-check-fill:before {
  content: "";
}
.ri-shield-check-line:before {
  content: "";
}
.ri-shield-cross-fill:before {
  content: "";
}
.ri-shield-cross-line:before {
  content: "";
}
.ri-shield-fill:before {
  content: "";
}
.ri-shield-flash-fill:before {
  content: "";
}
.ri-shield-flash-line:before {
  content: "";
}
.ri-shield-keyhole-fill:before {
  content: "";
}
.ri-shield-keyhole-line:before {
  content: "";
}
.ri-shield-line:before {
  content: "";
}
.ri-shield-star-fill:before {
  content: "";
}
.ri-shield-star-line:before {
  content: "";
}
.ri-shield-user-fill:before {
  content: "";
}
.ri-shield-user-line:before {
  content: "";
}
.ri-ship-2-fill:before {
  content: "";
}
.ri-ship-2-line:before {
  content: "";
}
.ri-ship-fill:before {
  content: "";
}
.ri-ship-line:before {
  content: "";
}
.ri-shirt-fill:before {
  content: "";
}
.ri-shirt-line:before {
  content: "";
}
.ri-shopping-bag-2-fill:before {
  content: "";
}
.ri-shopping-bag-2-line:before {
  content: "";
}
.ri-shopping-bag-3-fill:before {
  content: "";
}
.ri-shopping-bag-3-line:before {
  content: "";
}
.ri-shopping-bag-fill:before {
  content: "";
}
.ri-shopping-bag-line:before {
  content: "";
}
.ri-shopping-basket-2-fill:before {
  content: "";
}
.ri-shopping-basket-2-line:before {
  content: "";
}
.ri-shopping-basket-fill:before {
  content: "";
}
.ri-shopping-basket-line:before {
  content: "";
}
.ri-shopping-cart-2-fill:before {
  content: "";
}
.ri-shopping-cart-2-line:before {
  content: "";
}
.ri-shopping-cart-fill:before {
  content: "";
}
.ri-shopping-cart-line:before {
  content: "";
}
.ri-showers-fill:before {
  content: "";
}
.ri-showers-line:before {
  content: "";
}
.ri-shuffle-fill:before {
  content: "";
}
.ri-shuffle-line:before {
  content: "";
}
.ri-shut-down-fill:before {
  content: "";
}
.ri-shut-down-line:before {
  content: "";
}
.ri-side-bar-fill:before {
  content: "";
}
.ri-side-bar-line:before {
  content: "";
}
.ri-signal-tower-fill:before {
  content: "";
}
.ri-signal-tower-line:before {
  content: "";
}
.ri-signal-wifi-1-fill:before {
  content: "";
}
.ri-signal-wifi-1-line:before {
  content: "";
}
.ri-signal-wifi-2-fill:before {
  content: "";
}
.ri-signal-wifi-2-line:before {
  content: "";
}
.ri-signal-wifi-3-fill:before {
  content: "";
}
.ri-signal-wifi-3-line:before {
  content: "";
}
.ri-signal-wifi-error-fill:before {
  content: "";
}
.ri-signal-wifi-error-line:before {
  content: "";
}
.ri-signal-wifi-fill:before {
  content: "";
}
.ri-signal-wifi-line:before {
  content: "";
}
.ri-signal-wifi-off-fill:before {
  content: "";
}
.ri-signal-wifi-off-line:before {
  content: "";
}
.ri-sim-card-2-fill:before {
  content: "";
}
.ri-sim-card-2-line:before {
  content: "";
}
.ri-sim-card-fill:before {
  content: "";
}
.ri-sim-card-line:before {
  content: "";
}
.ri-single-quotes-l:before {
  content: "";
}
.ri-single-quotes-r:before {
  content: "";
}
.ri-sip-fill:before {
  content: "";
}
.ri-sip-line:before {
  content: "";
}
.ri-skip-back-fill:before {
  content: "";
}
.ri-skip-back-line:before {
  content: "";
}
.ri-skip-back-mini-fill:before {
  content: "";
}
.ri-skip-back-mini-line:before {
  content: "";
}
.ri-skip-forward-fill:before {
  content: "";
}
.ri-skip-forward-line:before {
  content: "";
}
.ri-skip-forward-mini-fill:before {
  content: "";
}
.ri-skip-forward-mini-line:before {
  content: "";
}
.ri-skull-2-fill:before {
  content: "";
}
.ri-skull-2-line:before {
  content: "";
}
.ri-skull-fill:before {
  content: "";
}
.ri-skull-line:before {
  content: "";
}
.ri-skype-fill:before {
  content: "";
}
.ri-skype-line:before {
  content: "";
}
.ri-slack-fill:before {
  content: "";
}
.ri-slack-line:before {
  content: "";
}
.ri-slice-fill:before {
  content: "";
}
.ri-slice-line:before {
  content: "";
}
.ri-slideshow-2-fill:before {
  content: "";
}
.ri-slideshow-2-line:before {
  content: "";
}
.ri-slideshow-3-fill:before {
  content: "";
}
.ri-slideshow-3-line:before {
  content: "";
}
.ri-slideshow-4-fill:before {
  content: "";
}
.ri-slideshow-4-line:before {
  content: "";
}
.ri-slideshow-fill:before {
  content: "";
}
.ri-slideshow-line:before {
  content: "";
}
.ri-smartphone-fill:before {
  content: "";
}
.ri-smartphone-line:before {
  content: "";
}
.ri-snapchat-fill:before {
  content: "";
}
.ri-snapchat-line:before {
  content: "";
}
.ri-snowy-fill:before {
  content: "";
}
.ri-snowy-line:before {
  content: "";
}
.ri-sort-asc:before {
  content: "";
}
.ri-sort-desc:before {
  content: "";
}
.ri-sound-module-fill:before {
  content: "";
}
.ri-sound-module-line:before {
  content: "";
}
.ri-soundcloud-fill:before {
  content: "";
}
.ri-soundcloud-line:before {
  content: "";
}
.ri-space-ship-fill:before {
  content: "";
}
.ri-space-ship-line:before {
  content: "";
}
.ri-space:before {
  content: "";
}
.ri-spam-2-fill:before {
  content: "";
}
.ri-spam-2-line:before {
  content: "";
}
.ri-spam-3-fill:before {
  content: "";
}
.ri-spam-3-line:before {
  content: "";
}
.ri-spam-fill:before {
  content: "";
}
.ri-spam-line:before {
  content: "";
}
.ri-speaker-2-fill:before {
  content: "";
}
.ri-speaker-2-line:before {
  content: "";
}
.ri-speaker-3-fill:before {
  content: "";
}
.ri-speaker-3-line:before {
  content: "";
}
.ri-speaker-fill:before {
  content: "";
}
.ri-speaker-line:before {
  content: "";
}
.ri-spectrum-fill:before {
  content: "";
}
.ri-spectrum-line:before {
  content: "";
}
.ri-speed-fill:before {
  content: "";
}
.ri-speed-line:before {
  content: "";
}
.ri-speed-mini-fill:before {
  content: "";
}
.ri-speed-mini-line:before {
  content: "";
}
.ri-split-cells-horizontal:before {
  content: "";
}
.ri-split-cells-vertical:before {
  content: "";
}
.ri-spotify-fill:before {
  content: "";
}
.ri-spotify-line:before {
  content: "";
}
.ri-spy-fill:before {
  content: "";
}
.ri-spy-line:before {
  content: "";
}
.ri-stack-fill:before {
  content: "";
}
.ri-stack-line:before {
  content: "";
}
.ri-stack-overflow-fill:before {
  content: "";
}
.ri-stack-overflow-line:before {
  content: "";
}
.ri-stackshare-fill:before {
  content: "";
}
.ri-stackshare-line:before {
  content: "";
}
.ri-star-fill:before {
  content: "";
}
.ri-star-half-fill:before {
  content: "";
}
.ri-star-half-line:before {
  content: "";
}
.ri-star-half-s-fill:before {
  content: "";
}
.ri-star-half-s-line:before {
  content: "";
}
.ri-star-line:before {
  content: "";
}
.ri-star-s-fill:before {
  content: "";
}
.ri-star-s-line:before {
  content: "";
}
.ri-star-smile-fill:before {
  content: "";
}
.ri-star-smile-line:before {
  content: "";
}
.ri-steam-fill:before {
  content: "";
}
.ri-steam-line:before {
  content: "";
}
.ri-steering-2-fill:before {
  content: "";
}
.ri-steering-2-line:before {
  content: "";
}
.ri-steering-fill:before {
  content: "";
}
.ri-steering-line:before {
  content: "";
}
.ri-stethoscope-fill:before {
  content: "";
}
.ri-stethoscope-line:before {
  content: "";
}
.ri-sticky-note-2-fill:before {
  content: "";
}
.ri-sticky-note-2-line:before {
  content: "";
}
.ri-sticky-note-fill:before {
  content: "";
}
.ri-sticky-note-line:before {
  content: "";
}
.ri-stock-fill:before {
  content: "";
}
.ri-stock-line:before {
  content: "";
}
.ri-stop-circle-fill:before {
  content: "";
}
.ri-stop-circle-line:before {
  content: "";
}
.ri-stop-fill:before {
  content: "";
}
.ri-stop-line:before {
  content: "";
}
.ri-stop-mini-fill:before {
  content: "";
}
.ri-stop-mini-line:before {
  content: "";
}
.ri-store-2-fill:before {
  content: "";
}
.ri-store-2-line:before {
  content: "";
}
.ri-store-3-fill:before {
  content: "";
}
.ri-store-3-line:before {
  content: "";
}
.ri-store-fill:before {
  content: "";
}
.ri-store-line:before {
  content: "";
}
.ri-strikethrough-2:before {
  content: "";
}
.ri-strikethrough:before {
  content: "";
}
.ri-subscript-2:before {
  content: "";
}
.ri-subscript:before {
  content: "";
}
.ri-subtract-fill:before {
  content: "";
}
.ri-subtract-line:before {
  content: "";
}
.ri-subway-fill:before {
  content: "";
}
.ri-subway-line:before {
  content: "";
}
.ri-subway-wifi-fill:before {
  content: "";
}
.ri-subway-wifi-line:before {
  content: "";
}
.ri-suitcase-2-fill:before {
  content: "";
}
.ri-suitcase-2-line:before {
  content: "";
}
.ri-suitcase-3-fill:before {
  content: "";
}
.ri-suitcase-3-line:before {
  content: "";
}
.ri-suitcase-fill:before {
  content: "";
}
.ri-suitcase-line:before {
  content: "";
}
.ri-sun-cloudy-fill:before {
  content: "";
}
.ri-sun-cloudy-line:before {
  content: "";
}
.ri-sun-fill:before {
  content: "";
}
.ri-sun-foggy-fill:before {
  content: "";
}
.ri-sun-foggy-line:before {
  content: "";
}
.ri-sun-line:before {
  content: "";
}
.ri-superscript-2:before {
  content: "";
}
.ri-superscript:before {
  content: "";
}
.ri-surgical-mask-fill:before {
  content: "";
}
.ri-surgical-mask-line:before {
  content: "";
}
.ri-surround-sound-fill:before {
  content: "";
}
.ri-surround-sound-line:before {
  content: "";
}
.ri-survey-fill:before {
  content: "";
}
.ri-survey-line:before {
  content: "";
}
.ri-swap-box-fill:before {
  content: "";
}
.ri-swap-box-line:before {
  content: "";
}
.ri-swap-fill:before {
  content: "";
}
.ri-swap-line:before {
  content: "";
}
.ri-switch-fill:before {
  content: "";
}
.ri-switch-line:before {
  content: "";
}
.ri-sword-fill:before {
  content: "";
}
.ri-sword-line:before {
  content: "";
}
.ri-syringe-fill:before {
  content: "";
}
.ri-syringe-line:before {
  content: "";
}
.ri-t-box-fill:before {
  content: "";
}
.ri-t-box-line:before {
  content: "";
}
.ri-t-shirt-2-fill:before {
  content: "";
}
.ri-t-shirt-2-line:before {
  content: "";
}
.ri-t-shirt-air-fill:before {
  content: "";
}
.ri-t-shirt-air-line:before {
  content: "";
}
.ri-t-shirt-fill:before {
  content: "";
}
.ri-t-shirt-line:before {
  content: "";
}
.ri-table-2:before {
  content: "";
}
.ri-table-alt-fill:before {
  content: "";
}
.ri-table-alt-line:before {
  content: "";
}
.ri-table-fill:before {
  content: "";
}
.ri-table-line:before {
  content: "";
}
.ri-tablet-fill:before {
  content: "";
}
.ri-tablet-line:before {
  content: "";
}
.ri-takeaway-fill:before {
  content: "";
}
.ri-takeaway-line:before {
  content: "";
}
.ri-taobao-fill:before {
  content: "";
}
.ri-taobao-line:before {
  content: "";
}
.ri-tape-fill:before {
  content: "";
}
.ri-tape-line:before {
  content: "";
}
.ri-task-fill:before {
  content: "";
}
.ri-task-line:before {
  content: "";
}
.ri-taxi-fill:before {
  content: "";
}
.ri-taxi-line:before {
  content: "";
}
.ri-taxi-wifi-fill:before {
  content: "";
}
.ri-taxi-wifi-line:before {
  content: "";
}
.ri-team-fill:before {
  content: "";
}
.ri-team-line:before {
  content: "";
}
.ri-telegram-fill:before {
  content: "";
}
.ri-telegram-line:before {
  content: "";
}
.ri-temp-cold-fill:before {
  content: "";
}
.ri-temp-cold-line:before {
  content: "";
}
.ri-temp-hot-fill:before {
  content: "";
}
.ri-temp-hot-line:before {
  content: "";
}
.ri-terminal-box-fill:before {
  content: "";
}
.ri-terminal-box-line:before {
  content: "";
}
.ri-terminal-fill:before {
  content: "";
}
.ri-terminal-line:before {
  content: "";
}
.ri-terminal-window-fill:before {
  content: "";
}
.ri-terminal-window-line:before {
  content: "";
}
.ri-test-tube-fill:before {
  content: "";
}
.ri-test-tube-line:before {
  content: "";
}
.ri-text-direction-l:before {
  content: "";
}
.ri-text-direction-r:before {
  content: "";
}
.ri-text-spacing:before {
  content: "";
}
.ri-text-wrap:before {
  content: "";
}
.ri-text:before {
  content: "";
}
.ri-thermometer-fill:before {
  content: "";
}
.ri-thermometer-line:before {
  content: "";
}
.ri-thumb-down-fill:before {
  content: "";
}
.ri-thumb-down-line:before {
  content: "";
}
.ri-thumb-up-fill:before {
  content: "";
}
.ri-thumb-up-line:before {
  content: "";
}
.ri-thunderstorms-fill:before {
  content: "";
}
.ri-thunderstorms-line:before {
  content: "";
}
.ri-ticket-2-fill:before {
  content: "";
}
.ri-ticket-2-line:before {
  content: "";
}
.ri-ticket-fill:before {
  content: "";
}
.ri-ticket-line:before {
  content: "";
}
.ri-time-fill:before {
  content: "";
}
.ri-time-line:before {
  content: "";
}
.ri-timer-2-fill:before {
  content: "";
}
.ri-timer-2-line:before {
  content: "";
}
.ri-timer-fill:before {
  content: "";
}
.ri-timer-flash-fill:before {
  content: "";
}
.ri-timer-flash-line:before {
  content: "";
}
.ri-timer-line:before {
  content: "";
}
.ri-todo-fill:before {
  content: "";
}
.ri-todo-line:before {
  content: "";
}
.ri-toggle-fill:before {
  content: "";
}
.ri-toggle-line:before {
  content: "";
}
.ri-tools-fill:before {
  content: "";
}
.ri-tools-line:before {
  content: "";
}
.ri-tornado-fill:before {
  content: "";
}
.ri-tornado-line:before {
  content: "";
}
.ri-trademark-fill:before {
  content: "";
}
.ri-trademark-line:before {
  content: "";
}
.ri-traffic-light-fill:before {
  content: "";
}
.ri-traffic-light-line:before {
  content: "";
}
.ri-train-fill:before {
  content: "";
}
.ri-train-line:before {
  content: "";
}
.ri-train-wifi-fill:before {
  content: "";
}
.ri-train-wifi-line:before {
  content: "";
}
.ri-translate-2:before {
  content: "";
}
.ri-translate:before {
  content: "";
}
.ri-travesti-fill:before {
  content: "";
}
.ri-travesti-line:before {
  content: "";
}
.ri-treasure-map-fill:before {
  content: "";
}
.ri-treasure-map-line:before {
  content: "";
}
.ri-trello-fill:before {
  content: "";
}
.ri-trello-line:before {
  content: "";
}
.ri-trophy-fill:before {
  content: "";
}
.ri-trophy-line:before {
  content: "";
}
.ri-truck-fill:before {
  content: "";
}
.ri-truck-line:before {
  content: "";
}
.ri-tumblr-fill:before {
  content: "";
}
.ri-tumblr-line:before {
  content: "";
}
.ri-tv-2-fill:before {
  content: "";
}
.ri-tv-2-line:before {
  content: "";
}
.ri-tv-fill:before {
  content: "";
}
.ri-tv-line:before {
  content: "";
}
.ri-twitch-fill:before {
  content: "";
}
.ri-twitch-line:before {
  content: "";
}
.ri-twitter-fill:before {
  content: "";
}
.ri-twitter-line:before {
  content: "";
}
.ri-typhoon-fill:before {
  content: "";
}
.ri-typhoon-line:before {
  content: "";
}
.ri-u-disk-fill:before {
  content: "";
}
.ri-u-disk-line:before {
  content: "";
}
.ri-ubuntu-fill:before {
  content: "";
}
.ri-ubuntu-line:before {
  content: "";
}
.ri-umbrella-fill:before {
  content: "";
}
.ri-umbrella-line:before {
  content: "";
}
.ri-underline:before {
  content: "";
}
.ri-uninstall-fill:before {
  content: "";
}
.ri-uninstall-line:before {
  content: "";
}
.ri-unsplash-fill:before {
  content: "";
}
.ri-unsplash-line:before {
  content: "";
}
.ri-upload-2-fill:before {
  content: "";
}
.ri-upload-2-line:before {
  content: "";
}
.ri-upload-cloud-2-fill:before {
  content: "";
}
.ri-upload-cloud-2-line:before {
  content: "";
}
.ri-upload-cloud-fill:before {
  content: "";
}
.ri-upload-cloud-line:before {
  content: "";
}
.ri-upload-fill:before {
  content: "";
}
.ri-upload-line:before {
  content: "";
}
.ri-usb-fill:before {
  content: "";
}
.ri-usb-line:before {
  content: "";
}
.ri-user-2-fill:before {
  content: "";
}
.ri-user-2-line:before {
  content: "";
}
.ri-user-3-fill:before {
  content: "";
}
.ri-user-3-line:before {
  content: "";
}
.ri-user-4-fill:before {
  content: "";
}
.ri-user-4-line:before {
  content: "";
}
.ri-user-5-fill:before {
  content: "";
}
.ri-user-5-line:before {
  content: "";
}
.ri-user-6-fill:before {
  content: "";
}
.ri-user-6-line:before {
  content: "";
}
.ri-user-add-fill:before {
  content: "";
}
.ri-user-add-line:before {
  content: "";
}
.ri-user-fill:before {
  content: "";
}
.ri-user-follow-fill:before {
  content: "";
}
.ri-user-follow-line:before {
  content: "";
}
.ri-user-heart-fill:before {
  content: "";
}
.ri-user-heart-line:before {
  content: "";
}
.ri-user-line:before {
  content: "";
}
.ri-user-location-fill:before {
  content: "";
}
.ri-user-location-line:before {
  content: "";
}
.ri-user-received-2-fill:before {
  content: "";
}
.ri-user-received-2-line:before {
  content: "";
}
.ri-user-received-fill:before {
  content: "";
}
.ri-user-received-line:before {
  content: "";
}
.ri-user-search-fill:before {
  content: "";
}
.ri-user-search-line:before {
  content: "";
}
.ri-user-settings-fill:before {
  content: "";
}
.ri-user-settings-line:before {
  content: "";
}
.ri-user-shared-2-fill:before {
  content: "";
}
.ri-user-shared-2-line:before {
  content: "";
}
.ri-user-shared-fill:before {
  content: "";
}
.ri-user-shared-line:before {
  content: "";
}
.ri-user-smile-fill:before {
  content: "";
}
.ri-user-smile-line:before {
  content: "";
}
.ri-user-star-fill:before {
  content: "";
}
.ri-user-star-line:before {
  content: "";
}
.ri-user-unfollow-fill:before {
  content: "";
}
.ri-user-unfollow-line:before {
  content: "";
}
.ri-user-voice-fill:before {
  content: "";
}
.ri-user-voice-line:before {
  content: "";
}
.ri-video-add-fill:before {
  content: "";
}
.ri-video-add-line:before {
  content: "";
}
.ri-video-chat-fill:before {
  content: "";
}
.ri-video-chat-line:before {
  content: "";
}
.ri-video-download-fill:before {
  content: "";
}
.ri-video-download-line:before {
  content: "";
}
.ri-video-fill:before {
  content: "";
}
.ri-video-line:before {
  content: "";
}
.ri-video-upload-fill:before {
  content: "";
}
.ri-video-upload-line:before {
  content: "";
}
.ri-vidicon-2-fill:before {
  content: "";
}
.ri-vidicon-2-line:before {
  content: "";
}
.ri-vidicon-fill:before {
  content: "";
}
.ri-vidicon-line:before {
  content: "";
}
.ri-vimeo-fill:before {
  content: "";
}
.ri-vimeo-line:before {
  content: "";
}
.ri-vip-crown-2-fill:before {
  content: "";
}
.ri-vip-crown-2-line:before {
  content: "";
}
.ri-vip-crown-fill:before {
  content: "";
}
.ri-vip-crown-line:before {
  content: "";
}
.ri-vip-diamond-fill:before {
  content: "";
}
.ri-vip-diamond-line:before {
  content: "";
}
.ri-vip-fill:before {
  content: "";
}
.ri-vip-line:before {
  content: "";
}
.ri-virus-fill:before {
  content: "";
}
.ri-virus-line:before {
  content: "";
}
.ri-visa-fill:before {
  content: "";
}
.ri-visa-line:before {
  content: "";
}
.ri-voice-recognition-fill:before {
  content: "";
}
.ri-voice-recognition-line:before {
  content: "";
}
.ri-voiceprint-fill:before {
  content: "";
}
.ri-voiceprint-line:before {
  content: "";
}
.ri-volume-down-fill:before {
  content: "";
}
.ri-volume-down-line:before {
  content: "";
}
.ri-volume-mute-fill:before {
  content: "";
}
.ri-volume-mute-line:before {
  content: "";
}
.ri-volume-off-vibrate-fill:before {
  content: "";
}
.ri-volume-off-vibrate-line:before {
  content: "";
}
.ri-volume-up-fill:before {
  content: "";
}
.ri-volume-up-line:before {
  content: "";
}
.ri-volume-vibrate-fill:before {
  content: "";
}
.ri-volume-vibrate-line:before {
  content: "";
}
.ri-vuejs-fill:before {
  content: "";
}
.ri-vuejs-line:before {
  content: "";
}
.ri-walk-fill:before {
  content: "";
}
.ri-walk-line:before {
  content: "";
}
.ri-wallet-2-fill:before {
  content: "";
}
.ri-wallet-2-line:before {
  content: "";
}
.ri-wallet-3-fill:before {
  content: "";
}
.ri-wallet-3-line:before {
  content: "";
}
.ri-wallet-fill:before {
  content: "";
}
.ri-wallet-line:before {
  content: "";
}
.ri-water-flash-fill:before {
  content: "";
}
.ri-water-flash-line:before {
  content: "";
}
.ri-webcam-fill:before {
  content: "";
}
.ri-webcam-line:before {
  content: "";
}
.ri-wechat-2-fill:before {
  content: "";
}
.ri-wechat-2-line:before {
  content: "";
}
.ri-wechat-fill:before {
  content: "";
}
.ri-wechat-line:before {
  content: "";
}
.ri-wechat-pay-fill:before {
  content: "";
}
.ri-wechat-pay-line:before {
  content: "";
}
.ri-weibo-fill:before {
  content: "";
}
.ri-weibo-line:before {
  content: "";
}
.ri-whatsapp-fill:before {
  content: "";
}
.ri-whatsapp-line:before {
  content: "";
}
.ri-wheelchair-fill:before {
  content: "";
}
.ri-wheelchair-line:before {
  content: "";
}
.ri-wifi-fill:before {
  content: "";
}
.ri-wifi-line:before {
  content: "";
}
.ri-wifi-off-fill:before {
  content: "";
}
.ri-wifi-off-line:before {
  content: "";
}
.ri-window-2-fill:before {
  content: "";
}
.ri-window-2-line:before {
  content: "";
}
.ri-window-fill:before {
  content: "";
}
.ri-window-line:before {
  content: "";
}
.ri-windows-fill:before {
  content: "";
}
.ri-windows-line:before {
  content: "";
}
.ri-windy-fill:before {
  content: "";
}
.ri-windy-line:before {
  content: "";
}
.ri-wireless-charging-fill:before {
  content: "";
}
.ri-wireless-charging-line:before {
  content: "";
}
.ri-women-fill:before {
  content: "";
}
.ri-women-line:before {
  content: "";
}
.ri-wubi-input:before {
  content: "";
}
.ri-xbox-fill:before {
  content: "";
}
.ri-xbox-line:before {
  content: "";
}
.ri-xing-fill:before {
  content: "";
}
.ri-xing-line:before {
  content: "";
}
.ri-youtube-fill:before {
  content: "";
}
.ri-youtube-line:before {
  content: "";
}
.ri-zcool-fill:before {
  content: "";
}
.ri-zcool-line:before {
  content: "";
}
.ri-zhihu-fill:before {
  content: "";
}
.ri-zhihu-line:before {
  content: "";
}
.ri-zoom-in-fill:before {
  content: "";
}
.ri-zoom-in-line:before {
  content: "";
}
.ri-zoom-out-fill:before {
  content: "";
}
.ri-zoom-out-line:before {
  content: "";
}
.ri-zzz-fill:before {
  content: "";
}
.ri-zzz-line:before {
  content: "";
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.app-inner {
  max-width: 184rem;
  margin: 0 auto;
  position: relative;
}
.app-body {
  height: 100%;
  padding-top: 8rem;
}
.app-page {
  height: 100%;
  position: relative;
}
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  background: #0d0940;
  color: #fff;
  padding: 0 4rem;
  z-index: 9999;
  transition: all 0.2s;
}
.app-header .app-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
}
.app-header .header-left {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.app-header .header-util {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.app-header .app-logo {
  margin-top: -2px;
}
.app-header .global-nav {
  height: 100%;
}
.sub-page-wrapper {
  width: 100%;
  max-width: 168rem;
  margin: 5rem auto;
  padding: 0 1.6rem 10rem;
  display: flex;
  gap: 6rem;
}
.sub-page-wrapper .sub-nav {
  width: 20rem;
  flex: 0 0 20rem;
}
.sub-page-wrapper .sub-page {
  width: calc(100% - 26rem);
  min-width: 0;
}
.sub-nav .sub-nav-list {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  overflow: hidden;
}
.sub-nav .sub-nav-list .list-item .nav-btn {
  display: flex;
  width: 100%;
  min-height: 5rem;
  align-items: center;
  padding: 1em 2.4rem;
  background: #f9f9f9;
  font-size: 1.5rem;
  color: #888;
  position: relative;
  line-height: 1.2;
  transition: background 0.2s;
  word-break: keep-all;
}
.sub-nav .sub-nav-list .list-item .nav-btn:hover {
  color: #000;
  font-weight: 500;
  background: #fff;
}
.sub-nav .sub-nav-list .list-item .nav-btn.on {
  color: #000;
  font-weight: 600;
  background: #fff;
}
.sub-nav .sub-nav-list .list-item .nav-btn.on:before {
  content: "";
  display: block;
  width: 3px;
  height: 2rem;
  background: #2c2685;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.sub-nav .sub-nav-list .list-item + .list-item {
  border-top: 1px solid #e9e9e9;
}
.sub-tab-nav .tab-nav-list {
  display: flex;
  gap: 3px;
  margin-bottom: 3rem;
}
.sub-tab-nav .tab-nav-list li .nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 0 1em;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #555;
}
.sub-tab-nav .tab-nav-list li .nav-btn.on {
  background: #2c2685;
  color: #fff;
  font-weight: 700;
  border: none;
}
.sub-tab-nav .tab-nav-list li .nav-btn:not(.on):hover {
  background: #f8f9fa;
}
.sub-page .sub-page-header {
  padding-top: 6px;
  padding-bottom: 2.4rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #eee;
  background: url("../../assets/img/common/sub-title-klbs.svg") no-repeat right
    top 8px;
}
.sub-page .sub-page-header .header-title {
  display: inline-block;
  position: relative;
  font-size: 2rem;
  font-weight: 700;
  color: #000;
}
.sub-page .sub-page-header .header-title:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #2c2685;
  position: absolute;
  left: 0;
  bottom: calc(-2.4rem - 1px);
}
.sub-page .search-bar {
  margin-bottom: 2rem;
}
@media screen and (max-width: 1600px) {
  .sub-page-wrapper {
    gap: 3rem;
  }
}
.app-page.map {
  overflow: hidden;
}
.app-page.dashboard {
  height: 100%;
  overflow-y: overlay;
  background: #f3f3f4;
}
.dashboard-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 178rem;
  padding: 3.4rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-wrapper .dashboard-inner {
  width: 100%;
}
.dashboard-wrapper .swiper-container,
.dashboard-wrapper .swiper-wrapper,
.dashboard-wrapper .swiper-slide {
  width: 100%;
}
.dashboard-wrapper .dashboard-slide {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
}
.dashboard-wrapper .dashboard-slide .dashboard-item {
  opacity: 0;
  transition: all 0.2s;
}
.dashboard-wrapper .dashboard-slide .dashboard-item:nth-child(1) {
  transition-delay: 0.02s;
}
.dashboard-wrapper .dashboard-slide .dashboard-item:nth-child(2) {
  transition-delay: 0.04s;
}
.dashboard-wrapper .dashboard-slide .dashboard-item:nth-child(3) {
  transition-delay: 0.06s;
}
.dashboard-wrapper .dashboard-slide .dashboard-item:nth-child(4) {
  transition-delay: 0.08s;
}
.dashboard-wrapper .dashboard-slide .dashboard-item:nth-child(5) {
  transition-delay: 0.1s;
}
.dashboard-wrapper .dashboard-slide .dashboard-item:nth-child(6) {
  transition-delay: 0.12s;
}
.dashboard-wrapper .dashboard-slide .dashboard-item:nth-child(7) {
  transition-delay: 0.14s;
}
.dashboard-wrapper .dashboard-slide .dashboard-item:nth-child(8) {
  transition-delay: 0.16s;
}
.dashboard-wrapper .dashboard-slide.swiper-slide-active .dashboard-item {
  opacity: 1;
}
.dashboard-wrapper .dashboard-item {
  width: calc(25% - 0.75rem);
  position: relative;
  border-radius: 8px;
}
.dashboard-wrapper .dashboard-item.modal-open {
  cursor: pointer;
}
.dashboard-wrapper .dashboard-item:after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 57.14%;
}
.dashboard-wrapper .dashboard-item .item-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.dashboard-wrapper .dashboard-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.dashboard-wrapper .dashboard-control .dashboard-pager {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 6px;
}
.dashboard-wrapper
  .dashboard-control
  .dashboard-pager
  .swiper-pagination-bullet {
  width: 6.2rem;
  height: 1rem;
  opacity: 1;
  background: #c5c5c5;
  border-radius: 0;
  cursor: pointer;
}
.dashboard-wrapper
  .dashboard-control
  .dashboard-pager
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #2c2685;
}
.dashboard-wrapper .dashboard-control .control-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.dashboard-wrapper .dashboard-control .control-btn {
  min-width: 3.6rem;
  height: 3.6rem;
  border: 1px solid #000;
  border-radius: 3px;
  background: #fff;
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
  white-space: nowrap;
  line-height: 3.6rem;
  padding: 0 1.8rem;
}
.dashboard-wrapper .dashboard-control .control-btn .icon {
  font-size: 1.8rem;
}
.dashboard-wrapper .dashboard-control .control-btn.full {
  width: 3.6rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-wrapper .dashboard-control .control-btn.full .origin {
  display: none;
}
.dashboard-wrapper .dashboard-control .control-btn.full.on {
  background: #2c2685;
  color: #fff;
}
.dashboard-wrapper .dashboard-control .control-btn.full.on .max {
  display: none;
}
.dashboard-wrapper .dashboard-control .control-btn.full.on .origin {
  display: block;
}
.dashboard-wrapper .fixed {
  width: 100%;
  display: flex;
  gap: 1rem;
}
.dashboard-item {
  background: #fff;
}
.dashboard-item .item-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3rem 0 4rem;
}
.dashboard-item .item-num {
  font-size: 8.4rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.dashboard-item .item-num .unit {
  font-size: 2.4rem;
  font-weight: 600;
  color: #888;
  margin-left: 6px;
  letter-spacing: 0;
}
.dashboard-item .item-label {
  font-size: 2.2rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 1.3rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
}
.dashboard-item .item-label .label-more {
  display: flex;
  align-items: center;
  flex: none;
  margin-left: auto;
  font-size: 2rem;
  font-weight: 700;
  color: #000;
}
.dashboard-item .item-label .label-more span {
  color: #888;
  font-weight: 600;
  margin-right: 6px;
}
.dashboard-item .item-label .label-more .icon {
  font-size: 2.4rem;
  font-weight: 400;
  margin-left: 10px;
}
.dashboard-item .item-label .label-more .icon[class*="-up"] {
  color: #fe5d2a;
}
.dashboard-item .item-label .label-more .icon[class*="-down"] {
  color: #1b61ec;
}
.dashboard-item .item-chart {
  display: flex;
  color: #000;
  font-weight: 700;
  font-size: 1.8rem;
  margin-top: 1.2rem;
  gap: 20px;
}
.dashboard-item .item-chart [class*="chart-m"] {
  display: flex;
  font-weight: 800;
  gap: 6px;
}
.dashboard-item .item-chart .chart-min .chart-label {
  color: #1b61ec;
}
.dashboard-item .item-chart .chart-max .chart-label {
  color: #fe5d2a;
}
.dashboard-item.total {
  opacity: 1;
  background: #41b787;
  background: linear-gradient(
    45deg,
    rgb(65, 183, 135) 0%,
    rgb(121, 208, 173) 100%
  );
  color: #fff;
}
.dashboard-item.total .item-inner {
  background: url("../../assets/img/dashboard/card-bg.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.total .item-label {
  color: #fff;
}
.dashboard-item.start {
  opacity: 1;
  background: #1b61ec;
  background: linear-gradient(
    45deg,
    rgb(27, 97, 236) 0%,
    rgb(55, 160, 241) 100%
  );
  color: #fff;
}
.dashboard-item.start .item-inner {
  background: url("../../assets/img/dashboard/card-bg.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.start .item-label {
  color: #fff;
}
.dashboard-item.pause {
  opacity: 1;
  background: #fe5d2a;
  background: linear-gradient(
    45deg,
    rgb(254, 93, 42) 0%,
    rgb(254, 155, 42) 100%
  );
  color: #fff;
}
.dashboard-item.pause .item-inner {
  background: url("../../assets/img/dashboard/card-bg.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.pause .item-label {
  color: #fff;
}
.dashboard-item.end {
  opacity: 1;
  background: #050506;
  background: linear-gradient(45deg, rgb(5, 5, 6) 0%, rgb(40, 37, 93) 100%);
  color: #fff;
}
.dashboard-item.end .item-inner {
  background: url("../../assets/img/dashboard/card-bg.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.end .item-label {
  color: #fff;
}
.dashboard-item.chip-battery .item-inner {
  background: url("../../assets/img/dashboard/icon-chip.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.car-battery .item-inner {
  background: url("../../assets/img/dashboard/icon-car.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.temp .item-inner {
  background: url("../../assets/img/dashboard/icon-temp.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.hum .item-inner {
  background: url("../../assets/img/dashboard/icon-hum.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.engine .item-inner {
  background: url("../../assets/img/dashboard/icon-engine.svg") no-repeat right
    bottom/auto 100%;
}
.dashboard-item.speed .item-inner {
  background: url("../../assets/img/dashboard/icon-speed.svg") no-repeat right
    bottom/auto 100%;
}
.full-mode .app-page.dashboard {
  padding-top: 5rem;
}
.custom-check-group {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.custom-check-group + .custom-check-group {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px dashed #ddd;
}
.custom-check-group .custom-check-btn {
  width: calc(25% - 3px);
}
.custom-check-group .custom-check-btn .label {
  width: 100%;
}
.custom-radio-group.marker-icon {
  gap: 6px;
}
.custom-radio-group.marker-icon .custom-radio .label {
  width: 6.4rem;
  height: 6.4rem;
  min-width: 0;
  padding: 0;
}
.custom-radio-group.marker-icon .custom-radio .label-name {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: #888;
  margin-top: 1rem;
}
.custom-radio-group.marker-icon .custom-radio img {
  filter: invert(100%);
  opacity: 0.4;
}
.custom-radio-group.marker-icon .custom-radio input:checked ~ .label img {
  filter: invert(88%) sepia(73%) saturate(3880%) hue-rotate(242deg)
    brightness(92%) contrast(93%);
  opacity: 1;
}
.custom-radio-group.marker-icon .custom-radio input:checked ~ .label-name {
  font-weight: 700;
  color: #2c2685;
}
.custom-radio-group.theme-icon .custom-radio .label {
  min-width: 0;
  padding: 1rem;
  height: auto;
}
.custom-radio-group.theme-icon .custom-radio .label-name {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: #888;
  margin-top: 1rem;
}
.custom-radio-group.theme-icon .custom-radio input:checked ~ .label-name {
  font-weight: 700;
  color: #2c2685;
}
.common-modal .geo-wrapper {
  display: flex;
  max-width: 146rem;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  align-items: stretch;
}
.common-modal .geo-wrapper .left {
  flex: 1;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
}
.common-modal .geo-wrapper .left #map {
  width: 100%;
  height: 100%;
  flex: 1;
}
.common-modal .geo-wrapper .right {
  flex: 0 0 48rem;
}
.common-modal .geo-wrapper .addr-search-bar {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 6.5rem;
  padding: 1.5rem 1.6rem 1.5rem 2.4rem;
  background: #fff;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}
.common-modal .geo-wrapper .addr-search-bar .pin-icon {
  font-size: 2.4rem;
  color: #2c2685;
}
.common-modal .geo-wrapper .addr-search-bar .custom-input {
  border: none;
  flex: 1;
  position: relative;
}
.common-modal .geo-wrapper .addr-search-bar .custom-input input {
  font-size: 1.6rem;
  cursor: unset;
  padding: 0;
}
.common-modal .geo-wrapper .addr-search-bar .custom-input input:read-only {
  background: none;
}
.common-modal .geo-wrapper .addr-search-bar .custom-input .del-btn {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 2.4rem;
  opacity: 0.17;
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.common-modal .geo-wrapper .addr-search-bar .custom-input .del-btn.on {
  display: block;
}
.common-modal .geo-wrapper .common-form-table .row .row-label {
  width: 40%;
}
.common-modal .geo-wrapper .modal-page {
  height: 100%;
}
.common-modal .geo-wrapper .geo-info .row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.common-modal .geo-wrapper .geo-info .row .row-content {
  height: auto;
}
.common-modal .geo-wrapper .geo-info .info-list {
  display: table;
}
.common-modal .geo-wrapper .geo-info .info-list li {
  display: table-row;
  font-size: 1.5rem;
}
.common-modal .geo-wrapper .geo-info .info-list li .item-label {
  display: table-cell;
  white-space: nowrap;
  padding: 5px 2em 5px 0;
  font-weight: 600;
  color: #2c2685;
}
.common-modal .geo-wrapper .geo-info .info-list li .item-content {
  display: table-cell;
  padding: 5px 0;
  line-height: 1.2;
}
.common-modal .geo-wrapper .geo-info .info-list li .item-content .y {
  padding-right: 0.6em;
}
.sms-user-info {
  text-align: left;
  font-weight: 600;
  color: #2c2685;
  padding-top: 1em;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.sms-user-info .icon {
  font-size: 2.4rem;
  margin-top: -2px;
  font-weight: 400;
}
.sms-control {
  display: flex;
  gap: 4px;
  background: #f8f9fa;
  border: 1px solid #eee;
  padding: 1rem;
  margin: 2rem 0;
}
.sms-control [class*="custom-"] {
  height: 4rem;
  border-color: #ddd;
}
.sms-control .count {
  width: 200px;
}
.sms-control .memo {
  flex: 1;
}
.key-code {
  margin-right: 1em;
}
.intro-wrapper {
  display: flex;
  align-items: stretch;
  min-height: 100%;
}
.intro-wrapper .intro-slide-wrapper {
  flex: 1;
  background: #eee;
  overflow: hidden;
  position: relative;
}
.intro-wrapper .intro-slide-wrapper .swiper-container,
.intro-wrapper .intro-slide-wrapper .swiper-wrapper,
.intro-wrapper .intro-slide-wrapper .swiper-slide {
  height: 100%;
}
.intro-wrapper .intro-slide-wrapper .slide-item {
  position: relative;
  height: 100%;
  padding: 14.8rem 8rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  letter-spacing: -0.05em;
}
.intro-wrapper .intro-slide-wrapper .slide-item .item-content {
  position: relative;
  z-index: 1;
  max-width: 80rem;
}
.intro-wrapper .intro-slide-wrapper .slide-item .item-logo {
  margin-bottom: 4rem;
}
.intro-wrapper .intro-slide-wrapper .slide-item .item-logo img {
  min-height: 3.5rem;
  width: auto;
}
.intro-wrapper .intro-slide-wrapper .slide-item .item-title {
  font-size: 3.8rem;
  font-weight: 600;
  margin-bottom: 0.3em;
  line-height: 1.2;
}
.intro-wrapper .intro-slide-wrapper .slide-item .item-sub {
  font-size: 2.2rem;
  font-weight: 600;
  padding-bottom: 1.2em;
  margin-bottom: 1.2em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  line-height: 1.2;
}
.intro-wrapper .intro-slide-wrapper .slide-item .item-desc {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 1.4;
  word-break: keep-all;
}
.intro-wrapper .intro-slide-wrapper .slide-item .item-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.intro-wrapper .intro-slide-wrapper .slide-item .item-bg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.intro-wrapper .intro-slide-wrapper .swiper-pager {
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  background: rgba(0, 0, 0, 0.53);
  display: flex;
}
.intro-wrapper .intro-slide-wrapper .swiper-pager .item-pager {
  width: 100%;
  border-radius: 0;
  flex: 1;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
  font-weight: 400;
  opacity: 1;
}
.intro-wrapper .intro-slide-wrapper .swiper-pager .item-pager + .item-pager {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.intro-wrapper .intro-slide-wrapper .swiper-pager .item-pager .pager-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.intro-wrapper
  .intro-slide-wrapper
  .swiper-pager
  .item-pager:not([class*="-active"])
  .pager-btn {
  color: #fff !important;
  border-color: rgba(0, 0, 0, 0) !important;
}
.intro-wrapper .intro-slide-wrapper .swiper-pager .swiper-pagination-bullet,
.intro-wrapper
  .intro-slide-wrapper
  .swiper-pager
  .swiper-pagination-bullet-active {
  background: none;
}
.intro-wrapper .intro-slide-wrapper .swiper-control {
  position: absolute;
  z-index: 99;
  right: 8rem;
  bottom: 14.8rem;
  display: flex;
  gap: 4px;
}
.intro-wrapper .intro-slide-wrapper .swiper-control .btn {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.intro-wrapper .intro-slide-wrapper .swiper-control .auto {
  position: relative;
}
.intro-wrapper .intro-slide-wrapper .swiper-control .auto:after {
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.intro-wrapper .intro-slide-wrapper .swiper-control .auto.play:after {
  width: 0;
  height: 0;
  border-left: 8px solid #fff;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-right: none;
  left: calc(50% + 1px);
}
.intro-wrapper .intro-slide-wrapper .swiper-control .auto svg {
  width: 100%;
  height: 100%;
}
.intro-wrapper .intro-slide-wrapper .swiper-control .auto svg .bar {
  fill: rgba(0, 0, 0, 0);
  stroke-width: 2;
  stroke: #fff;
  transform: rotate(-90deg);
}
.intro-wrapper .intro-slide-wrapper .swiper-control .auto svg .bg {
  fill: rgba(0, 0, 0, 0);
  stroke-width: 2;
  stroke: rgba(0, 0, 0, 0.3);
}
.intro-wrapper .intro-slide-wrapper .swiper-control .auto svg circle {
  transform-origin: 50% 50%;
}
.intro-wrapper .intro-slide-wrapper .swiper-control .counter {
  width: 6.8rem;
  height: 3.6rem;
  border-radius: 9999px;
  background: rgba(255, 255, 255, 0.15);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  color: #fff;
}
.intro-wrapper .intro-slide-wrapper.slide-black-opacity .slide-item {
  color: #000;
}
.intro-wrapper .intro-slide-wrapper.slide-black-opacity .swiper-control .btn {
  background: rgba(0, 0, 0, 0.3);
  color: #000;
}
.intro-wrapper
  .intro-slide-wrapper.slide-black-opacity
  .swiper-control
  .auto:after {
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}
.intro-wrapper
  .intro-slide-wrapper.slide-black-opacity
  .swiper-control
  .auto.play:after {
  border-left: 8px solid #000;
}
.intro-wrapper
  .intro-slide-wrapper.slide-black-opacity
  .swiper-control
  .auto
  svg
  .bar {
  stroke: #000;
}
.intro-wrapper
  .intro-slide-wrapper.slide-black-opacity
  .swiper-control
  .auto
  svg
  .bg {
  stroke: rgba(0, 0, 0, 0.3);
}
.intro-wrapper
  .intro-slide-wrapper.slide-black-opacity
  .swiper-control
  .counter {
  background: rgba(0, 0, 0, 0.3);
  color: #000;
}
.intro-wrapper .intro-slide-wrapper.slide-black .slide-item {
  color: #fff;
}
.intro-wrapper .intro-slide-wrapper.slide-black .swiper-control .btn {
  background: #000;
  color: #fff;
}
.intro-wrapper .intro-slide-wrapper.slide-black .swiper-control .auto:after {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}
.intro-wrapper
  .intro-slide-wrapper.slide-black
  .swiper-control
  .auto.play:after {
  border-left: 8px solid #fff;
}
.intro-wrapper .intro-slide-wrapper.slide-black .swiper-control .auto svg .bar {
  stroke: #fff;
}
.intro-wrapper .intro-slide-wrapper.slide-black .swiper-control .auto svg .bg {
  stroke: rgba(255, 255, 255, 0.3);
}
.intro-wrapper .intro-slide-wrapper.slide-black .swiper-control .counter {
  background: #000;
  color: #fff;
}
.intro-wrapper .intro-panel-wrapper {
  width: 50rem;
  flex: 0 0 50rem;
  padding: 5rem 4rem;
  box-shadow: -10px 0 60px rgba(0, 0, 0, 0.33);
}
.intro-wrapper .intro-panel-wrapper .panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ddd;
}
.intro-wrapper .intro-panel-wrapper .panel-header .logo img {
  max-height: 2.2rem;
  width: auto;
}
.intro-wrapper .intro-panel-wrapper .panel-header .slogan {
  color: #aeaeae;
  font-size: 1.4rem;
}
.intro-wrapper .intro-panel-wrapper .panel-header .slogan .emp {
  font-weight: 600;
  color: #2c2685;
}
.intro-wrapper .intro-module + .intro-module {
  margin-top: 5rem;
}
.intro-wrapper .intro-module .module-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.2rem;
}
.intro-wrapper .intro-module .module-header .header-title {
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 9px;
}
.intro-wrapper .intro-module .module-header .header-title .icon {
  font-weight: 400;
  font-size: 2rem;
  opacity: 0.18;
}
.intro-wrapper .intro-module .module-header .header-control a {
  font-size: 1.5rem;
  color: #959595;
}
.intro-wrapper .intro-module .module-body [class*="-item"] {
  background: #f8f9fa;
  border-radius: 4px;
  height: 5.4rem;
  padding: 0 1.8rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  transition: background 0.2s;
}
.intro-wrapper .intro-module .module-body [class*="-item"] label {
  font-weight: 500;
  color: #000;
  width: 6em;
}
.intro-wrapper .intro-module .module-body [class*="-item"] input[type="text"],
.intro-wrapper
  .intro-module
  .module-body
  [class*="-item"]
  input[type="password"] {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  font-size: 1.5rem;
}
.intro-wrapper
  .intro-module
  .module-body
  [class*="-item"]
  input[type="text"]:focus,
.intro-wrapper
  .intro-module
  .module-body
  [class*="-item"]
  input[type="password"]:focus {
  border: none;
  outline: none;
}
.intro-wrapper .intro-module .module-body [class*="-item"] + [class*="-item"] {
  margin-top: 4px;
}
.intro-wrapper .intro-module .module-body [class*="-item"]:has(input:focus) {
  background: #e9e7ff;
}
.intro-wrapper .intro-module .module-footer .submit-btn {
  width: 100%;
  height: 5.4rem;
  background: #2c2685;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
}
.intro-wrapper .intro-module .module-footer .submit-btn:hover {
  background: #413aab;
}
.intro-wrapper .login-module .etc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 2.4rem;
}
.intro-wrapper .login-module .etc .custom-checkbox .checkbox {
  background: #fff;
  border: 1px solid #ddd;
}
.intro-wrapper .login-module .etc .custom-checkbox .label {
  font-size: 1.5rem;
  font-weight: 600;
}
.intro-wrapper .login-module .etc .custom-checkbox input:checked ~ .checkbox {
  background: #e9e7ff;
  border-color: #2c2685;
}
.intro-wrapper
  .login-module
  .etc
  .custom-checkbox
  input:checked
  ~ .checkbox::after {
  border-color: #2c2685;
}
.intro-wrapper .login-module .etc .custom-checkbox input:checked ~ .label {
  color: #2c2685;
}
.intro-wrapper .login-module .etc .link {
  font-size: 1.5rem;
  color: #959595;
}
.intro-wrapper .recent-module .recent-list a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 20px;
}
.intro-wrapper .recent-module .recent-list a .item-title {
  min-width: 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2rem;
}
.intro-wrapper .recent-module .recent-list a .item-title.new:before {
  content: "N";
  display: block;
  width: 2rem;
  height: 2rem;
  background: #ff9233;
  border-radius: 2px;
  color: #fff;
  font-weight: 800;
  font-size: 1.2rem;
  float: left;
  margin-right: 9px;
  text-align: center;
}
.intro-wrapper .recent-module .recent-list a .item-date {
  flex: none;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #888;
}
.intro-wrapper .recent-module .recent-list a .item-date .icon {
  color: #ccc;
}
.recent-view {
  padding: 3rem 0;
  text-align: left;
}
.recent-view .view-title {
  font-size: 2rem;
  font-weight: 700;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #eee;
  line-height: 1.2;
}
.recent-view .view-meta {
  display: flex;
}
.recent-view .view-meta .meta-item {
  display: flex;
  margin-bottom: 3em;
  position: relative;
  gap: 10px;
  font-size: 1.5rem;
}
.recent-view .view-meta .meta-item + .meta-item {
  margin-left: 16px;
  padding-left: 16px;
}
.recent-view .view-meta .meta-item + .meta-item:before {
  content: "";
  display: blolck;
  width: 1px;
  height: 12px;
  background: #ddd;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.recent-view .view-meta .meta-item .item-label {
  font-weight: 600;
  color: #2c2685;
}
.recent-view .view-meta .meta-item .item-content {
  font-weight: 500;
  color: #555;
}
.recent-view .view-content {
  background: #f8f9fa;
  padding: 2em;
  border-radius: 10px;
}
.privacy-wrapper {
  background: #f8f9fa;
}
.privacy-wrapper .privacy-inner {
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  max-width: 96rem;
  margin: 0 auto;
  padding: 6rem 4rem 10rem;
}
.privacy-wrapper .logo-title {
  margin-bottom: 4rem;
  padding-bottom: 4rem;
  text-align: right;
}
.privacy-wrapper .pv-title {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 2em;
  border-bottom: 1px solid #eee;
}
.privacy-wrapper .pv-title span {
  position: relative;
  display: inline-block;
  padding-bottom: 1em;
  height: 100%;
}
.privacy-wrapper .pv-title span:after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background: #2c2685;
  position: absolute;
  left: 0;
  bottom: 0;
}
.privacy-wrapper .pv-sec {
  margin-bottom: 6rem;
}
.privacy-wrapper .pv-sec * {
  line-height: 1.3;
}
.privacy-wrapper .pv-sec .sec-title {
  font-size: 1.8rem;
  margin-bottom: 1.2em;
  font-weight: 700;
  color: #000;
}
.privacy-wrapper .pv-sec .sec-p + .sec-p {
  margin-top: 1em;
}
.privacy-wrapper .pv-sec ul li + li {
  margin-top: 0.6em;
}
.privacy-wrapper .pv-sec ul li ul {
  margin: 0.6em 0 1em;
}
.custom-select {
  position: relative;
  border: 1px solid #c9c9c9;
  height: 3.4rem;
  min-width: 10rem;
  background: #fff;
  border-radius: 3px;
}
.custom-select:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 6px solid #000;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  pointer-events: none;
}
.custom-select select {
  width: 100%;
  height: 100%;
  padding: 0 1.4rem 0 1.2rem;
  border: none;
  appearance: none;
  border-radius: 3px;
  font-size: 1.4rem;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}
.custom-select select:focus {
  outline: none;
}
.custom-input {
  border: 1px solid #c9c9c9;
  height: 3.4rem;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  position: relative;
}
.custom-input:has(.search-result-panel) {
  overflow: visible;
}
.custom-input.full {
  flex: 1;
  width: 100%;
}
.custom-input.wide {
  flex: 0 0 400px;
  width: 400px;
}
.custom-input .label {
  border-right: 1px solid #ddd;
  white-space: nowrap;
  color: #6b6b6b;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 0 4rem 0 1.6rem;
  min-width: 10rem;
  display: flex;
  align-items: center;
}
.custom-input input {
  background: none;
  border: none;
  appearance: none;
  width: 100%;
  height: 100%;
  padding: 0 1.2rem;
  font-size: 1.6rem;
  color: #000;
  font-weight: 500;
}
.custom-input input:disabled,
.custom-input input:read-only {
  background: #f6f6f6;
  outline: none;
}
.custom-color {
  width: 7.6rem;
  border-radius: 3px;
  border: 1px solid #ddd;
}
.custom-color input {
  width: 100%;
  height: 4rem;
  padding: 4px;
  border: none;
  cursor: pointer;
  background: none;
}
.custom-color input::-webkit-color-swatch-wrapper {
  padding: 0;
}
.custom-checkbox {
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
}
.custom-checkbox input {
  display: none;
}
.custom-checkbox .checkbox {
  width: 2rem;
  height: 2rem;
  background: #676579;
  border-radius: 2px;
}
.custom-checkbox input:checked ~ .checkbox {
  background: #413aab;
  position: relative;
}
.custom-checkbox input:checked ~ .checkbox:after {
  content: "";
  display: block;
  width: 1rem;
  height: 0.7rem;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.custom-checkbox .label {
  padding-top: 2px;
}
.custom-file {
  display: flex;
  gap: 3px;
}
.custom-file input[type="file"] {
  display: none;
}
.custom-file label {
  cursor: pointer;
}
.custom-file .custom-input {
  flex: 0 0 400px;
}
.custom-file.short .custom-input {
  flex: 0 0 240px;
}
.custom-file .del-btn {
  display: none;
}
.custom-file .del-btn.on {
  display: flex;
}
.filter-module {
  position: relative;
}
.filter-module [class*="-btn"] {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.5rem;
  color: #8d8d8d;
  letter-spacing: -0.05em;
}
.filter-module [class*="-btn"] .icon {
  font-size: 1.4rem;
}
.filter-module [class*="-btn"] .icon.red {
  color: #1546a8;
}
.filter-module [class*="-btn"] .icon.blue {
  color: #fe5d2a;
}
.filter-module .filter-option {
  position: absolute;
  top: -8px;
  right: -6px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  white-space: nowrap;
  display: none;
  flex-direction: column;
  overflow: hidden;
}
.filter-module .filter-option.on {
  display: flex;
}
.filter-module .filter-option [class*="-btn"] {
  gap: 6px;
  padding: 11px 14px 11px 12px;
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
}
.filter-module .filter-option [class*="-btn"] + [class*="-btn"] {
  border-top: 1px solid #ddd;
}
.filter-module .filter-option [class*="-btn"].on {
  background: #f5f5f5;
}
.filter-module .filter-option [class*="-btn"]:hover {
  background: #f5f5f5;
}
.common-btn {
  min-width: 10rem;
  height: 4rem;
  border-radius: 3px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0 13px;
  border: none;
}
.common-btn .icon {
  color: #000;
  font-size: 1.9rem;
  margin-top: -1px;
}
.common-btn span {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
}
.common-btn.sm {
  min-width: 7rem;
  height: 4rem;
}
.common-btn.xs {
  min-width: 0;
  height: 2.8rem;
  font-size: 1.4rem;
}
.common-btn.purple {
  background: #2c2685;
}
.common-btn.purple .icon {
  color: #fff;
}
.common-btn.purple span {
  color: #fff;
}
.common-btn.white {
  background: #fff;
  border: 1px solid #ddd;
}
.common-btn.white .icon {
  color: #000;
}
.common-btn.white span {
  color: #000;
}
.common-btn.excel .icon {
  color: #087233;
}
.custom-datepicker {
  position: relative;
  border: 1px solid #ddd;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.02);
}
.custom-datepicker:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 6px solid #000;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  pointer-events: none;
}
.custom-datepicker input {
  width: 100%;
  height: 100%;
  padding: 0 1.4rem 0 1.2rem;
  border: none;
  appearance: none;
  border-radius: 3px;
  font-size: 1.4rem;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}
.custom-datepicker input:focus {
  outline: none;
}
.custom-datepicker .datepicker-dropdown {
  min-width: 320px;
}
.period-group {
  display: flex;
  gap: 6px;
  align-items: center;
}
.period-group [class*="custom-"] {
  width: 160px;
  flex: 0 0 160px;
}
.period-group + .period-group {
  margin-left: 10px;
}
.custom-radio-group,
.custom-check-group {
  display: flex;
  gap: 4px;
}
.custom-radio-group.col-3,
.custom-check-group.col-3 {
  flex-wrap: wrap;
}
.custom-radio-group.col-3 .custom-radio,
.custom-radio-group.col-3 .custom-check,
.custom-check-group.col-3 .custom-radio,
.custom-check-group.col-3 .custom-check {
  width: calc(33.333% - 2.667px);
}
.custom-radio input,
.custom-check input {
  display: none;
}
.custom-radio .label,
.custom-check .label {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 7.6rem;
  padding: 0 1em;
  height: 4rem;
  border-radius: 3px;
  border: 1px solid #ddd;
  background: #fff;
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}
.custom-radio input:checked ~ .label,
.custom-check input:checked ~ .label {
  background: #d9d6ff;
  border-color: #2c2685;
  color: #2c2685;
  box-shadow: 0 5px 12px rgba(44, 38, 133, 0.12);
}
.custom-check-btn input {
  display: none;
}
.custom-check-btn .label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.6rem;
  height: 4rem;
  border-radius: 3px;
  border: 1px solid #ddd;
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
}
.custom-check-btn input:disabled ~ .label {
  background: #f6f6f6;
  border: 1px solid #ddd;
  box-shadow: 0 5px 12px rgba(44, 38, 133, 0.12);
}
.custom-check-btn input:checked ~ .label {
  background: #d9d6ff;
  border-color: #2c2685;
  color: #2c2685;
}
.custom-textarea {
  width: 100%;
  height: 20rem;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.custom-textarea textarea {
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  resize: none;
  overflow-y: auto;
  font-family: inherit;
  font-size: 1.5rem;
  color: #333;
  line-height: 1.2;
  padding: 1em;
}
.address {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.address .row {
  display: flex;
  gap: 4px;
}
.address .row .custom-input.full {
  width: 100%;
}
[class*="custom-"].short {
  width: 200px;
}
[class*="custom-"].mini {
  width: 100px;
}
[class*="custom-"] + .unit {
  margin-right: 1em;
}
.datepicker-picker {
  width: 100%;
  position: relative;
}
.datepicker-picker .datepicker-header {
  padding-top: 10px;
}
.datepicker-picker .datepicker-header .datepicker-controls {
  position: relative;
  justify-content: flex-end;
  padding: 0 10px;
}
.datepicker-picker .datepicker-header .datepicker-controls .view-switch {
  order: -1;
  font-size: 2rem;
  width: auto;
  padding: 0 24px 0 10px;
  flex: none;
  margin-right: auto;
  color: #000;
}
.datepicker-picker .datepicker-header .datepicker-controls .view-switch:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 6px solid #000;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.datepicker-picker .datepicker-header .datepicker-controls .next-btn,
.datepicker-picker .datepicker-header .datepicker-controls .prev-btn {
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #ddd;
  border-radius: 2px;
  background: #fff;
  text-indent: -9999px;
  margin-left: 2px;
  margin-top: 11px;
}
.datepicker-picker .datepicker-header .datepicker-controls .prev-btn:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-right: 4px solid #000;
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  transform: translate(-50%, -50%);
}
.datepicker-picker .datepicker-header .datepicker-controls .next-btn:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 4px solid #000;
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  transform: translate(-50%, -50%);
}
.datepicker-picker .datepicker-footer .datepicker-controls .today-btn {
  position: absolute;
  top: 18px;
  right: 58px;
  width: 6.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
}
.datepicker-picker .datepicker-main {
  padding: 10px;
}
.datepicker-picker .days-of-week {
  background: #f6f6f6;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 0 6px;
}
.datepicker-picker .days-of-week .dow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.datepicker-picker .datepicker-view {
  width: 100%;
}
.datepicker-picker .datepicker-view .days {
  width: 100%;
}
.datepicker-picker .datepicker-view .days .datepicker-grid {
  width: 100%;
  padding: 0 6px;
}
.datepicker-picker .datepicker-view .days .datepicker-cell {
  width: 14.2857142857%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  position: relative;
  z-index: 1;
}
.datepicker-picker .datepicker-view .days .datepicker-cell:nth-child(7n + 1) {
  color: #dc6409;
}
.datepicker-picker .datepicker-view .days .datepicker-cell:nth-child(7n + 7) {
  color: #1546a8;
}
.datepicker-picker .datepicker-view .days .datepicker-cell.prev,
.datepicker-picker .datepicker-view .days .datepicker-cell.next {
  color: #aaa;
}
.datepicker-picker .datepicker-view .days .datepicker-cell.hasData {
  background: none;
  color: #fff;
}
.datepicker-picker .datepicker-view .days .datepicker-cell.hasData:before {
  content: "";
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  background: #554aff;
  position: absolute;
  left: 50%;
  top: calc(50% - 1px);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1;
  box-shadow: 0 3px 6px rgba(85, 74, 255, 0.23);
}
.datepicker-picker .datepicker-view .days .datepicker-cell.selected {
  background: none;
  color: #fff;
}
.datepicker-picker .datepicker-view .days .datepicker-cell.selected:before {
  content: "";
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  background: #000;
  position: absolute;
  left: 50%;
  top: calc(50% - 1px);
  transform: translate(-50%, -50%);
  border-radius: 4px;
  z-index: -1;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
#modal-loading {
  position: fixed;
  z-index: 10000;
  display: none;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
#modal-loading .loading-spinner {
  position: fixed;
  z-index: 10001;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  display: block;
  width: 80px;
  height: 80px;
  border: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  border-top-color: #413aab;
  animation: spin 0.8s ease-in-out infinite;
}
.loading #modal-loading {
  display: block;
}
.common-modal {
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: overlay;
}
.common-modal::-webkit-scrollbar {
  width: 4px;
}
.common-modal::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 0.49);
}
.common-modal.on {
  opacity: 1;
  visibility: visible;
}
.common-modal.on .modal-inner {
  transform: translateY(0);
}
.common-modal .modal-inner {
  width: 100%;
  min-height: 100%;
  display: flex;
  text-align: center;
  padding: 5rem 2rem;
  transform: translateY(10px);
  transition: all 0.2s;
}
.common-modal .modal-inner:has(.short) {
  padding: 15rem 2rem;
}
.common-modal .modal-page {
  background: #fff;
  width: 100%;
  max-width: 102rem;
  margin: 0 auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.common-modal .modal-page .page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.5rem;
  border-bottom: 1px solid #eee;
  padding: 0.2rem 3.2rem 0;
}
.common-modal .modal-page .page-header .page-title {
  height: 100%;
}
.common-modal .modal-page .page-header .page-title span {
  display: inline-flex;
  align-items: center;
  height: 100%;
  position: relative;
  font-size: 1.7rem;
  font-weight: 700;
}
.common-modal .modal-page .page-header .page-title span::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #413aab;
  position: absolute;
  bottom: -1px;
  left: 0;
}
.common-modal .modal-page .page-header .modal-close {
  border: none;
  background: none;
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
}
.common-modal .modal-page .page-header .modal-close .icon {
  font-size: 2.4rem;
  color: #000;
  pointer-events: none;
  position: relative;
  z-index: 1;
}
.common-modal .modal-page .page-header .modal-close:after {
  content: "";
  display: block;
  width: 150%;
  height: 150%;
  background: #eee;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s;
}
.common-modal .modal-page .page-header .modal-close:hover:after {
  transform: translate(-50%, -50%) scale(1);
}
.common-modal .modal-page .page-body {
  padding: 1.8rem 3.2rem 3rem;
}
.common-modal .modal-page .page-footer {
  margin-top: auto;
  flex: none;
  border-top: 1px solid #eee;
  padding: 1.6rem 3.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
.modal-page.sm {
  max-width: 48rem;
}
.modal-page.md {
  max-width: 80rem;
}
.modal-page.lg {
  max-width: 14.6rem;
}
.common-table {
  width: 100%;
  table-layout: fixed;
  box-shadow: 0 0 0 1px #d7dbde;
  border-radius: 4px;
  border-style: hidden;
}
.common-table .custom-checkbox .checkbox {
  width: 1.6rem;
  height: 1.6rem;
  background: #fff;
  border: 1px solid #ddd;
}
.common-table .custom-checkbox input:checked ~ .checkbox {
  background: #fff;
}
.common-table .custom-checkbox input:checked ~ .checkbox:after {
  border-color: #000;
  width: 0.8rem;
  height: 0.6rem;
}
.common-table thead th {
  background: #f5f5f5;
  height: 4.9rem;
  font-weight: 500;
  color: #464646;
  font-size: 1.5rem;
  border: 1px solid #d7dbde;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.03);
}
.common-table thead th:first-child {
  border-radius: 4px 0 0 0;
}
.common-table thead th:last-child {
  border-radius: 0 4px 0 0;
}
.common-table thead th .custom-checkbox {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
}
.common-table tbody th.center {
  text-align: center;
}
.common-table tbody th.center .common-btn {
  margin: 0 auto;
}
.common-table tbody td {
  border: 1px solid #eee;
  height: 4.9rem;
  text-align: center;
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 10px;
  line-height: 1.14;
}
.common-table tbody td.center {
  text-align: center;
}
.common-table tbody td.center .common-btn {
  margin: 0 auto;
}
.common-table tbody td.left {
  text-align: left;
}
.common-table .empty {
  padding: 2em 1em;
  text-align: center;
}
.common-table .bold {
  font-weight: 700;
}
.common-table .link {
  text-decoration: underline;
}
.common-table.clickable-row tbody tr:hover {
  cursor: pointer;
}
.common-table.clickable-row tbody tr:hover td {
  background: #f8f9fa;
}
.common-pager {
  margin-top: 2rem;
}
.common-pager ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
}
.common-pager ul li {
  width: 4rem;
  height: 4rem;
  flex: 0 0 4rem;
}
.common-pager ul li > * {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 4rem;
  height: 4rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: #000;
  border: 1px solid #ddd;
  background-color: #fff;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  transition: background 0.2s;
}
.common-pager ul li > *.on,
.common-pager ul li > *:not(.omit):hover {
  border-color: #2c2685;
  background: rgba(85, 74, 255, 0.22);
  color: #2c2685;
}
.common-pager ul li .omit {
  border: none;
  background-image: url("../../assets/img/common/icon-pager-omit.svg");
}
.common-pager ul li .prev {
  background-image: url("../../assets/img/common/icon-pager-prev.svg");
}
.common-pager ul li .next {
  background-image: url("../../assets/img/common/icon-pager-next.svg");
}
.common-pager ul li .first {
  background-image: url("../../assets/img/common/icon-pager-first.svg");
}
.common-pager ul li .last {
  background-image: url("../../assets/img/common/icon-pager-last.svg");
}
.add-control {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.add-control .common-btn {
  border-radius: 9999px;
}
.common-control {
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
}
.common-control .left {
  flex: none;
  margin-right: auto;
  display: flex;
  gap: 3px;
  align-items: center;
}
.common-control .right {
  flex: none;
  margin-left: auto;
  display: flex;
  gap: 3px;
  align-items: center;
}
.common-control .period {
  display: flex;
  gap: 4px;
  align-items: center;
  letter-spacing: -0.06em;
  font-weight: 700;
  color: #2c2685;
  margin-right: 12px;
}
.common-control .period + .info {
  padding-left: 12px;
}
.common-control .period + .info:before {
  content: "";
  display: block;
  width: 1px;
  height: 12px;
  background: #ddd;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.common-control .info {
  margin-right: 6px;
  color: #888;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.common-control .info .label {
  font-weight: 700;
  color: #000;
}
.common-control .info .label.emp {
  color: #1b61ec;
}
.common-control .emp {
  font-weight: 600;
  color: #2c2685;
}
.common-control + .common-table {
  margin-top: 2rem;
}
.common-form-table {
  display: table;
  text-align: left;
  table-layout: fixed;
  width: 100%;
}
.common-form-table + .common-form-table {
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid #eee;
}
.common-form-table .row {
  display: table-row;
}
.common-form-table .row .row-label {
  display: table-cell;
  width: 42%;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
}
.common-form-table .row .row-label:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #2c2685;
  vertical-align: top;
  margin-top: 4px;
  margin-right: 8px;
}
.common-form-table .row .row-content {
  display: table-cell;
  padding: 6px 0;
  height: 4rem;
}
.common-form-table .row .row-content [class*="custom-"] {
  height: 4rem;
  border-color: #ddd;
}
.common-view-table,
.common-edit-table {
  table-layout: fixed;
  width: 100%;
}
.common-view-table thead th,
.common-edit-table thead th {
  background: #f8f9fa;
  height: 4.4rem;
  border: 1px solid #ddd;
  padding: 1em 1.6em;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  text-align: center;
}
.common-view-table tbody th,
.common-edit-table tbody th {
  background: #f8f9fa;
  height: 6.4rem;
  border: 1px solid #ddd;
  padding: 1em 1.6em;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  text-align: left;
}
.common-view-table tbody th.center,
.common-edit-table tbody th.center {
  text-align: center;
}
.common-view-table tbody th.center .common-btn,
.common-edit-table tbody th.center .common-btn {
  margin: 0 auto;
}
.common-view-table tbody td,
.common-edit-table tbody td {
  height: 6.4rem;
  border: 1px solid #ddd;
  text-align: left;
  padding: 14px;
  font-size: 1.5rem;
  color: #333;
  font-weight: 500;
}
.common-view-table tbody td [class*="custom-"],
.common-edit-table tbody td [class*="custom-"] {
  min-height: 4rem;
  border-color: #ddd;
}
.common-view-table tbody td.center,
.common-edit-table tbody td.center {
  text-align: center;
}
.common-view-table tbody td.center *,
.common-edit-table tbody td.center * {
  margin: 0 auto;
}
.common-view-table + .common-table-label,
.common-edit-table + .common-table-label {
  margin-top: 2em;
}
.common-view-table .empty,
.common-edit-table .empty {
  padding: 1em 1em;
  text-align: center;
}
.common-view-table .guide,
.common-edit-table .guide {
  font-size: 1.4rem;
  color: #888;
  padding: 0.6em 0 0;
  font-weight: 400;
}
.common-view-table + .common-view-table,
.common-edit-table + .common-view-table {
  margin-top: 2rem;
}
.common-view-table .required:after,
.common-edit-table .required:after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  background: red;
  border-radius: 50%;
  vertical-align: top;
  margin-left: 5px;
}
.common-view-table [class*="custom-"],
.common-edit-table [class*="custom-"] {
  box-shadow: none;
}
.editor-view {
  min-height: 20rem;
  word-break: keep-all;
  word-wrap: break-word;
  line-height: 1.2;
}
.common-table-label {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.common-table-label .title {
  font-size: 1.6rem;
  font-weight: 700;
}
.common-table-label .desc {
  font-size: 1.5rem;
  color: #888;
}
.common-grid {
  width: 100%;
  font-family: "Pretendard";
}
.common-grid .tui-grid-container {
  width: 100%;
  font-family: "Pretendard";
  font-size: 1.5rem;
}
.common-grid .tui-grid-table {
  border-collapse: collapse;
}
.common-grid .tui-grid-table td {
  font-weight: 500;
  padding: 0 4px;
}
.common-grid .tui-grid-rside-area {
  border: none;
}
.common-grid .tui-grid-border-line-top {
  display: none;
}
.common-grid .tui-grid-border-line-bottom {
  display: none;
}
.common-grid .tui-grid-border-line-left {
  display: none;
}
.common-grid .tui-grid-border-line-right {
  display: none;
}
.common-grid .tui-grid-table {
  font-size: 14px;
  letter-spacing: -0.03em;
  border: 0px solid rgba(0, 0, 0, 0);
}
.common-grid .tui-grid-table tbody tr:first-child th {
  border-top: none;
}
.common-grid .tui-grid-table tbody tr:last-child td {
  border-bottom: none;
  border-right: none;
}
.common-grid .tui-grid-table tbody tr th:first-child {
  border-radius: 8px 0 0 0;
}
.common-grid .tui-grid-table tbody tr th:last-child {
  border-radius: 0 0 8px 0;
}
.common-grid .tui-grid-table tbody tr td:last-child {
  border-right: none;
}
.common-grid .tui-grid-header-area {
  border-color: #eee;
  border-top: 0px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  border-right: 1px solid rgba(0, 0, 0, 0);
}
.common-grid .tui-grid-cell-header {
  color: #646464;
  font-size: 1.5rem;
  font-weight: 500;
}
.common-grid .tui-grid-body-area:before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.03);
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 1;
}
.common-grid .tui-grid-btn-sorting {
  font-family: "remixicon";
  width: 14px;
  height: 14px;
  background: none;
  font-weight: normal;
  color: #888;
  margin-left: 10px;
  margin-top: -2px;
}
.common-grid .tui-grid-btn-sorting:after {
  content: "";
  display: block;
  font-size: 14px;
}
.common-grid .tui-grid-btn-sorting-up:after {
  font-family: "remixicon";
  content: "";
  display: block;
  font-size: 14px;
}
.common-grid .tui-grid-btn-sorting-down:after {
  font-family: "remixicon";
  content: "";
  display: block;
  font-size: 14px;
}
.common-grid .tui-grid-scrollbar-y-outer-border,
.common-grid .tui-grid-scrollbar-right-bottom {
  display: none;
}
.common-grid .tui-grid-scrollbar-right-top {
  border-right: none;
  border-left: 1px solid #eee;
  width: 17px;
}
.common-grid .link {
  text-decoration: underline;
}
.common-grid .tui-grid-content-area {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #ddd;
}
.common-grid .tui-grid-cell-has-input .tui-grid-cell-content {
  padding: 4px 8px;
}
.common-grid .tui-grid-lside-area {
  border-right: 1px solid #eee;
}
.common-grid .tui-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
}
.common-grid .tui-pagination > * {
  width: 4rem;
  height: 4rem;
  flex: 0 0 4rem;
}
.common-grid .tui-pagination .tui-page-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 4rem;
  height: 4rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: #000;
  border: 1px solid #ddd;
  background-color: #fff;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  transition: background 0.2s;
}
.common-grid .tui-pagination .tui-page-btn[class*="-ellip"] {
  border: none;
}
.common-grid .tui-pagination .tui-page-btn:not([class*="-ellip"]):hover,
.common-grid .tui-pagination .tui-page-btn .tui-is-selected {
  border-color: #2c2685;
  background-color: rgba(85, 74, 255, 0.22);
  color: #2c2685;
}
.common-grid .tui-pagination .tui-page-btn [class*="tui-ico"] {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
}
.common-grid .tui-pagination .tui-page-btn .tui-ico-first {
  background-image: url("../../assets/img/common/icon-pager-first.svg");
}
.common-grid .tui-pagination .tui-page-btn .tui-ico-last {
  background-image: url("../../assets/img/common/icon-pager-last.svg");
}
.common-grid .tui-pagination .tui-page-btn .tui-ico-prev {
  background-image: url("../../assets/img/common/icon-pager-prev.svg");
}
.common-grid .tui-pagination .tui-page-btn .tui-ico-next {
  background-image: url("../../assets/img/common/icon-pager-next.svg");
}
.common-grid .tui-pagination .tui-page-btn .tui-ico-ellip {
  border: none;
  background-image: url("../../assets/img/common/icon-pager-omit.svg");
}
.notice-list {
  margin-bottom: 4rem;
  text-align: left;
}
.notice-list .list-item {
  line-height: 1.2;
  color: #777;
  padding-left: 14px;
  position: relative;
  font-size: 1.5rem;
  word-break: keep-all;
}
.notice-list .list-item + .list-item {
  margin-top: 0.4em;
}
.notice-list .list-item:before {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  background: #000;
  position: absolute;
  left: 0;
  top: 6px;
}
.notice-list .emp {
  color: #2c2685;
  font-weight: 500;
}
.app-status {
  display: flex;
  align-items: center;
  gap: 1.8rem;
  padding-left: 2.4rem;
  margin-left: 2.4rem;
  position: relative;
}
.app-status:before {
  content: "";
  display: block;
  width: 1px;
  height: 14px;
  background: rgba(255, 255, 255, 0.32);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.app-status .status-item {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  gap: 1.2rem;
  letter-spacing: -0.04em;
}
.app-status .status-item.total {
  color: #1ce26b;
}
.app-status .status-item.driving {
  color: #5493ff;
}
.app-status .status-item.stop {
  color: #ff9233;
}
.app-status .status-item.end {
  color: #807e9b;
}
.app-status .status-item .item-label {
  font-weight: 600;
}
.app-status .status-item .item-content {
  font-weight: 700;
  color: #fff;
}
@media screen and (max-width: 1600px) {
  .app-status {
    width: 200px;
    flex-wrap: wrap;
    gap: 0.9rem 1.8rem;
  }
  .app-status .status-item {
    width: calc(50% - 0.9rem);
  }
}
.util-list {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 3.7rem;
}
.util-list .util-item.mobile {
  display: none;
}
.util-list .util-item:not(.user) {
  position: relative;
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: -0.03em;
}
.util-list .util-item:not(.user):before {
  content: "";
  display: block;
  width: 1px;
  height: 12px;
  background: rgba(255, 255, 255, 0.32);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -2rem;
}
.util-list .util-item:not(.user):hover .sub-menu {
  opacity: 1;
  visibility: visible;
}
.util-list .util-item:not(:has(.sub-menu)) .util-btn:before {
  content: "";
  display: block;
  width: calc(100% + 1.4em);
  height: 2em;
  background: rgba(255, 255, 255, 0.12);
  position: absolute;
  left: 50%;
  top: calc(50% - 1px);
  transform: translate(-50%, -50%);
  border-radius: 9999px;
  opacity: 0;
  transition: opacity 0.2s;
}
.util-list .util-item:not(:has(.sub-menu)) .util-btn:hover:before {
  opacity: 1;
}
.util-list .user-info {
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 1.7rem;
  font-weight: 600;
}
.util-list .user-info .user-avatar {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.29);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  position: relative;
}
.util-list .user-info .user-avatar img {
  width: 100%;
  height: 100%;
}
.util-list .user-info .user-avatar:before {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #1ce26b;
  position: absolute;
  right: 0.3rem;
  bottom: 0.3rem;
}
.util-list .util-btn {
  position: relative;
  padding: 1em 0;
}
.util-list .util-btn.more {
  padding-right: 2rem;
}
.util-list .util-btn.more:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 5px solid #fff;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.util-list .sub-menu {
  position: absolute;
  left: 50%;
  top: 1.4em;
  transform: translateX(-50%);
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  background: #3d3864;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 12px 7px rgba(0, 0, 0, 0.19);
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
}
.util-list .sub-menu .menu-btn {
  min-width: 7.2rem;
  padding: 1rem 1em;
  font-size: 1.4rem;
  font-weight: 500;
  transition: background 0.2s;
}
.util-list .sub-menu .menu-btn:hover {
  background: #413aab;
}
.util-list .sub-menu .menu-btn + .menu-btn {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
@media screen and (max-width: 1600px) {
  .util-list .util-item {
    display: none;
  }
  .util-list .util-item.user {
    display: block;
  }
  .util-list .util-item.mobile {
    display: block;
  }
  .util-list .util-item:last-child {
    display: block;
  }
}
.gnb-list {
  display: flex;
  height: 100%;
}
.gnb-list .nav-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.72;
  width: 7rem;
  height: 100%;
  position: relative;
  transition: all 0.2s;
}
.gnb-list .nav-btn .btn-icon {
  font-size: 2.4rem;
  margin-bottom: 4px;
  transition: margin 0.2s;
}
.gnb-list .nav-btn .btn-label {
  font-size: 1.4rem;
}
.gnb-list .nav-btn:hover {
  opacity: 1;
}
.gnb-list .nav-btn.active {
  opacity: 1;
}
.gnb-list .nav-btn.active:after {
  content: "";
  display: block;
  width: 4.8rem;
  height: 0.3rem;
  background: #554aff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.full-mode .app-header {
  height: 6rem;
}
.full-mode .app-header .gnb-list .nav-btn {
  width: 5.8rem;
}
.full-mode .app-header .gnb-list .btn-icon {
  margin-bottom: -2px;
}
.full-mode .app-header .gnb-list .btn-label {
  opacity: 0;
  visibility: hidden;
  font-size: 0;
}
@media screen and (max-width: 1600px) {
  .gnb-list .nav-btn {
    width: 6rem;
  }
  .gnb-list .nav-btn .btn-label {
    font-size: 1.3rem;
  }
}
.marker {
  width: 54px;
}
.marker .marker-icon {
  position: relative;
}
.marker .marker-icon:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  border-top: 19px solid transparent;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.marker.pause .marker-icon:before {
  border-top-color: red;
}
.marker.start .marker-icon:before {
  border-top-color: red;
}
.marker.end .marker-icon:before {
  border-top-color: red;
}
.marker.geo .marker-icon:before {
  /* border-top-color: #a49fef; */
}
.marker.street .marker-icon:before {
  /* border-top-color: #a49fef; */
}
.marker .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  transition: all 0.6s;
}
.marker .icon i {
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-position: center;
}
.marker.pause .icon {
  background: #ff9233;
  border: 3px solid #fce3cd;
  box-shadow: 0 5px 6px rgba(168, 52, 0, 0.25);
}
.marker.start .icon {
  background: #1b61ec;
  border: 3px solid #c1d3f8;
  box-shadow: 0 3px 6px rgba(22, 81, 198, 0.43);
}
.marker.end .icon {
  background: #888;
  border: 3px solid #ddd;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
}
.marker.geo .icon {
  background: #2c2685;
  border: 3px solid #b3b1d1;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
}
.marker.geo .icon i {
  font-size: 2.4rem;
  color: #fff;
  width: auto;
  height: auto;
}
.marker.street .icon {
  background: #2c2685;
  border: 3px solid #b3b1d1;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
}
.marker.normal .icon {
  background: #f5f5f5;
  border: 3px solid #d6d6d6;
}
.marker.normal .icon i {
  filter: invert(100%);
}
.marker .marker-name {
  height: 32px;
  white-space: nowrap;
  line-height: 32px;
  padding: 0 1em;
  border-radius: 9999px;
  border: 1px solid #000;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16);
  background: #fff;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}
.marker.network .icon:before {
  content: "";
  position: absolute;
  background: url("../../assets/img/markers/icon-network.svg") no-repeat;
  width: 18px;
  height: 18px;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.marker.bluetooth .icon:before {
  content: "";
  position: absolute;
  background: url("../../assets/img/markers/icon-bt.svg") no-repeat;
  width: 14px;
  height: 20px;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.marker.car .icon i {
  background-image: url("../img/markers/icon-car-1.svg");
}
.marker.car.start .icon i {
  background-image: url("../img/markers/icon-car-2.svg");
}
.marker.car.pause .icon i {
  background-image: url("../img/markers/icon-car-3.svg");
}
.marker.car.end .icon i {
  background-image: url("../img/markers/icon-car-4.svg");
}
.marker.motorcycle .icon i {
  background-image: url("../img/markers/icon-motorcycle-1.svg");
}
.marker.motorcycle.start .icon i {
  background-image: url("../img/markers/icon-motorcycle-2.svg");
}
.marker.motorcycle.pause .icon i {
  background-image: url("../img/markers/icon-motorcycle-3.svg");
}
.marker.motorcycle.end .icon i {
  background-image: url("../img/markers/icon-motorcycle-4.svg");
}
.marker.human .icon i {
  background-image: url("../img/markers/icon-human-1.svg");
}
.marker.human.start .icon i {
  background-image: url("../img/markers/icon-human-2.svg");
}
.marker.human.pause .icon i {
  background-image: url("../img/markers/icon-human-3.svg");
}
.marker.human.end .icon i {
  background-image: url("../img/markers/icon-human-4.svg");
}
.marker.bike .icon i {
  background-image: url("../img/markers/icon-bike-1.svg");
}
.marker.bike.start .icon i {
  background-image: url("../img/markers/icon-bike-2.svg");
}
.marker.bike.pause .icon i {
  background-image: url("../img/markers/icon-bike-3.svg");
}
.marker.bike.end .icon i {
  background-image: url("../img/markers/icon-bike-4.svg");
}
.marker.truck .icon i {
  background-image: url("../img/markers/icon-truck-1.svg");
}
.marker.truck.start .icon i {
  background-image: url("../img/markers/icon-truck-2.svg");
}
.marker.truck.pause .icon i {
  background-image: url("../img/markers/icon-truck-3.svg");
}
.marker.truck.end .icon i {
  background-image: url("../img/markers/icon-truck-4.svg");
}
.marker.trash .icon i {
  background-image: url("../img/markers/icon-trash-1.svg");
}
.marker.trash.start .icon i {
  background-image: url("../img/markers/icon-trash-2.svg");
}
.marker.trash.pause .icon i {
  background-image: url("../img/markers/icon-trash-3.svg");
}
.marker.trash.end .icon i {
  background-image: url("../img/markers/icon-trash-4.svg");
}
.marker.school .icon i {
  background-image: url("../img/markers/icon-school-1.svg");
}
.marker.school.start .icon i {
  background-image: url("../img/markers/icon-school-2.svg");
}
.marker.school.pause .icon i {
  background-image: url("../img/markers/icon-school-3.svg");
}
.marker.school.end .icon i {
  background-image: url("../img/markers/icon-school-4.svg");
}
.marker.airplane .icon i {
  background-image: url("../img/markers/icon-airplane-1.svg");
}
.marker.airplane.start .icon i {
  background-image: url("../img/markers/icon-airplane-2.svg");
}
.marker.airplane.pause .icon i {
  background-image: url("../img/markers/icon-airplane-3.svg");
}
.marker.airplane.end .icon i {
  background-image: url("../img/markers/icon-airplane-4.svg");
}
.marker.ship .icon i {
  background-image: url("../img/markers/icon-ship-1.svg");
}
.marker.ship.start .icon i {
  background-image: url("../img/markers/icon-ship-2.svg");
}
.marker.ship.pause .icon i {
  background-image: url("../img/markers/icon-ship-3.svg");
}
.marker.ship.end .icon i {
  background-image: url("../img/markers/icon-ship-4.svg");
}
.marker.crane .icon i {
  background-image: url("../img/markers/icon-crane-1.svg");
}
.marker.crane.start .icon i {
  background-image: url("../img/markers/icon-crane-2.svg");
}
.marker.crane.pause .icon i {
  background-image: url("../img/markers/icon-crane-3.svg");
}
.marker.crane.end .icon i {
  background-image: url("../img/markers/icon-crane-4.svg");
}
.marker.pet .icon i {
  background-image: url("../img/markers/icon-pet-1.svg");
}
.marker.pet.start .icon i {
  background-image: url("../img/markers/icon-pet-2.svg");
}
.marker.pet.pause .icon i {
  background-image: url("../img/markers/icon-pet-3.svg");
}
.marker.pet.end .icon i {
  background-image: url("../img/markers/icon-pet-4.svg");
}
.marker.street .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.marker.street .icon i {
  width: auto;
  height: auto;
  font-size: 2.4rem;
  color: #fff;
}
#map {
  width: 100%;
  height: 100%;
  z-index: 10;
}
#map .cluster {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border-radius: 50%;
}
#map .cluster.cluster-1 {
  background: #847fcb;
  box-shadow: 0 0 0 3px rgba(132, 127, 203, 0.34);
}
#map .cluster.cluster-2 {
  background: #524cab;
  box-shadow: 0 0 0 3px rgba(82, 76, 171, 0.34);
}
#map .cluster.cluster-3 {
  background: #2c268a;
  box-shadow: 0 0 0 3px rgba(57, 51, 149, 0.34);
}
#map .cluster.cluster-4 {
  background: #1c175d;
  box-shadow: 0 0 0 3px rgba(28, 23, 93, 0.34);
}
#map .cluster.cluster-5 {
  background: #0a072b;
  box-shadow: 0 0 0 3px rgba(28, 23, 93, 0.34);
}
#map .path-marker {
  min-width: 24px;
  height: 24px;
  background: #2c2685;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border-radius: 9999px;
  padding: 0 8px;
}
#map .path-shadow {
  -webkit-filter: drop-shadow(13px 15px 6px rgba(113, 104, 228, 0.8));
  filter: drop-shadow(13px 15px 6px rgba(113, 104, 228, 0.8));
}
#map .active {
  z-index: 999999998;
}
#map .active .temp {
  z-index: 999999999;
}
#map .active .marker:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background: #2c2685;
  border-radius: 50% 50%;
  box-shadow: 0 3px 6px rgba(44, 38, 133, 0.31);
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}
#map .active .marker:after {
  content: "";
  display: block;
  width: 9px;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  z-index: 3;
}
#map .active .marker .marker-name {
  background: #000;
  color: #fff;
}
#map .active .path-marker {
  background: #2bc167;
  position: relative;
}
#map .active .path-marker:before {
  content: "";
  width: 6rem;
  height: 6rem;
  background: url("../../assets/img/markers/icon-pathmarker.svg") no-repeat
    center;
  position: absolute;
  top: -6rem;
  left: 50%;
  transform: translateX(-50%);
}
.track-list-panel {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 1rem;
  transition: transform 0.2s ease-in-out;
}
.track-list-panel.fold {
  transform: translateX(-34.6rem);
}
.track-list-panel.fold .fold-btn .icon {
  transform: rotate(180deg);
}
.track-list-panel .panel-inner {
  width: 33.6rem;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.track-list-panel .track-tab-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.track-list-panel .track-tab-wrapper .tab-nav {
  height: 5.4rem;
  flex: 0 0 5.4rem;
}
.track-list-panel .track-tab-wrapper .tab-nav .tab-inner {
  display: flex;
  height: 100%;
  padding: 0 1rem;
  border-bottom: 1px solid #ddd;
}
.track-list-panel .track-tab-wrapper .tab-nav .nav-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #888;
  font-weight: 500;
  font-size: 1.5rem;
  position: relative;
}
.track-list-panel .track-tab-wrapper .tab-nav .nav-btn.on {
  color: #000;
  font-weight: 600;
}
.track-list-panel .track-tab-wrapper .tab-nav .nav-btn.on:after {
  content: "";
  display: block;
  width: 5.2rem;
  height: 3px;
  background: #000;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}
.track-list-panel .track-tab-wrapper .tab-panel-wrapper {
  height: 100%;
  overflow: hidden;
}
.track-list-panel .track-tab-wrapper .tab-panel-wrapper .tab-panel {
  height: 100%;
  flex-direction: column;
  display: flex;
  display: none;
}
.track-list-panel .track-tab-wrapper .tab-panel-wrapper .tab-panel.on {
  display: flex;
}
.track-list-panel .fold-btn {
  width: 2.8rem;
  height: 4.4rem;
  border-radius: 0 6px 6px 0;
  background: #0d0940;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -1.8rem;
  top: 50%;
  transform: translateY(-50%);
  border: none;
}
.track-list-panel .fold-btn .icon {
  font-size: 2.4rem;
  color: #fff;
  margin-left: -2px;
}
.track-list-panel .fold-btn:active {
  background: #413aab;
}
.track-list {
  padding: 1.7rem 0 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.track-list.multi {
  padding-top: 0;
}
.track-list.multi .list-header {
  padding: 0;
}
.track-list .list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem 1.3rem;
  transition: box-shadow 0.2s;
}
.track-list .list-header .total {
  letter-spacing: -0.04em;
}
.track-list .list-header .total .label {
  font-size: 1.5rem;
  font-weight: 1.5rem;
  color: #8d8d8d;
  padding-right: 4px;
}
.track-list .list-header .total .count {
  font-size: 1.6rem;
  font-weight: 700;
  color: #000;
}
.track-list .list-header .selected-date {
  font-size: 1.6rem;
  font-weight: 600;
  color: #000;
}
.track-list .list-header .selected-date ~ .total {
  display: flex;
}
.track-list .list-header .selected-date ~ .total .label {
  color: #8d8d8d;
}
.track-list .list-header .selected-date ~ .total .count {
  color: #1b61ec;
  padding-left: 4px;
}
.track-list.on .list-header {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.06);
}
.track-list .list-body {
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
}
.track-list .list-body::-webkit-scrollbar {
  /* width: 4px; */
  width: 12px;
}
.track-list .list-body::-webkit-scrollbar-thumb {
  height: 40px;
  background-color: rgba(107, 107, 107, 0.49);
  border-radius: 100px;
}
.track-list .list-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.49);
}
.track-list .list-body li + li {
  margin-top: 6px;
}
.track-list .item-card {
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0 0 7px rgba(104, 111, 127, 0.16);
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}
.track-list .item-card .marker .marker-icon:before {
  display: none;
}
.track-list .item-card .item-info {
  color: #7b7b7b;
  font-size: 1.4rem;
  padding-left: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.track-list .item-card .item-name {
  font-size: 1.7rem;
  color: #000;
  font-weight: 700;
  margin-bottom: 4px;
}
.track-list .item-card .item-name-m {
  font-size: 1.5rem;
  color: #000;
  font-weight: 700;
  margin-bottom: 4px;
}
.track-list .item-card .item-name-s {
  font-size: 1.5rem;
  color: #000;
  font-weight: 700;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
.track-list .item-card .item-distance {
  font-weight: 500;
  padding-left: 2.2rem;
  background: url("../../assets/img/markers/icon-item-distance.svg") no-repeat
    left center;
}
.track-list .item-card .item-recent {
  padding-left: 2.2rem;
  background: url("../../assets/img/markers/icon-item-recent.svg") no-repeat
    left center;
}
.track-list .item-card .item-num {
  flex: none;
  margin-left: auto;
  min-width: 3.6rem;
  height: 3.6rem;
  white-space: nowrap;
  line-height: 3.8rem;
  padding: 0 0.7em;
  font-weight: 700;
  font-size: 1.6rem;
  color: #fff;
  border-radius: 9999px;
  background: #2c2685;
  text-align: center;
}
.track-list .item-card .item-status {
  flex: none;
  margin-left: auto;
  font-weight: 600;
  font-size: 1.6rem;
  padding-right: 0.6rem;
}
.track-list .item-card .item-status .start {
  color: #1b61ec;
}
.track-list .item-card .item-status .pause {
  color: #fe9031;
}
.track-list .item-card .item-status .end {
  color: #868686;
}
.track-list .item-card.on {
  border: 1px solid #c6c5c5;
}
.right-info-panel {
  position: absolute;
  max-height: 100%;
  top: 0;
  right: 0;
  z-index: 999;
  padding: 1rem;
  transition: transform 0.2s ease-in-out;
  color: #fff;
  display: none;
  overflow: hidden;
}
.right-info-panel.on {
  display: block;
}
.right-info-panel.fold {
  height: 100%;
}
.right-info-panel.fold .detail-inner {
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}
.right-info-panel.fold .detail-content {
  display: none;
}
.right-info-panel.fold .detail-bottom {
  overflow: hidden;
}
.right-info-panel.fold .detail-bottom .detail-tab-wrapper {
  height: 100%;
  overflow: hidden;
}
.right-info-panel.minimize {
  height: auto;
}
.right-info-panel.minimize .detail-summary,
.right-info-panel.minimize .detail-bottom {
  display: none !important;
}
.right-info-panel.minimize
  .detail-inner
  .detail-top
  .detail-header
  .header-control
  .size:after {
  bottom: 13px;
}
.right-info-panel .detail-inner {
  width: 33.6rem;
  display: flex;
  flex-direction: column;
}
.right-info-panel .detail-inner > div {
  border-radius: 4px;
  background: #191634;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.right-info-panel .detail-inner .detail-top {
  flex: 0 0 auto;
  margin-bottom: 10px;
}
.right-info-panel .detail-inner .detail-top .detail-header {
  width: 100%;
  height: 3.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background: #151241;
  border-bottom: 1px solid #33315a;
}
.right-info-panel .detail-inner .detail-top .detail-header .header-title {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.4rem;
  font-weight: 500;
}
.right-info-panel .detail-inner .detail-top .detail-header .header-title .icon {
  font-size: 1.6rem;
  opacity: 0.3;
  font-weight: normal;
}
.right-info-panel .detail-inner .detail-top .detail-header .header-control {
  display: flex;
  gap: 2px;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-header
  .header-control
  button {
  border: none;
  width: 20px;
  height: 20px;
  position: relative;
  background: none;
  border-radius: 3px;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-header
  .header-control
  button:hover {
  background: #2c2685;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-header
  .header-control
  .size:before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-header
  .header-control
  .size:after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-header
  .header-control
  .close:before {
  content: "";
  display: block;
  width: 14px;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-header
  .header-control
  .close:after {
  content: "";
  display: block;
  width: 14px;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.right-info-panel .detail-inner .detail-top .detail-body .detail-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.4rem;
  border-bottom: 1px solid #2e2b46;
}
.right-info-panel .detail-inner .detail-top .detail-body .detail-summary .left {
  display: flex;
  align-items: center;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .detail-summary
  .right {
  display: flex;
  align-items: center;
}
.right-info-panel .detail-inner .detail-top .detail-body .marker {
  width: 3rem;
  margin-right: 6px;
}
.right-info-panel .detail-inner .detail-top .detail-body .marker .icon {
  width: 3rem;
  height: 3rem;
  position: relative;
  /* border: none; */
  box-shadow: none;
  /* background: none; */
}
.right-info-panel .detail-inner .detail-top .detail-body .marker .icon i {
  background-size: 158% auto;
}
.right-info-panel .detail-inner .detail-top .detail-body .marker .icon:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 3px;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.pause
  .icon:after {
  /* background: #ff9132; */
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.end
  .icon:after {
  /* background: #ddd; */
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.start
  .icon:after {
  /* background: #1b61ec; */
}
.right-info-panel .detail-inner .detail-top .detail-body .marker.car .icon i {
  background-image: url("../img/markers/icon-car-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.car.start
  .icon
  i {
  background-image: url("../img/markers/icon-car-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.car.pause
  .icon
  i {
  background-image: url("../img/markers/icon-car-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.car.end
  .icon
  i {
  background-image: url("../img/markers/icon-car-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.motorcycle
  .icon
  i {
  background-image: url("../img/markers/icon-motorcycle-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.motorcycle.start
  .icon
  i {
  background-image: url("../img/markers/icon-motorcycle-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.motorcycle.pause
  .icon
  i {
  background-image: url("../img/markers/icon-motorcycle-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.motorcycle.end
  .icon
  i {
  background-image: url("../img/markers/icon-motorcycle-1.svg");
}
.right-info-panel .detail-inner .detail-top .detail-body .marker.human .icon i {
  background-image: url("../img/markers/icon-human-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.human.start
  .icon
  i {
  background-image: url("../img/markers/icon-human-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.human.pause
  .icon
  i {
  background-image: url("../img/markers/icon-human-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.human.end
  .icon
  i {
  background-image: url("../img/markers/icon-human-1.svg");
}
.right-info-panel .detail-inner .detail-top .detail-body .marker.bike .icon i {
  background-image: url("../img/markers/icon-bike-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.bike.start
  .icon
  i {
  background-image: url("../img/markers/icon-bike-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.bike.pause
  .icon
  i {
  background-image: url("../img/markers/icon-bike-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.bike.end
  .icon
  i {
  background-image: url("../img/markers/icon-bike-1.svg");
}
.right-info-panel .detail-inner .detail-top .detail-body .marker.truck .icon i {
  background-image: url("../img/markers/icon-truck-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.truck.start
  .icon
  i {
  background-image: url("../img/markers/icon-truck-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.truck.pause
  .icon
  i {
  background-image: url("../img/markers/icon-truck-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.truck.end
  .icon
  i {
  background-image: url("../img/markers/icon-truck-1.svg");
}
.right-info-panel .detail-inner .detail-top .detail-body .marker.trash .icon i {
  background-image: url("../img/markers/icon-trash-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.trash.start
  .icon
  i {
  background-image: url("../img/markers/icon-trash-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.trash.pause
  .icon
  i {
  background-image: url("../img/markers/icon-trash-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.trash.end
  .icon
  i {
  background-image: url("../img/markers/icon-trash-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.school
  .icon
  i {
  background-image: url("../img/markers/icon-school-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.school.start
  .icon
  i {
  background-image: url("../img/markers/icon-school-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.school.pause
  .icon
  i {
  background-image: url("../img/markers/icon-school-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.school.end
  .icon
  i {
  background-image: url("../img/markers/icon-school-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.airplane
  .icon
  i {
  background-image: url("../img/markers/icon-airplane-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.airplane.start
  .icon
  i {
  background-image: url("../img/markers/icon-airplane-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.airplane.pause
  .icon
  i {
  background-image: url("../img/markers/icon-airplane-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.airplane.end
  .icon
  i {
  background-image: url("../img/markers/icon-airplane-1.svg");
}
.right-info-panel .detail-inner .detail-top .detail-body .marker.ship .icon i {
  background-image: url("../img/markers/icon-ship-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.ship.start
  .icon
  i {
  background-image: url("../img/markers/icon-ship-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.ship.pause
  .icon
  i {
  background-image: url("../img/markers/icon-ship-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.ship.end
  .icon
  i {
  background-image: url("../img/markers/icon-ship-1.svg");
}
.right-info-panel .detail-inner .detail-top .detail-body .marker.crane .icon i {
  background-image: url("../img/markers/icon-crane-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.crane.start
  .icon
  i {
  background-image: url("../img/markers/icon-crane-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.crane.pause
  .icon
  i {
  background-image: url("../img/markers/icon-crane-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.crane.end
  .icon
  i {
  background-image: url("../img/markers/icon-crane-1.svg");
}
.right-info-panel .detail-inner .detail-top .detail-body .marker.pet .icon i {
  background-image: url("../img/markers/icon-pet-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.pet.start
  .icon
  i {
  background-image: url("../img/markers/icon-pet-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.pet.pause
  .icon
  i {
  background-image: url("../img/markers/icon-pet-1.svg");
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .marker.pet.end
  .icon
  i {
  background-image: url("../img/markers/icon-pet-1.svg");
}
.right-info-panel .detail-inner .detail-top .detail-body .name {
  font-size: 2rem;
  font-weight: 700;
}
.right-info-panel .detail-inner .detail-top .detail-body .detail-content {
  padding: 2.4rem;
}
.right-info-panel .detail-inner .detail-top .detail-body .detail-content .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.2rem;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .detail-content
  .row
  .detail-item
  .item-label {
  margin-bottom: 6px;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .detail-content
  .row
  .detail-item
  .item-content {
  font-size: 1.6rem;
  font-weight: 700;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .detail-content
  .col
  .detail-item {
  display: flex;
  justify-content: space-between;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .detail-content
  .col
  .detail-item
  + .detail-item {
  margin-top: 0.6em;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .detail-content
  .detail-item
  .item-label {
  color: #e6e6e6;
  font-size: 1.4rem;
}
.right-info-panel
  .detail-inner
  .detail-top
  .detail-body
  .detail-content
  .detail-item
  .item-content {
  font-size: 1.5rem;
}
.right-info-panel .detail-inner .detail-bottom .detail-tab-wrapper {
  display: flex;
  flex-direction: column;
}
.right-info-panel .tab-nav {
  height: 5.4rem;
  flex: 0 0 5.4rem;
}
.right-info-panel .tab-nav .tab-inner {
  display: flex;
  height: 100%;
  padding: 0 6rem;
  border-bottom: 1px solid #33315a;
  background: #151241;
}
.right-info-panel .tab-nav .nav-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  position: relative;
  opacity: 0.58;
}
.right-info-panel .tab-nav .nav-btn.on {
  opacity: 1;
  font-weight: 600;
}
.right-info-panel .tab-nav .nav-btn.on:after {
  content: "";
  display: block;
  width: 5.2rem;
  height: 3px;
  background: #554aff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.right-info-panel .tab-panel-wrapper {
  height: 100%;
  overflow: hidden;
}
.right-info-panel .tab-panel-wrapper .tab-panel {
  height: 100%;
  flex-direction: column;
  display: flex;
  display: none;
}
.right-info-panel .tab-panel-wrapper .tab-panel.on {
  display: flex;
}
.right-info-panel .search-module {
  background: none;
}
.right-info-panel .search-module .custom-select {
  background: #3b3952;
  border: none;
  width: 24%;
}
.right-info-panel .search-module .custom-select:after {
  border-top-color: #fff;
}
.right-info-panel .search-module .custom-select select {
  background: #3b3952;
  color: #fff;
}
.right-info-panel .search-module .custom-select.custom-datepicker {
  overflow: visible;
  flex: 1;
  width: 100%;
}
.right-info-panel .search-module .custom-select.custom-datepicker input {
  width: 100%;
  height: 100%;
  background: none;
  color: #fff;
  appearance: none;
  border: none;
  padding-left: 1em;
}
.right-info-panel
  .search-module
  .custom-select.custom-datepicker
  .datepicker-dropdown {
  width: 400px;
  z-index: 9999;
  position: fixed;
  left: auto !important;
  top: 24rem !important;
  right: 33.6rem !important;
}
.right-info-panel
  .search-module
  .custom-select.custom-datepicker
  .datepicker-dropdown
  .datepicker-picker
  .days-of-week
  .dow {
  color: #000;
}
.right-info-panel .search-module .custom-input {
  background: #3b3952;
  border: none;
}
.right-info-panel .search-module .custom-input input {
  color: #fff;
}
.right-info-panel .search-module .custom-input input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.right-info-panel:has(.track-list.on) .search-control {
  z-index: 1;
  box-shadow: 0 10px 10px rgba(21, 18, 65, 0.6);
}
.right-info-panel .recent-info {
  padding: 2.4rem;
}
.right-info-panel .recent-info .info-item {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.right-info-panel .recent-info .info-item + .info-item {
  margin-top: 0.6em;
}
.right-info-panel .recent-info .info-item .item-label {
  opacity: 0.58;
}
.right-info-panel .recent-info .info-item .item-content {
  font-weight: 500;
}
.right-info-panel .search-control {
  padding: 0 1rem 0.6rem;
  display: flex;
  justify-content: space-between;
}
.right-info-panel .search-control .showpath-btn {
  background: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 6px;
  font-size: 15px;
  font-weight: 600;
}
.right-info-panel .search-control .showpath-btn .icon {
  font-size: 2.4rem;
  font-weight: normal;
  opacity: 0.49;
}
.right-info-panel .track-list {
  padding: 0;
  height: 100%;
  display: block;
}
.right-info-panel .track-list .list-body {
  padding: 1rem;
}
.right-info-panel .track-list .list-body::-webkit-scrollbar {
  /* width: 4px; */
  width: 12px;
}
.right-info-panel .track-list .list-body::-webkit-scrollbar-thumb {
  height: 40px;
  background-color: rgba(255, 255, 255, 0.49);
  border-radius: 100px;
}
.right-info-panel .track-list .list-body li {
  position: relative;
}
.right-info-panel .track-list .list-body li:has(.on):before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 6px;
  z-index: 1;
  pointer-events: none;
}
.right-info-panel .track-list .list-body li + li {
  margin-top: 4px;
  position: relative;
}
.right-info-panel .track-list .list-body li + li:after {
  content: "";
  display: block;
  width: 3px;
  height: 4px;
  background: #2fe0de;
  position: absolute;
  left: 14px;
  top: -4px;
}
.right-info-panel .track-list:has(.card.on) .card {
  opacity: 0.5;
}
.right-info-panel .track-list:has(.card.on) .card:hover {
  opacity: 1;
}
.right-info-panel .track-list:has(.card.on) .card.on {
  opacity: 1;
}
.right-info-panel .track-list .card {
  height: 100%;
  cursor: pointer;
  background: #333053;
  border-radius: 6px;
  transition: background 0.2s;
  position: relative;
}
.right-info-panel .track-list .card .card-status {
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.2s;
}
.right-info-panel .track-list .card .card-status .top {
  height: 3.6rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background: #46435f;
  border-radius: 6px 6px 0 0;
}
.right-info-panel .track-list .card .card-status.start .top {
  background: #244fab;
}
.right-info-panel .track-list .card .card-status.pause .top {
  background: #d67a29;
}
.right-info-panel .track-list .card .card-status.end .top {
  background: #8d8d8d;
}
.right-info-panel .track-list .card .card-status .bottom {
  height: 3.6rem;
  display: flex;
  padding: 0 1rem;
}
.right-info-panel .track-list .card .card-status .bottom > * {
  padding: 1.1rem 0;
}
.right-info-panel .track-list .card .card-status .item-num {
  padding-right: 0.9rem;
  margin-right: 0.8rem;
  position: relative;
}
.right-info-panel .track-list .card .card-status .item-num:after {
  content: "";
  display: block;
  width: 1px;
  height: 10px;
  background: rgba(255, 255, 255, 0.34);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.right-info-panel .track-list .card .card-status .item-time {
  flex: none;
  margin-left: auto;
}
.right-info-panel .track-list .card .card-status .item-speed {
  padding-right: 0.9rem;
  margin-right: 0.8rem;
  position: relative;
}
.right-info-panel .track-list .card .card-status .item-speed:after {
  content: "";
  display: block;
  width: 1px;
  height: 10px;
  background: rgba(255, 255, 255, 0.34);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  top: 1.8rem;
  right: 0;
}
.right-info-panel .track-list .card .card-status .item-addr {
  flex: 1;
  text-align: right;
  padding-left: 0.4rem;
}
.right-info-panel .track-list .card .card-detail {
  display: none;
  padding: 1.2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.14);
}
.right-info-panel .track-list .card .card-detail .row {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  font-size: 1.4rem;
}
.right-info-panel .track-list .card .card-detail .row + .row {
  margin-top: 6px;
}
.right-info-panel .track-list .card .card-detail .row .item-label {
  opacity: 0.7;
}
.right-info-panel .track-list .card.on .card-status:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background: #2c2685;
  border-radius: 50% 50%;
  box-shadow: 0 3px 6px rgba(44, 38, 133, 0.31);
  position: absolute;
  right: -6px;
  top: -6px;
  z-index: 3;
}
.right-info-panel .track-list .card.on .card-status:after {
  content: "";
  display: block;
  width: 8px;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: -1px;
  right: 0px;
  z-index: 3;
  transform: rotate(-45deg);
}
.right-info-panel .track-list .card.on .card-detail {
  display: block;
}
.right-info-panel .track-list .card:hover {
  background: #2a2744;
  opacity: 1;
}
.right-info-panel .track-list .card:hover.start .top {
  background: #1b4cb6;
}
.right-info-panel .track-list .card:hover.pause .top {
  background: #df7920;
}
.right-info-panel .track-list .card:hover.end .top {
  background: #888484;
}
.map-control {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  display: flex;
  gap: 20px;
}
.map-control .control-group {
  display: flex;
  gap: 4px;
}
.map-control .control-group .control-btn {
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 11px;
  height: 3.6rem;
  white-space: nowrap;
  cursor: pointer;
}
.map-control .control-group .control-btn.on {
  background: #2c2685;
}
.map-control .control-group .control-btn.on .icon,
.map-control .control-group .control-btn.on .label {
  color: #fff;
}
.map-control .control-group .control-btn.on .content.blue {
  color: #fff;
}
.map-control .control-group .control-btn .label {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
}
.map-control .control-group .control-btn .content {
  padding-left: 8px;
  font-size: 1.5rem;
  font-weight: 700;
}
.map-control .control-group .control-btn .content.blue {
  color: #1b61ec;
}
.map-control .control-group .control-btn .content.orange {
  color: #fe5d2a;
}
.map-control .control-group .control-btn .icon {
  color: #000;
  font-size: 2rem;
  font-weight: 500;
}
.map-control .control-group .control-btn .icon ~ .label {
  padding-left: 5px;
}
.map-control .control-group .control-btn.refresh {
  width: 3.6rem;
  padding: 0;
  justify-content: center;
}
.map-control .control-group.map .control-btn {
  height: 100%;
}
.map-control .control-group.map .control-btn .icon {
  font-size: 1.8rem;
  color: #888;
}
.map-control .control-group.map .control-btn.zoom-in,
.map-control .control-group.map .control-btn.zoom-out,
.map-control .control-group.map .control-btn.full {
  width: 3.6rem;
  padding: 0;
  justify-content: center;
}
.map-control .control-group.map .control-btn.zoom-in .icon,
.map-control .control-group.map .control-btn.zoom-out .icon,
.map-control .control-group.map .control-btn.full .icon {
  color: #000;
  font-size: 2rem;
  font-weight: 500;
  pointer-events: none;
}
.map-control .control-group.map .control-btn.zoom-in:active,
.map-control .control-group.map .control-btn.zoom-out:active {
  background: #2c2685;
}
.map-control .control-group.map .control-btn.zoom-in:active .icon,
.map-control .control-group.map .control-btn.zoom-out:active .icon {
  color: #fff;
}
.map-control .control-group.map .control-btn.full .origin {
  display: none;
}
.map-control .control-group.map .control-btn.full.on .max {
  display: none;
}
.map-control .control-group.map .control-btn.full.on .origin {
  display: block;
}
.map-control .control-group.map .control-btn.on .icon {
  color: #fff;
}
.map-control .control-group.map .btn-group {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 4px;
  overflow: hidden;
}
.map-control .control-group.map .btn-group .group-item {
  display: flex;
  gap: 4px;
  align-items: center;
  height: 100%;
}
.map-control .control-group.map .btn-group .group-item input {
  display: none;
}
.map-control .control-group.map .btn-group .group-item input ~ .control-btn {
  width: 7rem;
}
.map-control .control-group.map .btn-group .group-item + .group-item {
  border-left: 1px solid #000;
}
.map-control .control-group.map .btn-group .group-item .control-btn {
  border: none;
  border-radius: 0;
}
.map-control
  .control-group.map
  .btn-group
  .group-item
  input:checked
  ~ .control-btn {
  background: #2c2685;
}
.map-control
  .control-group.map
  .btn-group
  .group-item
  input:checked
  ~ .control-btn
  .icon {
  color: #fff;
}
.map-control
  .control-group.map
  .btn-group
  .group-item
  input:checked
  ~ .control-btn
  .label {
  color: #fff;
}
#pano {
  width: 100%;
  height: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999;
  display: block;
}
#pano.on {
  display: block;
}
#pano.on ~ .pano-control {
  display: block;
}
.pano-control {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  display: block;
}
.pano-control .close {
  width: 5rem;
  height: 5rem;
  background: #2c2685;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 2.2rem;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}
.pano-control .close:hover {
  background: #413aab;
}
.street-cursor {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 12000;
  display: none;
  transform: translate(-50%, -130%);
}
.street-cursor.on {
  display: block;
}
.full-mode .app-body {
  padding: 0;
}
.full-mode .track-list-panel {
  transform: translateX(-110%);
}
.full-mode .right-info-panel {
  transform: translateX(110%);
}
.event-info-panel {
  height: 100%;
}
.event-info-panel .detail-inner {
  height: 100%;
}
.event-info-panel .detail-inner .detail-top {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.event-info-panel .detail-inner .detail-top .detail-header {
  flex: 0 0 auto;
}
.event-info-panel .detail-inner .detail-top .detail-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.event-info-panel
  .detail-inner
  .detail-top
  .detail-body:has(.track-list.on)
  .search-module {
  z-index: 1;
  box-shadow: 0 10px 10px rgba(21, 18, 65, 0.6);
}
.event-info-panel .detail-inner .search-module .custom-select:last-child {
  flex: 1;
}
.event-info-panel .track-list .list-body li + li:after {
  display: none;
}
.event-info-panel .track-list .card {
  cursor: pointer;
}
.icon-gps {
  font-weight: 500;
  font-style: normal;
  padding-left: 20px;
  background: url("../../assets/img/status/icon-gps-green.svg") no-repeat left
    center;
  position: relative;
}
.icon-gps.grey {
  background: url("../../assets/img/status/icon-gps-grey.svg") no-repeat left
    center;
}
.icon-gps.grey:after {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  background: url("../../assets/img/status/icon-red-disconnect.svg") no-repeat
    center;
  position: absolute;
  left: 7px;
  bottom: -2px;
}
.icon-gps.red {
  background: url("../../assets/img/status/icon-gps-red.svg") no-repeat left
    center;
}
.icon-gps.orange {
  background: url("../../assets/img/status/icon-gps-orange.svg") no-repeat left
    center;
}
.icon-gps.yellow {
  background: url("../../assets/img/status/icon-gps-yellow.svg") no-repeat left
    center;
}
.icon-gps.green {
  background: url("../../assets/img/status/icon-gps-green.svg") no-repeat left
    center;
}
.battery {
  font-size: 1.6rem;
  font-weight: 700;
  position: relative;
  padding-left: 26px;
  background: url("../../assets/img/status/icon-battery-green.svg") no-repeat
    left center;
  color: #fff;
}
.battery.grey {
  background: url("../../assets/img/status/icon-battery-grey.svg") no-repeat
    left center;
}
.battery.grey:after {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  background: url("../../assets/img/status/icon-red-alert.svg") no-repeat center;
  position: absolute;
  left: -4px;
  bottom: -2px;
}
.battery.red {
  background: url("../../assets/img/status/icon-battery-red.svg") no-repeat left
    center;
}
.battery.orange {
  background: url("../../assets/img/status/icon-battery-orange.svg") no-repeat
    left center;
}
.battery.green {
  background: url("../../assets/img/status/icon-battery-green.svg") no-repeat
    left center;
}
.battery.car {
  margin-left: 8px;
  padding-left: 34px;
  background: url("../../assets/img/status/icon-battery-car.svg") no-repeat 8px
    center;
}
.battery.car:before {
  content: "";
  display: block;
  width: 1px;
  height: 10px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.search-module {
  background: #f3f3f4;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.search-module .row {
  display: flex;
  gap: 3px;
}
.search-module .row > * {
  width: 100%;
}
.search-module .submit-btn {
  margin-top: 4px;
  height: 3.6rem;
  border-radius: 3px;
  background: #2c2685;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 0.2s;
}
.search-module .submit-btn:hover {
  background: #413aab;
}
.search-bar {
  position: relative;
  display: flex;
  gap: 6px;
  height: 4.8rem;
  margin-bottom: 1.2rem;
}
.search-bar > * {
  height: 100%;
}
.search-bar .custom-input {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}
.search-bar .custom-input input {
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  padding: 0 1em;
  color: #000;
  font-size: 1.5rem;
  letter-spacing: -0.04em;
}
.search-bar .custom-input input::placeholder {
  color: #888;
}
.search-bar .submit-btn {
  width: 9.2rem;
  flex: 0 0 9.2rem;
  height: 100%;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  transition: all 0.2s;
}
.search-bar .submit-btn:hover {
  border-color: #2c2685;
  background-color: rgba(85, 74, 255, 0.22);
  color: #2c2685;
}
.search-bar .custom-group {
  display: flex;
  flex: 1;
}
.search-bar .custom-group .custom-select {
  border-radius: 4px 0 0 4px;
}
.search-bar .custom-group .custom-select + .custom-input {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}
.search-bar .custom-group .custom-select + .custom-select {
  border-radius: 0;
  margin-left: -1px;
}
.search-bar .custom-group.short {
  width: 34%;
  flex: 0 0 34%;
}
.search-bar .custom-select {
  border: 1px solid #ddd;
  height: 100%;
  flex: 0 0 12rem;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.02);
}
.search-bar .custom-select select {
  color: #6b6b6b;
  padding: 0 1.6rem;
}
.search-bar .custom-select select:focus {
  outline: none;
}
.search-result-panel {
  position: absolute;
  left: 0;
  top: 5rem;
  z-index: 9;
  background: #fff;
  width: 600px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity;
}
.search-result-panel.on {
  opacity: 1;
  visibility: visible;
}
.search-result-panel li {
  font-size: 1.5rem;
}
.search-result-panel li + li {
  border-top: 1px solid #ddd;
}
.search-result-panel li a {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 1em;
}
.search-result-panel li a:hover {
  background: #f8f9fa;
}
.search-result-panel li a em {
  font-style: normal;
  font-weight: 500;
  color: red;
}
.search-result-panel li a .type {
  display: flex;
  height: 2rem;
  background: #2c2685;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  border-radius: 3px;
  color: #fff;
  font-size: 1.3rem;
}
.multi-wrapper {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  background: #000;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
}
.multi-wrapper .multi-item {
  width: calc(50% - 0.5px);
  height: calc(50vh - 0.5px);
  background: #ddd;
  position: relative;
}
.multi-wrapper .multi-item .map {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  position: relative;
}
.multi-wrapper .multi-item .map.off {
  display: none;
}
.multi-wrapper .multi-item > .pano {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
}
.multi-wrapper .multi-item > .pano.on {
  display: block;
  z-index: 9999;
}
.multi-wrapper .multi-item .map-control {
  position: absolute;
  left: 6px;
  bottom: auto;
  top: 6px;
  transform: none;
  transform: scale(0.9);
  transform-origin: left top;
}
.multi-wrapper .multi-item .map-control .control-group,
.multi-wrapper .multi-item .map-control .btn-group {
  height: 3.6rem;
}
.multi-wrapper .pano .close {
  width: 5rem;
  height: 5rem;
  background: #2c2685;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 2.2rem;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  z-index: 999;
}
.multi-wrapper .pano .close:hover {
  background: #413aab;
}
.info-layer {
  max-width: 400px;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  color: #333;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
}
.info-layer .layer-header {
  background: #2c2685;
  color: #fff;
  padding: 0 1rem;
  height: 3.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  flex: 0 0 3.4rem;
}
.info-layer .layer-header .control {
  display: flex;
  align-items: center;
}
.info-layer .layer-header .control .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.8rem;
  background: none;
  border: none;
}
.info-layer .layer-header .control .btn .icon {
  color: #fff;
  font-size: 1.8rem;
}
.info-layer .layer-header .control .btn .icon.pano {
  font-size: 1.5rem;
}
.info-layer .layer-body {
  display: flex;
  flex-wrap: wrap;
}
.info-layer .layer-body .row {
  width: 56%;
  display: flex;
  border-bottom: 1px solid #ddd;
  flex: 0 0 56%;
}
.info-layer .layer-body .row:nth-child(2n + 1) {
  width: 44%;
  flex: 0 0 44%;
}
.info-layer .layer-body .row:last-child {
  border: none;
}
.info-layer .layer-body .row .item-label {
  width: 7em;
  height: 3rem;
  background: #f3f3f3;
  flex: 0 0 7em;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 500;
}
.info-layer .layer-body .row.full {
  width: 100%;
  flex: 1;
}
.info-layer .layer-body .row.full .item-content {
  flex: 1;
}
.info-layer .layer-body .row .item-content {
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 6px 6px;
  word-break: keep-all;
}
.info-layer .layer-body .battery {
  color: #333;
  font-size: inherit;
  font-weight: 400;
}
.info-layer .layer-body .battery.car {
  margin-left: 0;
  padding-left: 26px;
  background-position: left center;
}
.info-layer .layer-body .icon-gps {
  color: #333;
  font-size: inherit;
  font-weight: 400;
}
.intro-card-wrapper {
  margin-top: 3rem;
}
.intro-card-wrapper .intro-card-list {
  display: flex;
  gap: 16px 6px;
  flex-wrap: wrap;
}
.intro-card-wrapper .intro-card-list .intro-card {
  display: none;
  width: calc(50% - 3px);
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 7px 28px rgba(0, 0, 0, 0.06);
  padding: 3rem 2.6rem;
  border-radius: 4px;
  overflow: hidden;
}
.intro-card-wrapper .intro-card-list .intro-card.on {
  display: block;
}
.intro-card-wrapper .intro-card-list .intro-card .card-num {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 2.8rem;
  border-radius: 4px;
  background: #2c2685;
  margin-bottom: 1.4rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}
.intro-card-wrapper .upload-module {
  display: block;
  position: relative;
  margin-bottom: 2rem;
}
.intro-card-wrapper .upload-module input[type="file"] {
  display: none;
}
.intro-card-wrapper .upload-module label {
  cursor: pointer;
}
.intro-card-wrapper .upload-module .preview {
  position: relative;
  border: 1px solid #ddd;
  background: #f8f9fa;
}
.intro-card-wrapper .upload-module .preview:after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
}
.intro-card-wrapper .upload-module .preview .placeholder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 1.6rem;
  gap: 0.6em;
}
.intro-card-wrapper .upload-module .preview .placeholder .icon {
  font-size: 2.4rem;
  opacity: 0.7;
}
.intro-card-wrapper .upload-module .preview .placeholder.on {
  display: flex;
}
.intro-card-wrapper .upload-module .preview .preview-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.intro-card-wrapper .upload-module .preview .preview-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.intro-card-wrapper .upload-module .preview .placeholder.on ~ .preview-img {
  display: none;
}
.intro-card-wrapper .upload-module .control {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  gap: 3px;
  display: none;
}
.intro-card-wrapper .upload-module .control.on {
  display: flex;
}
.intro-card-wrapper .card-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.intro-card-wrapper .card-content .row {
  display: flex;
  gap: 4px;
}
.intro-card-wrapper .card-content .row .label {
  height: 4rem;
  flex: 0 0 5em;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.6rem;
  position: relative;
  padding-left: 1.2rem;
}
.intro-card-wrapper .card-content .row .label:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background: #2c2685;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.intro-card-wrapper .card-content .row .custom-input {
  flex: 1;
  height: 4rem;
  border-color: #ddd;
}
.cursor {
  cursor: pointer;
}
.scroll-table {
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.w-break {
  word-break: break-all;
}
.util-list .user-name-m {
  font-size: 1.4rem;
}
.util-list .user-name-s {
  width: 185px;
  font-size: 1.4rem;
}
.w-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.util-list .org-name {
  font-size: 1.7rem;
  color: yellow;
}
.global-nav.mobile {
  display: none;
}
.marker-name-s {
  height: 32px;
  white-space: nowrap;
  line-height: 32px;
  padding: 0 1em;
  border-radius: 9999px;
  border: 1px solid #000;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16);
  background: #fff;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}
#map .active .marker .marker-name-s {
  background: #000;
  color: #fff;
}

