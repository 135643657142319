@mixin devider($width, $height, $color){
    content: "";
    display:block;
    width: $width;
    height: $height;
    background: $color;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
}

@mixin border-arrow($width, $height, $color){
    content: "";
    display:block;
    width: 0;
    height: 0;
    border-top:$height solid $color;
    border-left:calc($width / 2) solid transparent ;
    border-right:calc($width / 2) solid transparent ;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
}

@mixin border-right-arrow($width, $height, $color){
    content: "";
    display:block;
    width: 0;
    height: 0;
    border-left:$width solid $color;
    border-top:calc($height / 2) solid transparent ;
    border-bottom:calc($height / 2) solid transparent ;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
}

@mixin border-left-arrow($width, $height, $color){
    content: "";
    display:block;
    width: 0;
    height: 0;
    border-right:$width solid $color;
    border-top:calc($height / 2) solid transparent ;
    border-bottom:calc($height / 2) solid transparent ;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
}

@mixin icon-img($width, $height, $url){
    content: "";
    display:block;
    width: $width;
    height: $height;
    background:url($url) no-repeat center;
}