.search-module{
    background:$bg-grey-1;
    padding:1rem;
    display:flex;
    flex-direction: column;
    gap:3px;
    .row{
        display:flex;
        gap:3px;
        & > *{
            width:100%;
        }
    }
    .submit-btn{
        margin-top:4px;
        height:3.6rem;
        border-radius: $border-radius-xs;
        background:$color-purple-2;
        border:none;
        color:#fff;
        font-size: 1.5rem;
        font-weight: 600;
        transition: all 0.2s;
        &:hover{
            background:$color-purple-4;
        }
    }
}

.search-bar{
    position: relative;
    display:flex;
    gap:6px;
    height:4.8rem;
    margin-bottom:1.2rem;
    & > *{
        height: 100%;
    }
    .custom-input{
        width:100%;
        height:100%;
        background:#fff;
        border:1px solid #ddd;
        box-shadow: 0 5px 0 rgba(0,0,0,0.02);
        border-radius: $border-radius-sm ;
        input{
            border:none;
            background:none;
            width:100%;
            height: 100%;
            padding:0 1em;
            color:#000;
            font-size:1.5rem;
            letter-spacing: -0.04em;
            &::placeholder{
                color:#888;
            }
        }
    }
    .submit-btn{
        width:9.2rem;
        flex:0 0 9.2rem;
        height:100%;
        background:#fff;
        border:1px solid #ddd;
        box-shadow: 0 5px 0 rgba(0,0,0,0.02);
        border-radius: $border-radius-sm ;
        font-size:1.5rem;
        font-weight:700;
        color:#000;
        transition: all 0.2s;

        &:hover{
            border-color:$color-purple-2 ;
            background-color:rgba(85,74,255,0.22);
            color:$color-purple-2 
        }
    }
    &.between {
        justify-content: space-between;
    }
    .title {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 4.8rem;
        
        .date {
            color: #018564;
        }
        .unitid {
            color: blue;
        }
        .space-1 {
            margin-left: 1rem;
        }
        .space-2 {
            margin-left: 2rem;
        }
    }

    .custom-group{
        display:flex;
        flex:1;
        .custom-select{
            border-radius: 4px 0 0 4px;
            & + .custom-input{
                border-radius: 0 4px 4px 0;
                margin-left: -1px;
            }

            & + .custom-select{
                border-radius: 0;
                margin-left: -1px;
            }
        }
        &.short{
            width:34%;
            flex: 0 0 34%;
        }
        &.noflex{
            display: block;
            flex: 0;
        }

    }

    .custom-select{
        border:1px solid #ddd;
        height: 100%;
        flex:0 0 12rem;
        box-shadow: 0 5px 0 rgba(0,0,0,0.02);
        select{            
            color:#6B6B6B;
            padding:0 1.6rem;
            &:focus{
                outline: none;
            }
        }

       
    }
}

.search-result-panel{
    position: absolute;
    left:0;
    top:5rem;
    z-index: 9;
    background:#fff;
    width:600px;
    max-height:300px;
    overflow-y:auto;
    border:1px solid #ddd;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    opacity: 0;
    visibility: hidden;
    transition: opacity;

    &.on{
        opacity: 1;
        visibility: visible;
    }
    li{
       
        font-size:1.5rem;
        & + li{
            border-top:1px solid #ddd;
        }
        a{
            cursor: pointer;
            display:flex;
            align-items: center;
            width: 100%;
            gap:10px;
            padding:1em;
            &:hover{
                background:#f8f9fa;
            }
            em{
                font-style: normal;
                font-weight:500;
                color:red;
            }
            .type{
                display:flex;
                height:2rem;
                background:$color-purple-2;
                align-items: center;
                justify-content: center;
                padding:0 6px;
                border-radius: $border-radius-xs;
                color:#fff;
                font-size:1.3rem;
            }
        }
    }
}