//마커
.marker {
  width: 54px;

  // marker 아래 뾰족한 부분
  .marker-icon {
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 19px solid transparent;
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  &.pause .marker-icon:before {
    border-top-color: red;
  }
  &.start .marker-icon:before {
    border-top-color: red;
  }
  &.end .marker-icon:before {
    border-top-color: red;
  }
  // &.geo .marker-icon:before {
  //   border-top-color: #a49fef;
  // }
  // &.street .marker-icon:before {
  //   border-top-color: #a49fef;
  // }

  // 마커 (주행|정차|종료|지오|거리뷰|일반) 기본색상 설정
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    transition: all 0.6s;

    i {
      width: 100%;
      height: 100%;
      background-size: 100% auto;
      background-position: center;
    }
  }

  &.pause .icon {
    background: #ff9233;
    border: 3px solid #fce3cd;
    box-shadow: 0 5px 6px rgba(168, 52, 0, 0.25);
  }

  &.start .icon {
    background: #1b61ec;
    border: 3px solid #c1d3f8;
    box-shadow: 0 3px 6px rgba(22, 81, 198, 0.43);
  }

  &.end .icon {
    background: #888;
    border: 3px solid #ddd;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }

  &.geo .icon {
    background: #2c2685;
    border: 3px solid #b3b1d1;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
    i {
      font-size: 2.4rem;
      color: #fff;
      width: auto;
      height: auto;
    }
  }

  &.event .icon {
    background: #FFc933; 
    border: 3px solid #FFF5C6; 
    box-shadow: 0 5px 6px rgba(250,201,51,.5); 
    i {
      font-size: 2.4rem;
      color: #fff;
      width: auto;
      height: auto;
    }
  }

  &.history .icon {
    background: #186972; 
    border: 3px solid #EDFEFC; 
    box-shadow: 0 5px 6px rgba(237,254,252,.5); 
    i {
      font-size: 2.4rem;
      color: #fff;
      width: auto;
      height: auto;
    }
  }

  &.street .icon {
    background: #2c2685;
    border: 3px solid #b3b1d1;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }

  &.normal .icon {
    background: #f5f5f5;
    border: 3px solid #d6d6d6;
    i {
      filter: invert(100%);
    }
  }

  // 마커 아래 이름
  .marker-name {
    height: 32px;
    white-space: nowrap;
    line-height: 32px;
    padding: 0 1em;
    border-radius: 9999px;
    border: 1px solid #000;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.16);
    background: #fff;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.geo .marker-name {
    bottom: -35px !important;
  }

  // 마커 특정 상태 아이콘 (네트워크 | 블루투스)
  &.network .icon:before {
    content: "";
    position: absolute;
    background: url("../../../assets/img/markers/icon-network.svg") no-repeat;
    width: 18px;
    height: 18px;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  &.bluetooth .icon:before {
    content: "";
    position: absolute;
    background: url("../../../assets/img/markers/icon-bt.svg") no-repeat;
    width: 14px;
    height: 20px;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  // 마커 아이콘 설정
  &.car {
    .icon i {
      background-image: url("../../img/markers/icon-car-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-car-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-car-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-car-4.svg");
    }
  }

  &.motorcycle {
    .icon i {
      background-image: url("../../img/markers/icon-motorcycle-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-motorcycle-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-motorcycle-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-motorcycle-4.svg");
    }
  }

  &.human {
    .icon i {
      background-image: url("../../img/markers/icon-human-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-human-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-human-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-human-4.svg");
    }
  }

  &.bike {
    .icon i {
      background-image: url("../../img/markers/icon-bike-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-bike-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-bike-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-bike-4.svg");
    }
  }

  &.truck {
    .icon i {
      background-image: url("../../img/markers/icon-truck-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-truck-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-truck-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-truck-4.svg");
    }
  }

  &.trash {
    .icon i {
      background-image: url("../../img/markers/icon-trash-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-trash-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-trash-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-trash-4.svg");
    }
  }

  &.school {
    .icon i {
      background-image: url("../../img/markers/icon-school-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-school-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-school-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-school-4.svg");
    }
  }

  &.airplane {
    .icon i {
      background-image: url("../../img/markers/icon-airplane-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-airplane-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-airplane-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-airplane-4.svg");
    }
  }

  &.ship {
    .icon i {
      background-image: url("../../img/markers/icon-ship-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-ship-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-ship-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-ship-4.svg");
    }
  }

  &.crane {
    .icon i {
      background-image: url("../../img/markers/icon-crane-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-crane-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-crane-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-crane-4.svg");
    }
  }

  &.pet {
    .icon i {
      background-image: url("../../img/markers/icon-pet-1.svg");
    }
    &.start .icon i {
      background-image: url("../../img/markers/icon-pet-2.svg");
    }
    &.pause .icon i {
      background-image: url("../../img/markers/icon-pet-3.svg");
    }
    &.end .icon i {
      background-image: url("../../img/markers/icon-pet-4.svg");
    }
  }

  &.street {
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        width: auto;
        height: auto;
        font-size: 2.4rem;
        color: #fff;
      }
    }
  }
}

#map {
  width: 100%;
  height: 100%;

  //마커
  .size {
    width: auto !important;
    height: auto !important;
  }

  //클러스터
  .cluster {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    border-radius: 50%;

    &.cluster-1 {
      background: #847fcb;
      box-shadow: 0 0 0 3px rgba(132, 127, 203, 0.34);
    }
    &.cluster-2 {
      background: #524cab;
      box-shadow: 0 0 0 3px rgba(82, 76, 171, 0.34);
    }
    &.cluster-3 {
      background: #2c268a;
      box-shadow: 0 0 0 3px rgba(57, 51, 149, 0.34);
    }
    &.cluster-4 {
      background: #1c175d;
      box-shadow: 0 0 0 3px rgba(28, 23, 93, 0.34);
    }
    &.cluster-5 {
      background: #0a072b;
      box-shadow: 0 0 0 3px rgba(28, 23, 93, 0.34);
    }
  }

  //패스
  .path-marker {
    min-width: 24px;
    height: 24px;
    background: #2c2685;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    border-radius: 9999px;
    padding: 0 8px;

    &.event {
      background: #facd3e;
    }
  }
  .path-shadow {
    -webkit-filter: drop-shadow(13px 15px 6px rgba(113, 104, 228, 0.8));
    filter: drop-shadow(13px 15px 6px rgba(113, 104, 228, 0.8));
  }

  //마커 활성화시 상위 엘레멘트에서 active클래스 달림
  .active {
    z-index: 999999998;

    &.temp {
      z-index: 999999999;
    }

    .marker:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      background: #2c2685;
      border-radius: 50% 50%;
      box-shadow: 0 3px 6px rgba(44, 38, 133, 0.31);
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }

    .marker:after {
      content: "";
      display: block;
      width: 9px;
      height: 6px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
      z-index: 3;
    }

    .marker .marker-name {
      background: #000;
      color: #fff;
    }

    .path-marker {
      background: #2bc167;
      position: relative;
      &:before {
        content: "";
        width: 6rem;
        height: 6rem;        
        position: absolute;
        top: -6rem;
        left: 50%;
        transform: translateX(-50%);
      }
      &.car:before {
        background: url("../../../assets/img/markers/icon-car-pathmarker.svg")
          no-repeat center;
      }
      &.airplane:before {
        background: url("../../../assets/img/markers/icon-airplane-pathmarker.svg")
          no-repeat center;
      }
      &.bike:before {
        background: url("../../../assets/img/markers/icon-bike-pathmarker.svg")
          no-repeat center;
      }
      &.crane:before {
        background: url("../../../assets/img/markers/icon-crane-pathmarker.svg")
          no-repeat center;
      }
      &.human:before {
        background: url("../../../assets/img/markers/icon-human-pathmarker.svg")
          no-repeat center;
      }
      &.motorcycle:before {
        background: url("../../../assets/img/markers/icon-motorcycle-pathmarker.svg")
          no-repeat center;
      }
      &.pet:before {
        background: url("../../../assets/img/markers/icon-pet-pathmarker.svg")
          no-repeat center;
      }
      &.school:before {
        background: url("../../../assets/img/markers/icon-school-pathmarker.svg")
          no-repeat center;
      }
      &.ship:before {
        background: url("../../../assets/img/markers/icon-ship-pathmarker.svg")
          no-repeat center;
      }
      &.trash:before {
        background: url("../../../assets/img/markers/icon-trash-pathmarker.svg")
          no-repeat center;
      }
      &.truck:before {
        background: url("../../../assets/img/markers/icon-truck-pathmarker.svg")
          no-repeat center;
      }
      &.event:before {
        background: url("../../../assets/img/markers/icon-event-pathmarker.svg")
          no-repeat center;
      }
    }
  }
}

.track-list-panel {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 1rem;
  transition: transform 0.2s ease-in-out;

  &.fold {
    transform: translateX(-34.6rem);
    .fold-btn {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .panel-inner {
    width: 33.6rem;
    height: 100%;
    border-radius: $border-radius-sm;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .track-tab-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .tab-nav {
      height: 5.4rem;
      flex: 0 0 5.4rem;
      .tab-inner {
        display: flex;
        height: 100%;
        padding: 0 1rem;
        border-bottom: 1px solid #ddd;
      }

      .nav-btn {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $color-grey-2;
        font-weight: 500;
        font-size: 1.5rem;
        position: relative;

        &.on {
          color: $color-black-1;
          font-weight: 600;
          &:after {
            content: "";
            display: block;
            width: 5.2rem;
            height: 3px;
            background: #000;
            position: absolute;
            bottom: -1px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .tab-panel-wrapper {
      height: 100%;
      overflow: hidden;

      .tab-panel {
        height: 100%;
        flex-direction: column;
        display: flex;
        display: none;
        &.on {
          display: flex;
        }
      }
    }
  }

  .fold-btn {
    width: 2.8rem;
    height: 4.4rem;
    border-radius: 0 6px 6px 0;
    background: $color-purple-1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -1.8rem;
    top: 50%;
    transform: translateY(-50%);
    border: none;

    .icon {
      font-size: 2.4rem;
      color: #fff;
      margin-left: -2px;
    }

    &:active {
      background: $color-purple-4;
    }
  }
}

.track-list {
  padding: 1.7rem 0 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.multi {
    padding-top: 0;
    .list-header {
      padding: 0;
    }
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.6rem 1.3rem;
    transition: box-shadow 0.2s;

    .total {
      letter-spacing: -0.04em;
      .label {
        font-size: 1.5rem;
        font-weight: 1.5rem;
        color: $color-grey-1;
        padding-right: 4px;
      }
      .count {
        font-size: 1.6rem;
        font-weight: 700;
        color: #000;
      }
    }

    .selected-date {
      font-size: 1.6rem;
      font-weight: 600;
      color: #000;

      & ~ .total {
        display: flex;
        .label {
          color: $color-grey-1;
        }
        .count {
          color: $color-blue-2;
          padding-left: 4px;
        }
      }
    }
  }

  &.on .list-header {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.06);
  }

  .list-body {
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: hsla(0, 0%, 42%, 0.49);
      border-radius: 100px;
      &:hover {
        background-color: hsla(0, 0%, 0%, 0.49);
      }
    }
    li + li {
      margin-top: 6px;
    }
  }

  .item-card {
    display: flex;
    align-items: center;
    padding: 14px;
    border-radius: $border-radius-md;
    box-shadow: 0 0 7px rgba(104, 111, 127, 0.16);
    border: 1px solid transparent;
    cursor: pointer;

    .marker .marker-icon:before {
      display: none;
    }

    .item-info {
      color: #7b7b7b;
      font-size: 1.4rem;
      padding-left: 1.4rem;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .item-name {
      font-size: 1.7rem;
      color: #000;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .item-distance {
      font-weight: 500;
      padding-left: 2.2rem;
      background: url("../../../assets/img/markers/icon-item-distance.svg")
        no-repeat left center;
    }

    .item-gps {
      padding-left: 2.2rem;

      &.grey {
        background: url("../../../assets/img/status/icon-gps-grey.svg") no-repeat
          left center;
      }
      &.red {
        background: url("../../../assets/img/status/icon-gps-red.svg") no-repeat
          left center;
      }    
      &.orange {
        background: url("../../../assets/img/status/icon-gps-orange.svg") no-repeat
          left center;
      }    
      &.yellow {
        background: url("../../../assets/img/status/icon-gps-yellow.svg") no-repeat
          left center;
      }    
      &.green {
        color: inherit !important;
        background: url("../../../assets/img/status/icon-gps-green.svg") no-repeat
          left center;
      }
    }

    .item-num {
      flex: none;
      margin-left: auto;
      min-width: 3.6rem;
      height: 3.6rem;
      white-space: nowrap;
      line-height: 3.8rem;
      padding: 0 0.7em;
      font-weight: 700;
      font-size: 1.6rem;
      color: #fff;
      border-radius: 9999px;
      background: $color-purple-2;
      text-align: center;
    }

    .item-status {
      flex: none;
      margin-left: auto;
      font-weight: 600;
      font-size: 1.6rem;
      padding-right: 0.6rem;

      .start {
        color: #1b61ec;
      }
      .pause {
        color: #fe9031;
      }
      .end {
        color: #868686;
      }
    }

    &.on {
      border: 1px solid #c6c5c5;
      box-shadow: 0 0 0 2px rgba(129, 129, 129, 1) inset, 0 0 7px rgba(104, 111, 127, .16);
    }
  }
}

.right-info-panel {
  position: absolute;
  max-height: 100%;
  top: 0;
  right: 0;
  z-index: 999;
  padding: 1rem;
  transition: transform 0.2s ease-in-out;
  color: #fff;
  display: none;
  overflow: hidden;

  &.on {
    display: block;
  }

  &.fold {
    height: 100%;

    .detail-inner {
      height: 100%;
      overflow: hidden;
      border-radius: 4px;
    }

    .detail-content {
      display: none;
    }    

    &.path .detail-content {
      display: block;
    }

    .detail-bottom {
      overflow: hidden;

      .detail-tab-wrapper {
        height: 100%;
        overflow: hidden;
      }
    }
  }

  &.minimize {
    height: auto;

    .detail-summary,
    .detail-bottom {
      display: none !important;
    }

    .detail-inner .detail-top .detail-header .header-control .size:after {
      bottom: 13px;
    }
  }

  .detail-inner {
    width: 33.6rem;
    display: flex;
    flex-direction: column;

    & > div {
      border-radius: $border-radius-sm;
      background: #191634;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      overflow: hidden;
    }

    .detail-top {
      flex: 0 0 auto;
      margin-bottom: 10px;
      .detail-header {
        width: 100%;
        height: 3.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        background: #151241;
        border-bottom: 1px solid #33315a;

        .header-title {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 1.4rem;
          font-weight: 500;
          .icon {
            font-size: 1.6rem;
            opacity: 0.3;
            font-weight: normal;
          }
        }

        .header-control {
          display: flex;
          gap: 2px;

          button {
            border: none;
            width: 20px;
            height: 20px;
            position: relative;
            background: none;
            border-radius: 3px;

            &:hover {
              background: $color-purple-2;
            }
          }

          .c-size {
            &:before {
              content: "";
              display: block;
              width: 14px;
              height: 14px;
              border: 1px solid rgba(255, 255, 255, 0.5);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &:after {
              content: "";
              display: block;
              width: 10px;
              height: 2px;
              background: #fff;
              position: absolute;
              bottom: 6px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          .close {
            &:before {
              content: "";
              display: block;
              width: 14px;
              height: 1px;
              background: #fff;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &:after {
              content: "";
              display: block;
              width: 14px;
              height: 1px;
              background: #fff;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
      }

      .detail-body {
        .detail-summary {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 2rem 2.4rem;
          border-bottom: 1px solid #2e2b46;
          .left {
            display: flex;
            align-items: center;
          }
          .right {
            display: flex;
            align-items: center;
          }
        }

        .marker {
          width: 3rem;
          margin-right: 6px;
          .icon {
            width: 3rem;
            height: 3rem;
            position: relative;
            // border: none;
            box-shadow: none;
            // background: none;
            i {
              background-size: 158% auto;
            }
            &:after {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              position: absolute;
              right: 0;
              bottom: 3px;
            }
          }

          &.pause .icon:after {
            // background: #ff9132;
          }

          &.end .icon:after {
            // background: #dddddd;
          }

          &.start .icon:after {
            // background: #1b61ec;
          }

          &.car {
            .icon i {
              background-image: url("../../img/markers/icon-car-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-car-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-car-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-car-1.svg");
            }
          }

          &.motorcycle {
            .icon i {
              background-image: url("../../img/markers/icon-motorcycle-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-motorcycle-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-motorcycle-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-motorcycle-1.svg");
            }
          }

          &.human {
            .icon i {
              background-image: url("../../img/markers/icon-human-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-human-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-human-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-human-1.svg");
            }
          }

          &.bike {
            .icon i {
              background-image: url("../../img/markers/icon-bike-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-bike-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-bike-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-bike-1.svg");
            }
          }

          &.truck {
            .icon i {
              background-image: url("../../img/markers/icon-truck-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-truck-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-truck-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-truck-1.svg");
            }
          }

          &.trash {
            .icon i {
              background-image: url("../../img/markers/icon-trash-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-trash-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-trash-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-trash-1.svg");
            }
          }

          &.school {
            .icon i {
              background-image: url("../../img/markers/icon-school-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-school-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-school-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-school-1.svg");
            }
          }

          &.airplane {
            .icon i {
              background-image: url("../../img/markers/icon-airplane-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-airplane-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-airplane-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-airplane-1.svg");
            }
          }

          &.ship {
            .icon i {
              background-image: url("../../img/markers/icon-ship-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-ship-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-ship-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-ship-1.svg");
            }
          }

          &.crane {
            .icon i {
              background-image: url("../../img/markers/icon-crane-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-crane-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-crane-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-crane-1.svg");
            }
          }

          &.pet {
            .icon i {
              background-image: url("../../img/markers/icon-pet-1.svg");
            }
            &.start .icon i {
              background-image: url("../../img/markers/icon-pet-1.svg");
            }
            &.pause .icon i {
              background-image: url("../../img/markers/icon-pet-1.svg");
            }
            &.end .icon i {
              background-image: url("../../img/markers/icon-pet-1.svg");
            }
          }
        }

        .name {
          font-size: 2rem;
          font-weight: 700;
          word-break: break-all;
        }

        .detail-content {

          
          padding: 2.4rem;
          .row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2.2rem;
            
            .detail-item {
              .item-label {
                margin-bottom: 6px;
              }
              
              .item-content {
                font-size: 1.6rem;
                font-weight: 700;
              }
            }
          }
          
          & + .detail-content {
            border-top : 1px solid #2e2b46;
      
            & .row {
              margin-bottom: 0;
            }
          }

          .col {
            .detail-item {
              display: flex;
              justify-content: space-between;

              & + .detail-item {
                margin-top: 0.6em;
              }
            }
          }

          .detail-item {
            .item-label {
              color: #e6e6e6;
              font-size: 1.4rem;
            }

            .item-content {
              font-size: 1.5rem;
              &.fixed {
                width: calc(100% - 7rem);
                word-break: keep-all;
                text-align: right;
              }
            }
          }
        }
      }
    }

    .detail-bottom {
      .detail-tab-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .tab-nav {
    height: 5.4rem;
    flex: 0 0 5.4rem;
    .tab-inner {
      display: flex;
      height: 100%;
      padding: 0 6rem;
      border-bottom: 1px solid #33315a;
      background: #151241;
    }

    .nav-btn {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
      position: relative;
      opacity: 0.58;

      &.on {
        opacity: 1;
        font-weight: 600;
        &:after {
          content: "";
          display: block;
          width: 5.2rem;
          height: 3px;
          background: $color-purple-5;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .tab-panel-wrapper {
    height: 100%;
    overflow: hidden;
    .tab-panel {
      height: 100%;
      flex-direction: column;
      display: flex;
      display: none;
      &.on {
        display: flex;
      }
    }
  }

  .search-module {
    background: none;
    .custom-select {
      background: #3b3952;
      border: none;
      width: 24%;
      &:after {
        border-top-color: #fff;
      }
      select {
        background: #3b3952;
        color: #fff;
      }

      &.custom-datepicker {
        overflow: visible;
        flex: 1;
        width: 100%;
        input {
          width: 100%;
          height: 100%;
          background: none;
          color: #fff;
          appearance: none;
          border: none;
          padding-left: 1em;
        }

        .datepicker-dropdown {
          width: 400px;
          z-index: 9999;
          position: fixed;
          left: auto !important;
          top: 24rem !important;
          right: 33.6rem !important;

          .datepicker-picker .days-of-week .dow {
            color: #000;
          }
        }
      }
    }
    .custom-input {
      background: #3b3952;
      border: none;
      input {
        color: #fff;
        &::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  &:has(.track-list.on) .search-control {
    z-index: 1;
    box-shadow: 0 10px 10px rgba(21, 18, 65, 0.6);
  }

  .recent-info {
    padding: 2.4rem;
    .info-item {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      & + .info-item {
        margin-top: 0.6em;
      }

      .item-label {
        opacity: 0.58;
      }
      .item-content {
        font-weight: 500;
      }
    }
  }

  .search-control {
    padding: 0 1rem 0.6rem;
    display: flex;
    justify-content: space-between;

    .showpath-btn {
      background: none;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      gap: 6px;
      font-size: 15px;
      font-weight: 600;

      .icon {
        font-size: 2.4rem;
        font-weight: normal;
        opacity: 0.49;
      }
    }

    .btn-scroll-bottom {
      background: none;

      .i {
        font-size: 2rem;
      }
    }
  }

  .track-list {
    padding: 0;
    height: 100%;
    display: block;

    .list-body {
      padding: 1rem;

      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: hsla(0, 0%, 42%, 0.49);
        border-radius: 100px;
        &:hover {
          background-color: hsla(0, 0%, 99%, 0.49);
        }
      }
      li {
        position: relative;
      }
      li:has(.on) {
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          border: 2px solid #fff;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 6px;
          z-index: 1;
          pointer-events: none;
        }
      }
      li + li {
        margin-top: 4px;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 3px;
          height: 4px;
          background: #2fe0de;
          position: absolute;
          left: 14px;
          top: -4px;
        }
      }
    }

    &:has(.card.on) {
      .card {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
      .card.on {
        opacity: 1;
      }
    }

    .card {
      height: 100%;
      cursor: pointer;
      background: #333053;
      border-radius: 6px;
      transition: background 0.2s;
      position: relative;

      .card-status {
        font-size: 1.4rem;
        font-weight: 400;
        transition: all 0.2s;
        .top {
          height: 3.6rem;
          display: flex;
          align-items: center;
          padding: 0 1rem;
          background: #46435f;
          border-radius: 6px 6px 0 0;
        }
        &.start .top {
          background: #244fab;
        }
        &.pause .top {
          background: #d67a29;
        }
        &.end .top {
          background: #8d8d8d;
        }
        &.event .top {
          background: #facd3e;
          color: #000;
          font-weight: 500;
        }
        .bottom {
          height: 3.6rem;
          display: flex;
          align-items: center;
          padding: 0 1rem;
        }
        .item-num {
          padding-right: 0.9rem;
          margin-right: 0.8rem;
          position: relative;
          &:after {
            @include devider(1px, 10px, rgba(255, 255, 255, 0.34));
            right: 0;
          }
        }
        &.event .item-num {
          padding-right: 0.9rem;
          margin-right: 0.8rem;
          position: relative;
          &:after {
            @include devider(1px, 10px, rgba(3, 3, 3, 0.34));
            right: 0;
          }
        }
        .item-time {
          flex: none;
          margin-left: auto;
        }
        .item-speed {
          padding-right: 0.9rem;
          margin-right: 0.8rem;
          position: relative;
          &:after {
            @include devider(1px, 10px, rgba(255, 255, 255, 0.34));
            right: 0;
          }
        }
        .item-addr {
          flex: none;
          margin-left: auto;
        }
      }

      .card-detail {
        display: none;
        padding: 1.2rem;
        border-top: 1px solid rgba(255, 255, 255, 0.14);
        .row {
          display: flex;
          gap: 30px;
          justify-content: space-between;
          font-size: 1.4rem;
          & + .row {
            margin-top: 6px;
          }
          .item-label {
            opacity: 0.7;
          }
        }
      }

      &.on {
        .card-status {
          &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid #fff;
            background: #2c2685;
            border-radius: 50% 50%;
            box-shadow: 0 3px 6px rgba(44, 38, 133, 0.31);
            position: absolute;
            right: -6px;
            top: -6px;
            z-index: 3;
          }
          &:after {
            content: "";
            display: block;
            width: 8px;
            height: 6px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            position: absolute;
            top: -1px;
            right: 0px;
            z-index: 3;
            transform: rotate(-45deg);
          }
        }
        .card-detail {
          display: block;
        }
        &.path .card-detail {
          display: none;
        }
      }

      &:hover {
        background: #2a2744;
        opacity: 1;
        &.start .top {
          background: #1b4cb6;
        }
        &.pause .top {
          background: #df7920;
        }
        &.end .top {
          background: #888484;
        }
      }
    }
  }
}

@media print {
  .right-info-panel {
    .track-list {
      &:has(.card.on) {
        .card {
          opacity: 1 !important;
        }
      }
    }
  }
}

.map-control {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  display: flex;
  gap: 20px;

  .control-group {
    display: flex;
    gap: 4px;

    .control-btn {
      background: #fff;
      border: 1px solid #000;
      border-radius: $border-radius-sm;
      display: flex;
      align-items: center;
      padding: 0 11px;
      height: 3.6rem;
      white-space: nowrap;
      cursor: pointer;

      &.on {
        background: $color-purple-2;
        .icon,
        .label {
          color: #fff;
        }

        .content.blue {
          color: #fff;
        }
      }

      .label {
        font-size: 1.5rem;
        font-weight: 700;
        color: #000;
      }

      .content {
        padding-left: 8px;
        font-size: 1.5rem;
        font-weight: 700;

        &.blue {
          color: #1b61ec;
        }

        &.orange {
          color: #fe5d2a;
        }
      }

      .icon {
        color: #000;
        font-size: 2rem;
        font-weight: 500;
      }

      .icon ~ .label {
        padding-left: 5px;
      }

      &.refresh {
        width: 3.6rem;
        padding: 0;
        justify-content: center;
      }
    }

    &.map {
      .control-btn {
        height: 100%;
        .icon {
          font-size: 1.8rem;
          color: #888;
        }
        &.zoom-in,
        &.zoom-out,
        &.full {
          width: 3.6rem;
          padding: 0;
          justify-content: center;
          .icon {
            color: #000;
            font-size: 2rem;
            font-weight: 500;
            pointer-events: none;
          }
        }
        &.zoom-in:active,
        &.zoom-out:active {
          background: $color-purple-2;
          .icon {
            color: #fff;
          }
        }

        &.full {
          .origin {
            display: none;
          }

          &.on {
            .max {
              display: none;
            }
            .origin {
              display: block;
            }
          }
        }

        &.on {
          .icon {
            color: #fff;
          }
        }
      }
      .btn-group {
        display: flex;
        align-items: center;
        border: 1px solid #000;
        border-radius: $border-radius-sm;
        overflow: hidden;
        .group-item {
          display: flex;
          gap: 4px;
          align-items: center;
          height: 100%;
          input {
            display: none;

            & ~ .control-btn {
              width: 7rem;
            }
          }

          & + .group-item {
            border-left: 1px solid #000;
          }

          .control-btn {
            border: none;
            border-radius: 0;
          }

          input:checked ~ .control-btn {
            background: $color-purple-2;
            .icon {
              color: #fff;
            }
            .label {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

#pano {
  width: 100%;
  height: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  &.on {
    display: block;
    & .pano-control {
      display: block;
    }
  }
}

.pano-control {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  display: none;
  .close {
    width: 5rem;
    height: 5rem;
    background: $color-purple-2;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2.2rem;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    &:hover {
      background: $color-purple-4;
    }
  }
}

.street-cursor {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 12000;
  display: none;
  transform: translate(-50%, -130%);
  &.on {
    display: block;
  }
}

.full-mode {
  .app-body {
    padding: 0;
  }
  .track-list-panel {
    transform: translateX(-110%);
  }

  .right-info-panel {
    transform: translateX(110%);
  }
}

.event-info-panel {
  height: 100%;

  .detail-inner {
    height: 100%;

    .detail-top {
      height: 100%;
      display: flex;
      flex-direction: column;
      .detail-header {
        flex: 0 0 auto;
      }
      .detail-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        &:has(.track-list.on) .search-module {
          z-index: 1;
          box-shadow: 0 10px 10px rgba(21, 18, 65, 0.6);
        }
      }
    }
    .search-module {
      .custom-select {
        &:last-child {
          flex: 1;
        }
      }
    }
  }
  .track-list {
    .list-body li + li:after {
      display: none;
    }
    .card {
      cursor: pointer;
    }
  }
}

.icon-gps {
  font-weight: 500;
  font-style: normal;
  padding-left: 20px;
  background: url("../../../assets/img/status/icon-gps-green.svg") no-repeat
    left center;
  position: relative;

  &.grey {
    background: url("../../../assets/img/status/icon-gps-grey.svg") no-repeat
      left center;
    &:after {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      background: url("../../../assets/img/status/icon-red-disconnect.svg")
        no-repeat center;
      position: absolute;
      left: 7px;
      bottom: -2px;
    }
  }

  &.red {
    background: url("../../../assets/img/status/icon-gps-red.svg") no-repeat
      left center;
  }

  &.orange {
    background: url("../../../assets/img/status/icon-gps-orange.svg") no-repeat
      left center;
  }

  &.yellow {
    background: url("../../../assets/img/status/icon-gps-yellow.svg") no-repeat
      left center;
  }

  &.green {
    color: inherit;
    background: url("../../../assets/img/status/icon-gps-green.svg") no-repeat
      left center;
  }
}

.battery {
  font-size: 1.6rem;
  font-weight: 700;
  position: relative;
  padding-left: 26px;
  background: url("../../../assets/img/status/icon-battery-green.svg") no-repeat
    left center;
  color: #fff;

  &.grey {
    background: url("../../../assets/img/status/icon-battery-grey.svg")
      no-repeat left center;
    &:after {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      background: url("../../../assets/img/status/icon-red-alert.svg") no-repeat
        center;
      position: absolute;
      left: -4px;
      bottom: -2px;
    }
  }

  &.red {
    background: url("../../../assets/img/status/icon-battery-red.svg") no-repeat
      left center;
  }

  &.orange {
    background: url("../../../assets/img/status/icon-battery-orange.svg")
      no-repeat left center;
  }

  &.green {
    background: url("../../../assets/img/status/icon-battery-green.svg")
      no-repeat left center;
  }

  &.car {
    margin-left: 8px;
    padding-left: 34px;
    background: url("../../../assets/img/status/icon-battery-car.svg") no-repeat
      8px center;
    &:before {
      content: "";
      display: block;
      width: 1px;
      height: 10px;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
