.screen-box
  position: relative
  .box-map
    widows: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border: 4px solid #ccc
.map-box-header-wrap 
  position: absolute
  top: 8px
  left: 0
  width: 100%
  height: 50px
  z-index: 1
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0px 8px
  .map-box-header
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    height: 100%
    background: #fff
    border: 1px solid #000
    box-shadow: 0px 0px 5px 0px #363636a2
    border-radius: 5px 5px 0 0
    padding: 0px 16px
    .map-box-header-title
      font-size: 18px
      color: #000
      font-weight: 400
      border: 1px solid #ccc
      padding: 4px
      width: 60%
    .map-box-header-title-sub
      display: flex
      justify-content: start
      gap: 6px
      flex-direction: column
      span
        font-size: 10px
        color: #000
        font-weight: 400
      p
        font-size: 14px
        color: #000
        font-weight: 400
.map-box-stat-wrap
  position: absolute
  bottom: 0
  left: 0
  z-index: 101
  display: flex
  padding: 8px
  
  flex-direction: row
  gap: 3px
  .map-box-stat
    .icon-speed
      background: url("../../assets/img/status/icon-speed.svg") no-repeat 0
    background: #2c2685
    display: flex
    color: #fff
    align-items:  center
    justify-content: center
    flex-direction: column
    border-radius: 5px
    padding: 4px
    &.s-1
      width: 70px
      height: 55px
    &.s-2
      width: 54px
      height: 44px
    .speed
      display: flex
      justify-content: center
      align-items: center
      gap: 3px
      flex-direction: row
      span
        font-weight: 400
        font-size: 5px
      p
        font-size: 10px
    span
      font-weight: 400
      font-size: 5px!important
    p
      &.s-1
        font-size: 15px
      &.s-2
        font-size: 10px
    i
      &.icon-gps
        width: 80%
      width: 100% 
      height: 55% 
      background-position: 55% !important
      background-size: 50% !important
    .battery 
    .car
      padding: 0px!important
      margin-right: 8px
      
    .battery::before
      content: ''
      display: none
.map-box-ctr-wrap
  position: absolute
  bottom: 0
  right: 0
  z-index: 101
  display: flex
  padding: 8px
  width: 60px
  flex-direction: column
  gap: 3px
  .map-box-ctr-btn
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 5px
    button
      background: #fff
      display: flex
      color: #000
      font-size: 8px
      align-items:  center
      justify-content: center
      flex-direction: column
      border-radius: 5px
      padding: 4px
      width: 40px
      height: 40px
      border: 1px solid #000
      border-radius: 4px
      &:active
        background: #2c2685
        color: #fff
      i
        font-size: 1.6rem
    .active
      background: #2c2685
      color: #fff
      i
        color: #fff
.map-box-modal-wrap
  position: absolute
  top: 50px
  width: 100%
  z-index: 101
  padding: 8px
  .map-box-modal
    color: #fff
    padding: 8px
    background: #2c2685
    border: 1px solid #000
    border-radius: 0 0 5px 5px
    box-shadow: 0px 0px 5px 0px #363636a2
    display: flex
    flex-direction: column
    justify-content: space-between
    .map-box-modal-body
      width: 100%
      display: flex
      justify-content: space-between
      align-items: start
      flex-direction: row
      margin-bottom: 8px
      padding-bottom: 8px 
      border-bottom: 1px solid #000
      .map-box-modal-row
        display: flex
        justify-content: start
        align-items: start
        flex-direction: column
        gap: 6px
        .map-box-modal-item
          display: flex
          justify-content: space-between
          align-items: center
          flex-direction: row
          gap: 6px
          span
            font-size: 10px
            font-weight: 400
          p
            font-size: 14px
            font-weight: 600
    .map-box-modal-footer
      display: flex
      justify-content: space-between
      align-items: center
      flex-direction: row
      .map-box-modal-footer-title
        display: flex
        justify-content: start 
        align-items: center
        flex-direction: row
        gap: 6px 
      .map-box-modal-footer-close
        // padding-right: 8px
        display: flex
        justify-content: center
        button
          position: relative
          width: 15px
          height: 15px
          background: none
        button::before
          border: 1px solid hsla(0,0%,100%,.5)
          content: ""
          display: block
          height: 14px
          position: absolute
          top: 50%
          -webkit-transform: translate(-50%,-50%)
          transform: translate(-50%,-50%)
          width: 14px
        button::after
          background: #fff
          bottom: 6px
          content: ""
          display: block
          height: 2px
          top: 60%
          position: absolute
          -webkit-transform: translateX(-50%)
          transform: translateX(-50%)
          width: 10px
.map-modal-select
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  gap: 6px
  width: 50%
  height: 50%
  top: 25%
  left: 25%
  position: absolute
  border-radius: 5px
  z-index: 110
  .map-modal-select-title
    font-size: 18px
    font-weight: 600
  .map-modal-select-body
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 6px
  .map-modal-select-body-row
    display: flex
    justify-content: center
    align-items: center
    flex-direction: row
    flex-wrap: wrap
    padding: 20px
    gap: 15px
    .map-modal-select-body-row-item
      justify-content: center
      align-items: center
      flex-direction: row
      gap: 6px
      padding: 8px
      width: 200px
      height: 150px
      border: 1px solid #ccc
      border-radius: 5px
      padding: 5px
      background: #fff
      display: grid
      place-items: center
      cursor: pointer
      grid-template-columns: 1fr
      grid-template-rows: 1fr
    .map-modal-select-body-row-item:hover
      background: #2c2685
      color: #fff
      scale: 1.1
    .map-modal-select-body-row-item input 
      display: none
    .layout-map-box
      position: relative
      width: 100%
      height: 100%
      background: #a6a6aa