@import const.sass
@import mixin.sass
@import button.sass
@import newEvent.sass
@import mainStyle.sass
#app-cal
#EditEvent
  padding: 0 2rem
  padding-top: 5rem
  padding-bottom: 200px
.spliter
  width: 100%
  height: 1px
  background-color: #ccc
  margin-top: 10px
  margin-bottom: 10px
.date-box
  // font-size: $font-size-medium
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  height: 100%
  padding: 10px
.date-header
  padding: 0px 10px 0px 10px
  font-size: 18px
  font-weight: bold
  display: flex
  flex-direction: row
  width: 100%
  justify-content: space-between
  align-items: center
.list
  width: 100%
  display: flex
  flex-direction: column
  gap: 10px

.date-body 
  border-radius: 5px
  display: flex
  flex-direction: column
  width: 100%
  justify-content: start
  margin-top: 10px
  margin-bottom: 10px
  padding: 10px 0px 10px 0px
  align-items: center
  gap: 10px
  height: 100%
  .event-box
    width: 100%
    display: flex
    flex-direction: row
    justify-content: start
    align-items: center
    gap: 10px

.icon
  img
    width: 25px
.event-body
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  width: 100%
  gap: 0px
  span
    font-size: $font-size-small
    font-weight: 400
    margin-bottom: 5px
    margin-top: 5px
.event-header
  span
    font-size: $font-size-medium
    font-weight: bold
.input
  border: 1px solid #ccc
  border-radius: 20px
  box-shadow: none
  padding: 10px
  width: 70%
.btn-add-event
  height: 40px
  width: 40px
  border-radius: 50%
  border: none
  background-color: $color-defalut
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  padding: 0px
  flex-direction: column
  font-size: 18px
  color: $color-primary
  &:hover
    border: 1px solid #ccc
    border-radius: 5px
.select-event-calendar
  border: none
  color: #000
  font-size: 1.4rem
  font-weight: 500
  height: 100%
  padding: 0 2rem
  width: 100%
.text-area-event-calendar
  border: none
  color: #000
  font-size: 1.4rem
  font-weight: 500
  height: 100%
  padding: 0 2rem
  width: 100%
.text-area-event-calendar
  padding: 20px
  border: 1px solid #ddd
  border-radius: 3px
  width: 100%
.date-footer
  display: flex
  flex-direction: row
  justify-content: end
  width: 100%

.form-row
  width: 100%
  .row-label
    color: #888
    font-size: 1.5rem
    font-weight: 500
    margin-bottom: 1em

  + .form-row
    margin-top: 2.6rem

.form-input
  border: 1px solid #ddd
  border-radius: 3px
  height: 4.4rem
  overflow: hidden
  width: 100%


  &:focus-within
    border-color: #2e2dae
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08)

  input
    border: none
    color: #000
    font-size: 1.4rem
    font-weight: 500
    height: 100%
    padding: 0 2rem
    width: 100%

    &::-webkit-input-placeholder, &::placeholder
      color: #888
      font-weight: 400

    &:focus
      outline: none


.history-control
  align-items: center
  display: flex
  margin-bottom: 2rem
  margin-top: 1rem

  > * + *
    margin-left: 6px

  .input-wrapper
    align-items: center
    border: 1px solid #ddd
    border-radius: 9999px
    display: flex
    height: 4rem
    justify-content: space-between
    overflow: hidden
    padding: 1px 10px 0

    &.full
      flex: 1 1

  .input-date
    color: #000
    flex: 1 1
    font-size: 1.5rem
    font-weight: 700
    margin: 0 .5rem
    text-align: center

    input
      border: none
      cursor: pointer
      font-size: 1.4rem
      font-weight: 700
      height: 3.8rem
      text-align: center
      width: 100%

      &:focus
        outline: none

  .arrow
    align-items: center
    background: none
    display: flex
    height: 2.4rem
    justify-content: center
    width: 3rem

    .icon
      color: #000
      font-size: 2.6rem

  .time-wrapper
    align-items: center
    flex: 0 0 12rem
    justify-content: center
    padding-top: 2px
    width: 12rem

    span
      color: #000
      font-size: 1.4rem
      font-weight: 700

    .devider
      padding: 0 0.4em

.modal-datepicker
  align-items: center
  background: rgba(0, 0, 0, 0.5)
  display: flex
  height: 100vh
  justify-content: center
  left: 0
  padding: 1.6rem
  position: fixed
  top: 0
  transition: opacity .2s
  width: 100vw
  z-index: 1000000

  .modal-inner
    background: #fff
    border-radius: 8px
    max-width: 60rem
    padding: 1.6rem
    width: 100%

button
  border: none
  color: inherit
  font-family: inherit
  outline: none

  *
    pointer-events: none
.m-event-body
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  width: 100%
  gap: 0px
  span
    font-size: $font-size-small
    font-weight: 400
    margin-bottom: 5px
    margin-top: 5px
.event-header
  span
    font-size: $font-size-medium
    font-weight: bold 
.icon-event-list
  .label-icon
    color: #000
    background: #444444
    width: 45px
    height: 45px
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    box-shadow: -3px 2px 5px 0px rgba(0,0,0,0.75)
    -webkit-box-shadow: -3px 2px 5px 0px rgba(0,0,0,0.75)
    -moz-box-shadow: -3px 2px 5px 0px rgba(0,0,0,0.75)
    img
      width: 30px
      height: 30px
      box-shadow: none
 