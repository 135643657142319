.common-table {
  width: 100%;
  table-layout: fixed;
  box-shadow: 0 0 0 1px #d7dbde;
  border-radius: 4px;
  border-style: hidden;

  .custom-checkbox {
    .checkbox {
      width: 1.6rem;
      height: 1.6rem;
      background: #fff;
      border: 1px solid #ddd;
    }
    input:checked ~ .checkbox {
      background: #fff;
    }
    input:checked ~ .checkbox:after {
      border-color: #000;
      width: 0.8rem;
      height: 0.6rem;
    }
  }

  thead {
    th {
      background: #f5f5f5;
      height: 4.9rem;
      font-weight: 500;
      color: #464646;
      font-size: 1.5rem;
      border: 1px solid #d7dbde;
      box-shadow: 0 5px 0 rgba(0, 0, 0, 0.03);
      &:first-child {
        border-radius: 4px 0 0 0;
      }
      &:last-child {
        border-radius: 0 4px 0 0;
      }

      .custom-checkbox {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
      }
    }
  }
  tbody {
    th {
      &.center {
        text-align: center;

        .common-btn {
          margin: 0 auto;
        }
      }
    }
    td {
      border: 1px solid #eee;
      height: 4.9rem;
      text-align: center;
      color: #000;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 10px;
      line-height: 1.14;
      word-break: break-all;
      &.center {
        text-align: center;

        .common-btn {
          margin: 0 auto;
        }
      }

      &.left {
        text-align: left;
      }
    }
  }

  .empty {
    padding: 2em 1em;
    text-align: center;
  }

  .bold {
    font-weight: 700;
  }

  .link {
    text-decoration: underline;
  }

  &.clickable-row {
    tbody {
      tr:hover {
        cursor: pointer;
        td {
          background: #f8f9fa;
        }
      }
    }
  }
}

.common-pager {
  margin-top: 2rem;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
    li {
      width: 4rem;
      height: 4rem;
      flex: 0 0 4rem;
      > * {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 4rem;
        height: 4rem;
        font-size: 1.6rem;
        font-weight: 700;
        color: #000;
        border: 1px solid #ddd;
        background-color: #fff;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
        transition: background 0.2s;

        &.on,
        &:not(.omit):hover {
          border-color: $color-purple-2;
          background: rgba(85, 74, 255, 0.22);
          color: $color-purple-2;
        }
      }

      .omit {
        border: none;
        background-image: url("../../../assets/img/common/icon-pager-omit.svg");
      }
      .prev {
        background-image: url("../../../assets/img/common/icon-pager-prev.svg");
      }
      .next {
        background-image: url("../../../assets/img/common/icon-pager-next.svg");
      }
      .first {
        background-image: url("../../../assets/img/common/icon-pager-first.svg");
      }
      .last {
        background-image: url("../../../assets/img/common/icon-pager-last.svg");
      }
    }
  }
}

.add-control {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  .common-btn {
    border-radius: 9999px;
  }
}

.common-control {
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  gap: 10px;
  .left {
    flex: none;
    margin-right: auto;
    display: flex;
    gap: 3px;
    align-items: center;
  }

  .right {
    flex: none;
    margin-left: auto;
    display: flex;
    gap: 3px;
    align-items: center;
  }

  .period {
    display: flex;
    gap: 4px;
    align-items: center;
    letter-spacing: -0.06em;
    font-weight: 700;
    color: $color-purple-2;
    margin-right: 12px;
  }
  .period + .info {
    padding-left: 12px;
    &:before {
      @include devider(1px, 12px, #ddd);
      left: 0;
    }
  }

  .info {
    margin-right: 6px;
    color: #888;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    .label {
      font-weight: 700;
      color: #000;
      &.emp {
        color: $color-blue-2;
      }
    }
  }

  .emp {
    font-weight: 600;
    color: $color-purple-2;
  }
}

.common-control + .common-table {
  margin-top: 2rem;
}

.common-form-table {
  display: table;
  text-align: left;
  table-layout: fixed;
  width: 100%;
  & + .common-form-table {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #eee;
  }
  .row {
    display: table-row;
    .row-label {
      display: table-cell;
      width: 42%;
      font-size: 1.5rem;
      font-weight: 500;
      color: #000;
      &:before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        background: $color-purple-2;
        vertical-align: top;
        margin-top: 4px;
        margin-right: 8px;
      }
    }
    .row-content {
      display: table-cell;
      padding: 6px 0;
      height: 4rem;
      [class*="custom-"] {
        height: 4rem;
        border-color: #ddd;
      }
    }
  }
}

.common-view-table {
  .true {
    color: blue;
  }
  .false {
    color: red;
  }
}