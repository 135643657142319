.a11y {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: -9999em;
    left: -9999em;
    display: block;
    text-indent: -9999em;
    font-size: 0px;
    line-height: 0;
}
html {
    height: 100%;
    font-size: 62.5%;
}
body {
    height: 100%;
    font-family: $font-base;
    font-size: 1.6rem;
    letter-spacing: -0.03em;
    overflow-y: overlay;
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: hsla(0, 0%, 42%, 0.49); 
    }     

}
#root {
    height: 100%;
}

.hr{
    width:100%;
    height:4px;
    background:#F3F3F4;
    margin:10px 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

.flex{
    display:flex;
    gap:4px;
    align-items: center;
    &.wide{
        gap:20px;
    }
}

.preload *{
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

.hidden{
    display:none;
    &.on{
        display: block;
    }
}

tr.hidden{
    display:none;
    &.on{
        display: table-row;
    }
}

.blue{
    color:$color-blue-2;
}
// .red{
//     color:$color-red-1;
// }
.green{
    color:$color-green-1;
}

.flr{
    float:right;
    &:after{
        content:"";
        display:block;
        clear: both;
    }
}

.excel-table {
    display: none;
}