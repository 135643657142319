.eventBody {
    div {
        display: flex;
        gap: 8px;

        span {
            margin-bottom: 0 !important;

            &.from {
                color: #003585;
            }

            &.to {
                color: #003585;
            }
        }
    }
}