@import const.sass
@mixin flex($direction, $justify, $align, $gap) 
  display: flex
  flex-direction: $direction
  justify-content: $justify
  align-items: $align
  gap: $gap

@mixin grid($template, $justify, $align, $gap)
  display: grid
  grid-template-columns: $template
  justify-content: $justify
  align-items: $align
  gap: $gap

@mixin primeButton($bg, $color)
  color: $color
  background-color: $bg
  width: 100%
  text-align: center
  height: 30px
  border: 1px solid $color-secondary
  border-radius: 5px
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
