.multi-wrapper{
    height: 100%;
    display:flex;
    flex-wrap: wrap;
    gap:1px;
    background:#000;
    align-items: stretch;
    justify-content: center;
    overflow: hidden;
    .multi-item{
        width:calc(50% - 0.5px);
        height:calc(50vh - 0.5px);
        background:#ddd;
        position: relative;
        .map{
            width:100%;
            height:100%;
            left:0;
            top:0;
            z-index: 2;
            position: relative;
            &.off{
                display:none;
            }
        }
        & > .pano{
            position: absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            z-index: 1;
            display:none;

            &.on{
                display:block;
                z-index: 9999;
            }
        }

        .map-control{
            position: absolute;
            left:6px;
            bottom:auto;
            top:6px;
            transform: none;
            transform: scale(0.9);
            transform-origin: left top;
            .control-group, .btn-group{
                height:3.6rem;
            }
        }
    }

    .pano .close{
        width:5rem;
        height:5rem;
        background:$color-purple-2;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size:2.2rem;
        border:none;
        box-shadow: 0 0 10px rgba(0,0,0,0.16);
        position: absolute;
        left:50%;
        transform:translateX(-50%);
        bottom:20px;
        z-index: 999;

        &:hover{
            background:$color-purple-4;
        }
    }
}



.info-layer{
    max-width:400px;
    height: 100%;
    background:#fff;
    border-radius: 8px;
    overflow: hidden;
    color:#333;
    font-size:1.2rem;
    display:flex;
    flex-direction: column;
    border:1px solid #ddd;
    .layer-header{
        background:$color-purple-2;
        color:#fff;
        padding:0 1rem;
        height:3.4rem;
        display:flex;
        justify-content: space-between;
        align-items: center;
        border-bottom:1px solid #ddd;
        flex:0 0 3.4rem;

        .control{
            display: flex;
            align-items: center;
            .btn{
                display:flex;
                align-items: center;
                justify-content: center;
                width:2.2rem;
                height:2.8rem;
                background:none;
                border:none;
                .icon{
                    color:#fff;
                    font-size:1.8rem;
                    &.pano{
                        font-size:1.5rem;
                    }
                }
            }
        }
    }
    .layer-body{
        display:flex;
        flex-wrap: wrap;
        .row{
            width:56%;
            display:flex;
            border-bottom:1px solid #ddd;
            flex:0 0 56%;
            &:nth-child(2n+1){
                width: 44%;
                flex: 0 0 44%;
            }
            &:last-child{border:none;}
            .item-label{
                width:7em;
                height:3rem;
                background:#f3f3f3;
                flex:0 0 7em;
                display:flex;
                align-items:center;
                padding:0 10px;
                font-weight:500;
            }

            &.full{
                width:100%;
                flex:1;
                .item-content{
                    flex:1;
                }
            }

            .item-content{
                height:3rem;
                display: flex;
                align-items:center;
                padding:6px 6px;
                word-break: keep-all;
            }
        }

        .battery{
            color:#333;
            font-size:inherit;
            font-weight: 400;
            &.car{
                margin-left:0;
                padding-left:26px;
                background-position: left center;
            } 
        }

        .icon-gps{          
            color:#333;    
            font-size:inherit;
            font-weight: 400;   
             
        }

    }
}