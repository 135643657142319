.common-modal {
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  visibility: hidden;
  transition: opacity 0.2s;

  background: rgba(0, 0, 0, 0.5);
  overflow-y: overlay;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 42%, 0.49);
  }

  &.on {
    opacity: 1;
    visibility: visible;
    .modal-inner {
      transform: translateY(0);
    }
  }

  .modal-inner {
    width: 100%;
    min-height: 100%;
    display: flex;
    text-align: center;
    padding: 5rem 2rem;
    transform: translateY(10px);
    transition: all 0.2s;

    &:has(.short) {
      padding: 15rem 2rem;
    }
    &:has(.short2) {
      padding: 30rem 2rem;
    }

    &.manual {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) !important;
      min-height: auto !important;
    }
  }

  .modal-page {
    background: #fff;
    width: 100%;
    max-width: 102rem;
    margin: 0 auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 6.5rem;
      border-bottom: 1px solid #eee;
      padding: 0.2rem 3.2rem 0;

      .page-title {
        height: 100%;
        span {
          display: inline-flex;
          align-items: center;
          height: 100%;
          position: relative;
          font-size: 1.7rem;
          font-weight: 700;

          &.blue {
            color: #000000 !important;
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 2px;
              background: #1b61eb;
              position: absolute;
              bottom: -1px;
              left: 0;
            }
          }
          &.red {
            color: red !important;
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 2px;
              background: red;
              position: absolute;
              bottom: -1px;
              left: 0;
            }
          }
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background: $color-purple-4;
            position: absolute;
            bottom: -1px;
            left: 0;
          }
        }
      }
      .modal-close {
        border: none;
        background: none;
        width: 2.4rem;
        height: 2.4rem;
        position: relative;
        .icon {
          font-size: 2.4rem;
          color: #000;
          pointer-events: none;
          position: relative;
          z-index: 1;
        }
        &:after {
          content: "";
          display: block;
          width: 150%;
          height: 150%;
          background: #eee;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(0);
          transition: transform 0.2s;
        }
        &:hover {
          &:after {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }

    .page-body {
      padding: 1.8rem 3.2rem 3rem;

      .m_title {
        font-weight: 700;
        font-size: 16px;
        width: 94px;
      }

      .m_table {
        text-align: start;

        tr {
          height: 45px;
          .key {
            font-weight: 500;
            color: #5D597B;
            font-size: 14px;
            width: 140px;
          }
          .value {
            font-size: 14px;
            font-weight: 700;
            
            .name_img {
              width: 200px;
              text-align: center;
              word-break: break-all;
            }

            &.blue {
              color: #1B61EB;
            }
          }          
        }
        td {
          border: none;
          padding: 0;
        }
        
        input {
          width: 100%;
          background-color: #F2F2F2;
          height: 35px;
          border-radius: 4px;
          border: none;
          padding: 0 1rem;
          font-weight: 700;
          &.small {
            width: 90%;
          }
        }
        button {
          padding: 10px 13px;
          border-radius: 4px;
          cursor: pointer;
          border: 1px solid #DDDDDD;
          font-weight: 500;

          &.delete {
            margin-left:10px;
            background-color: #F2F2F2;
          }
          &.modify {
            width: 100%;
            background-color: #1B61EB;
            color: #FFF;
            margin-top: 2rem;
          }
        }
      }
      .m_form {
        display: flex;

        &.vertical {
          flex-direction: column;

          div {
            display: flex;
          }
          .key {            
            width: 10rem;
            margin-right: 2rem;
          }
          .value {
            width: calc(100% - 12rem);
            line-height: 40px;
            font-weight: 700;
          }
          .space-2 {
            margin: 2rem 0 0 0 !important;
          }
        }
        span {
          line-height: 40px;
          font-weight: 700;
        }
        input {
          border: 0.5px solid gray;
          border-radius: 5px;
          height: 40px;
          padding: 5px;
          text-align: center;
          background-color: #fff;
          width: 100%;
          font-weight: 700;
        }
        div + div {
          margin-left: 2rem;
        }
      }
      .m_form + button {
        padding: 10px 13px;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid #DDDDDD;
        font-weight: 500;
        width: 100%;
        background-color: #1B61EB;
        color: #FFF;
        margin-top: 2rem;
      }
    }

    .page-footer {
      margin-top: auto;
      flex: none;
      border-top: 1px solid #eee;
      padding: 1.6rem 3.2rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
    }
  }
}

.modal-page.sm {
  max-width: 48rem;
}
.modal-page.md {
  max-width: 80rem;
}
.modal-page.lg {
  max-width: 14.6rem;
}
