$font-base: "Pretendard", sans-serif;
$font-email: "맑은고딕", sans-serif;

$color-purple-1 : #0D0940;
$color-purple-2 : #2C2685;
$color-purple-3 : #3D3864;
$color-purple-4 : #413AAB;
$color-purple-5 : #554AFF;

$bg-purple-1: #0D0940;
$bg-purple-3: #151241;
$bg-purple-2: #191634;

$bg-grey-1:#F3F3F4;
$bg-grey-2:#f8f9fa;

$color-black-1 : #000;
$color-black-2 : #222;
$color-black-3 : #333;

$color-orange-1 : #D67A29;
$color-orange-2 : #FF9232;
$color-orange-3 : #FEB77A;
$color-orange-4 : #FCE3CD;
$color-orange-shadow : rgba(254,93,42,0.43);

$color-blue-1 : #244FAB;
$color-blue-2 : #1B61EC;
$color-blue-3 : #81ABFF;
$color-blue-4 : #C1D3F8;
$color-blue-shadow : rgba(22,81,198,0.43);

$color-grey-1 : #8D8D8D;
$color-grey-2 : #888888;
$color-grey-3 : #B9B9B9;
$color-grey-4 : #DDDDDD;
$color-grey-shadow : rgba(0,0,0,0.16);

$color-green-1 : #05dc86;

$color-white-1 : rgba(255,255,255,1);
$color-white-2 : rgba(255,255,255,0.72);
$color-white-3 : rgba(255,255,255,0.58);

$color-red-1 : #d81d1d;

$color-chart-red: #FE5D2A;
$color-chart-blue: #1B61EC;

$break-xs: 480px;
$break-sm: 768px;
$break-md: 1024px;
$break-lg: 1200px;
$break-xl: 1440px;
$break-xxl: 1600px;

$border-radius-xs : 3px;
$border-radius-sm : 4px;
$border-radius-md : 8px;