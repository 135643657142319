#app-cal
  #addNewEvent
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100vh
    background: #fff
    display: flex
    flex-direction: column
    justify-content: start
  #EditEvent
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100vh
    background: #fff
    display: none
    flex-direction: column
    justify-content: start
  .input-groups-box
    border-bottom: 1px solid #ccc
    .input-groups-event
      border: none
  .input-groups-event
    margin: 0px 10px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid #ccc
    padding: 10px 0px
    label
      font-size: 14px
      font-weight: 600
      color: #333
      margin-right: 10px
      width: 100%
  .input-event
    height: 30px
    border: none
    width: 100%
  .color-picker-box
  
    display: flex
    justify-content: end
  .color-picker
    width: 30px
    height: 30px
    text-align: center
    border-radius: 50%
    border: none
    background: red
  .input-groups-event
    select
      width: 100%
    textarea
      width: 100%
    input
      width: 100%
    span
      padding: 0px 5px
.color-picker-box
  display: flex
  justify-content: space-around
  align-items: center
  margin: 10px 0px
.color-picker-item
  width: 30px
  height: 30px
  border-radius: 50%
  border: 1px solid #ccc
  background: #636363
  &:hover
    cursor: pointer
    border: 1px solid #ccc
    