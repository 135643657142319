$color-defalut: #1c46ff
$color-primary: #ffffff
$color-secondary: #1800f0 
$font-size-default: 16px
$font-size-small: 12px
$font-size-medium: 16px
$font-size-large: 18px

$font-weight-default: 400
$font-weight-bold: 700

#app-cal
  .flex-col
    display: flex
    flex-direction: column
  .flex-row
    display: flex
    flex-direction: row

  .justify-center
    justify-content: center
  .justify-between
    justify-content: space-between

  .input-group-row
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    width: 100%
    gap: 10px
    span
      font-size: $font-size-small
      font-weight: 400
      margin-bottom: 5px
      margin-top: 5px
  .input-group-col
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    gap: 10px
    span
      font-size: $font-size-small
      font-weight: 400
      margin-bottom: 5px
      margin-top: 5px
