.custom-range {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px 0px 10px 0px;
  height: 100%;
  margin-bottom: 10px;
}

.label-geofnce {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5.6rem;
  padding: 5px;
  height: 4rem;
  border-radius: 3px;
  border: 1px solid #ddd;
  background: #fff;
  color: #000;
  font-size: 14.5px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}

.custom-radio input:checked ~ .label-geofnce {
  background: #d9d6ff;
  border-color: #2c2685;
  color: #2c2685;
  box-shadow: 0 5px 12px rgba(44, 38, 133, 0.12);
}
