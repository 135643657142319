/* hero */
.faq-outer {
    word-break: keep-all;
}

.faq-outer .img-scale {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
}

.faq-outer .modal {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background: #000;
    justify-content: center;
    align-items: center;
}

.faq-outer .youtube-embed-container {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    margin: 2em 1em;
}

.faq-outer .youtube-embed {
    position: absolute;
    width: 100%;
    height: 100%;
}

.faq-outer .img-30 {
    width: 30%;
}

.faq-outer .img-50 {
    width: 50%;
}

.faq-outer .img-70 {
    width: 70%;
    padding-bottom: 1.5em;
}

/* header */
.faq-outer .header {
    width: 100%;
    background: #ffffffb0;
    z-index: 999;
    position: fixed;
    top: 0;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    box-shadow: 0 0 4px 0 rgba(59, 131, 246, 0.3);
}

.faq-outer .header-container {
    height: 4.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: center;
    margin: auto 6em;
}

.faq-outer .header-logo {
    margin: 1em;
    width: 160px;
    /* width: 135px; */
    height: 40px;
    background: url('../../img/login/logo-klbs.svg');
    /* background: url('../../img/common/logo-iotplex.svg'); */
    background-size: cover;
}

.faq-outer .header-lists {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin: auto 1.5em;
}

.faq-outer .header-item {
    font-size: 1.2em;
    font-weight: 700;
    color: #111827;
    transition: all 0.5s;
}

.faq-outer .header-item-line {
    font-size: 1.2em;
    font-weight: 700;
    color: #111827;
    transition: all 0.5s;
    padding-bottom: 0.2em;
    border-bottom: 2px solid #3b82f6;
}

.faq-outer .header-item:hover,
.faq-outer .header-item-line:hover {
    color: #3b82f6;
}

.faq-outer .header-item-not {
    font-size: 1.2em;
    font-weight: 500;
    color: #9ca3af;
    cursor: default;
}

/* content */
.faq-outer .content-part {
    display: flex;
    height: 100%;
    /* overflow: hidden; */
}

/* navbar */
.faq-outer .nav-container {
    position: fixed;
    top: 4.5em;
    /* overflow-y: auto; */
    width: 15.5em;
    margin: 1em 0.5em 1em 6em;
    font-size: 1em;
}

.faq-outer .nav-lists {
    padding: 0 1em;
}

.faq-outer .nav-title {
    font-size: 1.2em;
    color:#111827;
    font-weight: 700;
    margin: 2em 0 1.5em;
}

.faq-outer .nav-link {
    display: block;
    color: #374151;
    transition: all 0.2s ease-in;
    margin-bottom: 0.8em;
}

.faq-outer .nav-link:hover {
    color: #3b82f6;
    border-left: 3px solid #3b82f6;
    padding-left: 0.8em;
}

/* content */
.faq-outer .main-container {
    flex: 1;
    /* overflow-y: auto; */
    margin: 0.5em 1em 2em 24em;
    padding: 0em 1.5em 2em 1.5em;
}

.faq-outer .article-title {
    font-size: 2em;
    color: #111827;
    font-weight: 700;
    margin-bottom: 1.5em;
    margin-top: 2em;
    padding-bottom: 0.8em;
    border-bottom: 1px solid #eff6ff;
}

.faq-outer .article-img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
    padding: 1em;
    margin-bottom: 0.5em;
}

.faq-outer .article-point-img {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    align-items: baseline;
    padding: 1em 0;
    text-align: center;
}

.faq-outer figcaption {
    font-size: 1em;
    color: #1e40af;
    font-weight: 700;
    text-align: center;
    padding-top: 0.5em;
}

.faq-outer .article-point {
    background-color: #eff6ff;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    padding: 2em 2em 1.5em 2em;
    margin: 1em 0 2em 0;
    box-shadow: 0 0 4px 0 rgba(59, 131, 246, 0.3);
}

.faq-outer .red-700 {
    color: #ef4444;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.faq-outer .blue-700 {
    color: #3b82f6;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.faq-outer .green-700 {
    color: #22c55e;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.faq-outer .normal-500 {
    font-weight: 500;
    color: #172554;
    line-height: 1.5;
    margin-bottom: 0.5em;
}

.faq-outer .normal-500 p {
    line-height: 1.6;
}

.faq-outer .normal-700 {
    font-weight: 700;
    color: #172554;
    margin-bottom: 0.5em;
}

.faq-outer .h2-bold {
    font-size: 1.4em;
    color: #1e40af;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 1em;
}

.faq-outer .article-bold {
    color: #111827;
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.4em;
    margin: 1.5em 0 0.2em 0;
}

.faq-outer .article-txt {
    font-weight: 500;
    line-height: 1.6;
    font-size: 1.05em;
    gap: 0.2em;
    color: #374151;
    padding-bottom: 0.5em;
}

.faq-outer .article-txt p {
    line-height: 1.6;
}

.faq-outer article {
    /* margin-bottom: 10em; */
    max-width: 800px;
    padding-top: 5em;
}

/* yt modal */
.faq-outer .article-modal {
    background-color: #111827;
    border-radius: 1em;
    display: flex;
    flex-direction: row;
    padding: 1em;
    margin: 1em 0;
    max-width: 5.5em;
}

.faq-outer .modal-txt {
    font-size: 1em;
    color: #fff;
    font-weight: 700;
    padding-right: 0.5em;
}

.faq-outer .icon {
    width: 1em;
}

/* float top banner */
.faq-outer .float-top {
    position: fixed;
    z-index: 999;
    bottom: 3em;
    right: 4em;
    background: #172554;
    border-radius: 50%;
    padding: 1em;
    box-shadow: 0 0 4px 0 rgba(59, 131, 246, 0.3)
}

.faq-outer .float-top-svg {
    width: 2em;
    height: 1.8em;
    color: #fff;
}

.faq-outer .float-banner {
    padding: 1.2em 1em 0.6em 1em;
    border: 0.5px solid #a1c5ff;
    max-height: 300px;
    min-width: 250px;
    background: #eff6ff;
    border-radius: 1em;
    position: fixed;
    right: 4em;
    top: 8em;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.5em;
    color: #172554;
}

.faq-outer .float-banner a:hover p {
    color: #3b82f6;
    transition: all 0.3s;
}