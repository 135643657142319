.errorcon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	.errorlogo {
		width: 180px;
		// width: 180px;
		margin: 8rem 0 0 0;
	}

	.errortitle {
		font-size: 2.4em;
		font-weight: 700;
		line-height: 1.2;
		margin: 1.5em 0 1em 0;
	}

	.errorsub {
		font-size: 1.2em;
		font-weight: 400;
		color: #818181;
		line-height: 1.3;
	}
}

.errorlink {
	display: flex;
	justify-content: center;
	margin-top: 2em;
}

.errorbtn {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 0;
	background: #222;
	color: #fff;
	padding: 1.5em 3em;
	border-radius: 14px;
	font-size: 1.2em;
	font-weight: 500;
	transition: background 0.2s;

	&:hover {
		background: #180470;
	}
}

.errorft {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 16rem;
	overflow: hidden;
	width: 100vw;
	text-align: center;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 900;
	position: fixed;
	bottom: -3.5rem;
	left: 0;
	color: #f2f2f2;
	z-index: -99;
}

@media screen and (max-width: 576px) {
	.errorcon {
		.errortitle {
			font-size: 2em;
		}
		.errorsub {
			font-size: 1.1em;
		}
	}
	.errorbtn {
		padding: 1.5em 2.5em;
		font-size: 1.1em;
	}
	.errorft {
		font-size: 8rem;
		bottom: -2.5rem;
	}
}

@media screen and (max-width: 370px) {
	.errorcon {
		.errorlogo {
			width: 160px;
		}
		.errortitle {
			font-size: 1.4em;
		}
		.errorsub {
			font-size: 1em;
		}
	}
	.errorlink {
		margin-top: 1.5em;
		.errorbtn {
			padding: 1.25em 2em;
			font-size: 1em;
		}
	}
	.errorft {
		font-size: 3rem;
		bottom: -1rem;
	}
}
