.col-8
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 50%
  gap: 20px
.col-4
  display: flex
  flex-direction: column
  // justify-content: center
  align-items: center
  width: 25%
  gap: 10px
.das-row
  display: flex
  flex-direction: row
  gap: 20px
.das-card
  display: flex
  flex-direction: row
  justify-content: space-between
  margin: 0 0 1rem 0
  padding: 20px 
  border: 1px solid #ccc
  border-radius: 10px
  background-color: #fff 
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2)
  width: 100%
  max-width: 451px
  height: 100%
  min-height: 141px
  max-height: 136px
  gap:18px
  cursor: pointer
.das-info
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
.das-icon-large
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  i
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    img
      width: 100px
      height: 100px

.das-info-title 
  display: flex
  flex-direction: column
  justify-content: start
  p
    color: var(--dashboard-default, #100A3D)
    font-family: Pretendard
    font-size: 20px
    font-style: normal
    font-weight: 700
    line-height: normal
    letter-spacing: -0.7px
  span
    font-size: 18px
    font-weight: normal
.das-info-value
  display: flex
  flex-direction: row
  justify-content: end
  color: var(--dashboard-black, #000)
  text-align: right
  font-family: Pretendard
  font-size: 32px
  font-style: normal
  font-weight: 700
  line-height: normal
  letter-spacing: -1.3px
#chart
  width: 100%
.card-chart
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 300px
  max-height: 300px
  gap: 20px
  border: 1px solid #ccc
  border-radius: 10px
  background-color: #fff
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2)
  padding: 10px
  margin: 0 0 20px  0

.das-chart-title
  display: flex
  flex-direction: row
  justify-content: start
  align-items: center
  width: 100%
  height: 100%
  gap: 20px
  font-size: 20px
  font-weight: bold
  padding-left: 30px
  

.apexcharts-legend-text
  font-size: 18px!important
  font-weight: 600!important
  margin-bottom: 20px!important
.apexcharts-legend
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 20px

.v-block
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  gap: 20px
  margin-bottom: 8px
.v-title
  display: flex
  flex-direction: row
  justify-content: start
  align-items: center
  width: 100%
  height: 100%
  gap: 10px
  font-size: 20px
  font-weight: bold
  p
    color: var(--Black-text-950, #000)
    font-family: Pretendard
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: -0.4px
  span
    color: var(--Red-caution-600, #F20606)
    font-family: Pretendard
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: -0.35px
.v-input
  width: 300px
  height: 42px
  flex-shrink: 0
  display: flex
  flex-direction: row
  justify-content: left
  align-items: center
  gap: 10px
   
  input
    border-radius: 20px
    border: 1px solid var(--Black-text-200, #E3E3E3)
    background: var(--Black-text-50, #FFF)
    width: 250px
    height: 100%
    text-align: end
    color: var(--Black-text-950, #000)
    font-family: Pretendard
    font-size: 18px
    font-style: normal
    font-weight: 700
    line-height: normal
    letter-spacing: -0.45px
    
  span
    width: 50px
.v-button
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 52px
  border-radius: 30px
  background: var(--Blue-driving-500, #3571FC)
  color: #FFF
  text-align: center
  font-family: Pretendard
  font-size: 18px
  font-style: normal
  font-weight: 700
  line-height: normal
  letter-spacing: -0.45px
  margin-top: 12px
@media screen and (max-width: 1440px) 
  .das-icon-large i img
    width: 45px
    height: 45px
  .col-4
    width: 100%

