/* blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
    margin:0;
    padding:0;
} */
.faq-outer h1, h2, h3, h4, h5, h6 {
    font-size:100%;
}
.faq-outer a {
    color:inherit;
    text-decoration:inherit;
}
.faq-outer ul, ol {
    list-style:none;
}
.faq-outer button, input, select {
    margin:0;
}
/* html {
    box-sizing:border-box;
} */
/* *, :after, :before {
    box-sizing:inherit;  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */
.faq-outer img, video {
    height:auto;
    max-width:100%;
}
.faq-outer iframe {
    border:0;
}
.faq-outer table {
    border-collapse:collapse;
    border-spacing:0;
}
.faq-outer td, th {
    padding:0;
}
/* :root {
    overflow-wrap:break-word;
    word-break:break-word;
} */

/* body {
    font-family: 'Pretendard';
    font-weight: 500;
    letter-spacing: -0.25%;
} */