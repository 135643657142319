.c-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.c-flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.c-flex-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.c-pt-5 {
  padding-top: 20px;
}

.c-mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}

.c-my-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-gap-5 {
  gap: 20px;
}

.c-gap-2 {
  gap: 10px;
}

.c-mt-2 {
  margin-top: 10px;
}

.c-mb-2 {
  margin-bottom: 10px;
}

.c-mb-5 {
  margin-bottom: 20px;
}

.c-hidden {
  display: none !important;
}

.c-header {
  background: #39394b;
  padding: 1rem 0.5rem 1rem 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  gap: 0.5rem;
}
.c-header .c-h-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.44px;
  color: #01f1e3;
  font-family: "Do Hyeon", sans-serif;
}
.c-header p {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.44px;
}

.c-form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.c-form-box .c-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.c-input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 3px;
}
.c-input-group label {
  font-family: "Noto Sans KR", sans-serif;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.44px;
}
.c-input-group input {
  border-radius: 3px;
  border: none;
  min-height: 39px;
  padding: 10px 12px;
  background: #05050f;
  color: #fff;
  width: 100%;
}
.c-input-group select {
  border-radius: 3px;
  border: none;
  min-height: 32px;
  padding: 10px 12px;
  background: #05050f;
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0.44px;
  width: 100%;
  height: 39px;
}
.c-input-group select:hover {
  background: #023aff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-input-group select:active {
  background: #00239d;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-input-group button {
  border-radius: 3px;
  border: none;
  min-height: 32px;
  padding: 10px 12px;
  background: #023aff;
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.44px;
}
.c-input-group button:hover {
  background: #023aff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-input-group button:active {
  background: #00239d;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-input-group input[type=radio] {
  border-radius: 3px;
  border: solid 1px #191932;
  width: 20px;
  height: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background: #191932;
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0.44px;
}
.c-input-group input[type=radio]:hover {
  background: #023aff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-input-group input[type=radio]:active {
  background: #00239d;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-input-group .c-invalid {
  border-radius: 3px;
  border: solid 1px #ff2d2e;
  min-height: 32px;
  padding: 10px 12px;
  background: #05050f;
  color: #fff;
  width: 100%;
}
.c-input-group .c-valid {
  border-radius: 3px;
  border: solid 1px #5eff5a;
  min-height: 32px;
  padding: 10px 12px;
  background: #05050f;
  color: #fff;
  width: 100%;
}
.c-input-group .c-error-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.c-input-group .c-error-text {
  color: #ff2d2e;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.44px;
}
.c-input-group .c-no-error {
  color: #5eff5a;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.44px;
}

.c-inputs-box {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 0.5rem 1fr;
  grid-template-columns: 2fr 1fr;
  gap: 0.5rem;
}

.c-inputs-box-3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 0.5rem 1fr 0.5rem 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
}

.c-radio-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  gap: 0.5rem;
}

.c-radio-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 0.5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.c-radio-group label {
  font-family: "Noto Sans KR", sans-serif;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.44px;
}
.c-radio-group input[type=radio] {
  border-radius: 3px;
  border: solid 1px #191932;
  width: 20px;
  height: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background: #191932;
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0.44px;
}
.c-radio-group input[type=radio]:hover {
  background: #023aff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-radio-group input[type=radio]:active {
  background: #00239d;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.c-form-text p {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.44px;
}
.c-form-text h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.44px;
  color: #01f1e3;
  font-family: "Do Hyeon", sans-serif;
}

#devForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
  gap: 2rem;
}

.c-inputs {
  width: 100%;
}

.c-terms {
  width: 100%;
}

@media (max-width: 690px) {
  #devForm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
    gap: 0rem;
    height: 100vh;
    height: 100%;
  }
  .c-inputs {
    width: 100%;
  }
  .c-terms {
    width: 100%;
  }
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.c-body {
  background: #05050f;
  color: #fff;
}

.c-main {
  background: red;
  width: 100%;
  padding: 1rem;
  background: #39394b;
}

.c-footer {
  background: #39394b;
  padding: 1rem;
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 300;
}
.c-footer p {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.44px; 
}