#modal-loading{
    position: fixed;
    z-index: 10000;
    display: none;
    top:0px;
    left:0px;
    right:0px;
    bottom:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.2);
    
    .loading-spinner{
        position: fixed;
        z-index: 10001;
        top:50%;
        left:50%;
        margin:-50px 0 0 -50px;
        display:block;
        width: 80px;
        height: 80px;
        border: 6px solid rgba(255,255,255,0.6);
        border-radius: 50%;
        border-top-color: $color-purple-4;
        animation: spin 0.8s ease-in-out infinite;
    }
}

.loading{
    #modal-loading{
        display: block;
    }
}

.m-loading {
    display : flex;
    flex-direction : column;
    align-items : center;

    .text {
        padding-top : 3rem;
        font-weight : 500;
    }
}