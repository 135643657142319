.common-view-table, .common-edit-table{
    table-layout: fixed;
    width: 100%;
    thead{
        th{
            background:#F8F9FA;
            height:4.4rem;
            border:1px solid #ddd;
            padding:1em 1.6em;
            font-size:1.5rem;
            font-weight:500;
            color:#000;
            text-align: center;
        }
    }
    tbody{
        th{
            background:#F8F9FA;
            height:6.4rem;
            border:1px solid #ddd;
            padding:1em 1.6em;
            font-size:1.5rem;
            font-weight:500;
            color:#000;
            text-align: left;
            &.center{
                text-align: center;
    
                .common-btn{
                    margin:0 auto;
                }
            }
        }
        td{
            height:6.4rem;
            border:1px solid #ddd;
            text-align: left;
            padding:14px;
            font-size:1.5rem;
            color:#333;
            font-weight:500;
            [class*="custom-"]{
                min-height:4rem;
                border-color:#ddd;
            }

            &.center{
                text-align: center;

                *{
                    margin:0 auto;
                }
            }
        }
    }

    & + .common-table-label{
        margin-top:2em;
    }

    .empty{
        padding:1em 1em;
        text-align: center;
    }

    .guide{
        font-size:1.4rem;
        color:#888;
        padding:0.6em 0 0;
        font-weight:400;
    }

    & + .common-view-table{
        margin-top:2rem;
    }

    .required{
        &:after{
            content:"";
            display:inline-block;
            width:5px;
            height:5px;
            background:red;
            border-radius: 50%;
            vertical-align: top;
            margin-left:5px;
        }
    }

    [class*="custom-"]{
        box-shadow: none;
    }
}

.editor-view{
    min-height:20rem;
    word-break:keep-all;
    word-wrap:break-word;
    line-height:1.2;
}

.common-table-label{
    margin-bottom: 1em;
    display:flex;
    justify-content: space-between;
    align-items: center;
    .title{
        font-size:1.6rem;
        font-weight:700;
    }
    .desc{
        font-size:1.5rem;
        color:#888;
    }
}